import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'
import { Link, useSearchParams } from 'react-router-dom'
import { IconButton } from '@ritmo/ui'
import { ArrowSquareOut } from 'phosphor-react'
import { SortButton } from '../../components/SortButton'
import { OperatingSystemLogo } from '../../components/OperatingSystemLogo'
import SearchInput from '../../components/SearchInput'
import { loading } from '../../components/loading'
import { PagesNav } from '../../components/PagesNav'

type PlayerReleaseInfoRow = {
  _id: string
  title: string
  client: string
  clientId: string
  os: {
    name: string
  }
  appVersion: string
  versionOutdated: boolean
}

interface Response {
  count: number
  players: PlayerReleaseInfoRow[]
}

function PlayerRow(player: PlayerReleaseInfoRow) {
  if (!player.os) {
    console.warn('Player without OS', player)
  }
  return (
    <tr>
      <td>{player.title}</td>
      <td>{player.client}</td>
      <td>
        <OperatingSystemLogo name={player.os?.name || '-'} />
      </td>
      <td
        className={player.versionOutdated ? 'text-amber-600' : 'text-green-500'}
      >
        {player.appVersion}
      </td>
      <td>
        <Link
          to={`/clients/${player.clientId}/players/${player._id}?back=${location.pathname}`}
        >
          <IconButton variant="secondary">
            <ArrowSquareOut size={18} />
          </IconButton>
        </Link>
      </td>
    </tr>
  )
}

export function ReleasesMetrics() {
  const [searchParams] = useSearchParams()

  const { data } = useSWR<Response>(
    `${
      baseApiUrls.METRICS
    }/players-with-release-info?${searchParams.toString()}`,
    baseFetcher
  )

  const head = (
    <>
      <h2>Versiones</h2>
      <div className="h-2" />
      <SearchInput
        className="w-80"
        placeholder="Buscar por punto de escucha o versión"
      />
      <div className="h-8" />
    </>
  )

  if (!data) {
    return (
      <>
        {head}
        {loading}
      </>
    )
  }

  return (
    <>
      {head}
      <h4>{data.count} resultados</h4>
      <table className="w-full max-w-4xl">
        <thead>
          <tr>
            <th>
              Nombre <SortButton field="title" />
            </th>
            <th>
              Cliente <SortButton field="client" />
            </th>
            <th>
              OS <SortButton field="os.name" />
            </th>
            <th>
              Versión <SortButton field="appVersion" />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.players.map((player) => (
            <PlayerRow key={player._id} {...player} />
          ))}
        </tbody>
      </table>
      <div className="text-center">
        <PagesNav rowCount={data.count} pageSize={40} />
      </div>
    </>
  )
}
