import { Pause, Pencil, Play, Trash } from 'phosphor-react'
import { useContext } from 'react'
import { Song } from '@ritmo/types'
import SongsManagerContext from './conext'
import { IconButton } from '@ritmo/ui'

export default function SongActions({ song }: { song: Song }) {
  const {
    playSong,
    playingSong,
    setSelectedSong,
    openDeleteModal,
    setEditModal,
    readOnly,
  } = useContext(SongsManagerContext)

  return (
    <td className="flex items-center justify-end text-accent opacity-20 group-hover:opacity-100 text-xl lg:text-xl gap-x-1.5 lg:gap-x-2">
      <IconButton
        size="small"
        className="hover:bg-accent/10"
        onClickCapture={(e) => {
          e.stopPropagation()
          playSong(song)
        }}
      >
        {playingSong?._id === song._id ? <Pause /> : <Play />}
      </IconButton>
      {!readOnly ? (
        <IconButton
          size="small"
          className="hover:bg-accent/10"
          onClickCapture={(e) => {
            e.stopPropagation()
            setSelectedSong(song)
            setEditModal(true)
          }}
        >
          <Pencil />
        </IconButton>
      ) : null}
      <IconButton
        size="small"
        className="hover:bg-accent/10"
        onClickCapture={(e) => {
          e.stopPropagation()
          setSelectedSong(song)
          openDeleteModal()
        }}
      >
        <Trash />
      </IconButton>
    </td>
  )
}
