import { emptySelected } from '../emptySelected'
import {
  selectedRange,
  selectOneDown,
  selectOneLeft,
  selectOneRight,
  selectOneUp,
} from '../selectRange'

export let pressingShift = false

export const setPressingShift = (value: boolean) => {
  pressingShift = value
}

export function handleKeyDown(e: KeyboardEvent) {
  if (!selectedRange) return
  const cell = e.target as HTMLElement
  if (cell.tagName !== 'TD') return

  if (e.key === 'Shift') {
    pressingShift = true
    return
  }

  // handle arrow keys
  if (e.key === 'ArrowUp') {
    e.preventDefault()
    selectOneUp()
    return
  }

  if (e.key === 'ArrowDown') {
    e.preventDefault()
    selectOneDown()
    return
  }

  if (e.key === 'ArrowLeft') {
    e.preventDefault()
    selectOneLeft()
    return
  }

  if (e.key === 'ArrowRight') {
    e.preventDefault()
    selectOneRight()
    return
  }

  if (e.key === 'Enter') {
    e.preventDefault()
    selectOneDown()
    return
  }

  if (cell.getAttribute('contentEditable') === 'true') return

  if (e.key === 'Backspace') {
    e.preventDefault()
    emptySelected()
    return
  }
}
