import { useMemo } from 'react'
import { Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { PlayerDownloadsRecord } from '../types'
import { AxisBottom } from '@visx/axis'
import { format } from 'date-fns'
import { Tooltip } from '@ritmo/ui'

export type DownloadsChartProps = {
  records: PlayerDownloadsRecord[]
}

export default function DownloadsChart({ records }: DownloadsChartProps) {
  // bounds
  const xMax = 400
  const yMax = 50

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: records.map((d) => d._time),
        padding: 0,
      }),
    [xMax, records]
  )

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...records.map((d) => Number(d._value)))],
      }),
    [yMax, records]
  )

  return (
    <svg width={xMax} height={yMax + 20}>
      <Group>
        {records.map((d) => {
          const barWidth = xScale.bandwidth()
          const barHeight = yMax - (yScale(Number(d._value)) ?? 0)
          const barX = xScale(d._time)
          const barY = yMax - barHeight
          return (
            <g key={`bar-${d._time}`}>
              <Bar
                x={(barX || 0) + barWidth / 2 - 2}
                y={barY}
                width={4}
                height={barHeight}
                fill="black"
              />
              <Tooltip text={d._value || '0'} delay={0} insideSvg>
                <rect
                  className="cursor-pointer"
                  x={barX}
                  y={0}
                  width={barWidth}
                  height={yMax}
                  fill="transparent"
                />
              </Tooltip>
            </g>
          )
        })}
      </Group>
      <AxisBottom
        scale={xScale}
        tickValues={records.map((r) => r._time)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        tickFormat={(d: string) => {
          try {
            return format(new Date(d), 'dd/MM')
          } catch {
            console.warn('Error formatting date', {
              date: d,
              type: 'usage-report',
            })
            return ''
          }
        }}
        top={yMax - 5}
        hideAxisLine
        hideTicks
      />
    </svg>
  )
}
