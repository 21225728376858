import { Outlet } from 'react-router-dom'
import Navbar from '../Navbar'
import { DashboardPlayBar } from './Playbar'

export default function Layout() {
  return (
    <div className="grid grid-rows-layout h-s-screen bg-gray-100 print:bg-transparent">
      <Navbar />
      <main id="main" className="overflow-y-auto">
        <div className="px-4 md:px-10 pb-10 max-w-7xl mx-auto">
          <Outlet />
        </div>
      </main>
      <DashboardPlayBar />
    </div>
  )
}
