import { IconButton } from '@ritmo/ui'
import { ArrowUp } from 'phosphor-react'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

interface SortButtonProps {
  field: string
}

export function SortButton({ field }: SortButtonProps) {
  const [params, setParams] = useSearchParams()

  const active = field === params.get('sort')

  const handleClick = useCallback(() => {
    setParams((curr) => {
      const direction = curr.get('direction')
      const active = field === curr.get('sort')
      curr.set('page', '1')
      curr.set('sort', field)
      curr.set('direction', direction === 'asc' && active ? 'desc' : 'asc')
      return curr
    })
  }, [field, setParams])

  return (
    <IconButton
      size="small"
      variant={active ? 'secondary' : 'custom'}
      onClick={handleClick}
      className={active ? '' : 'opacity-60'}
    >
      <ArrowUp
        className={
          params.get('direction') === 'desc' && active ? 'rotate-180' : ''
        }
      />
    </IconButton>
  )
}
