import { createContext } from 'react'
import useHowler from '../lib/playback/useHowler'

const defaultValue = {
  playSong: () => [],
  soundRef: null,
  requestSong: () => [],
}

export const HowlerContext = createContext<ReturnType<typeof useHowler> | typeof defaultValue>(
  defaultValue
)

export const HowlerProvider = ({ children }: { children: JSX.Element }) => {
  const howlerValue = useHowler()

  return <HowlerContext.Provider value={howlerValue}>{children}</HowlerContext.Provider>
}
