import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile, toBlobURL } from '@ffmpeg/util'
import { UploadInfo } from '../atoms/upload'
import { Metadata } from './upload/getMetadata'

const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/esm'
const TARGET_KBPS = 98

export const convertFile = async (
  file: File,
  metadata: Metadata,
  setUpload: React.Dispatch<React.SetStateAction<Record<string, UploadInfo>>>,
  fileNameWithoutExt: string
): Promise<File> => {
  const progressHandler = ({ progress }: { progress: number }) => {
    setUpload((curr) => ({
      ...curr,
      [fileNameWithoutExt]: {
        progress: progress * 100,
        status: isMp3 ? 'compressing' : 'converting to mp3',
        image: metadata.image,
      },
    }))
  }

  const isMp3 = metadata.ext === 'mp3'

  if (isMp3) return file

  const ffmpeg = new FFmpeg()

  const coreURL = await toBlobURL(
    `${baseURL}/ffmpeg-core.js`,
    'text/javascript'
  )
  const wasmURL = await toBlobURL(
    `${baseURL}/ffmpeg-core.wasm`,
    'application/wasm'
  )

  await ffmpeg.load({ coreURL, wasmURL }).catch(console.error)

  ffmpeg.on('progress', progressHandler)

  const destinationFileName = isMp3
    ? '_' + file.name
    : `${fileNameWithoutExt}.mp3`

  await ffmpeg.writeFile(file.name, await fetchFile(file))

  const qualityArgs =
    metadata.kbps <= TARGET_KBPS ? [] : ['-b:a', ` ${TARGET_KBPS}k`]
  await ffmpeg.exec(['-i', file.name, ...qualityArgs, destinationFileName])

  const ffmpegFile = (await ffmpeg.readFile(destinationFileName)) as Uint8Array
  const convertedFile = new File([ffmpegFile.buffer], destinationFileName, {
    type: 'audio/mp3',
  })

  ffmpeg.terminate()

  return convertedFile
}
