import Copy from '../../components/Copy'
import { Switch, PlaybackIndicator, Tooltip, Checkbox } from '@ritmo/ui'
import { Player, Connection } from '@ritmo/types'
import { useCallback, useMemo } from 'react'
import { Warning, WifiSlash } from 'phosphor-react'
import { Link } from 'react-router-dom'
import { formatRelative } from 'date-fns'
import { es } from 'date-fns/locale'
import { NotificationsToggle } from '../../components/NotificationsToggle'
import { OperatingSystemLogo } from '../../components/OperatingSystemLogo'

interface BranchTableItemProps {
  player: Player
  actions: JSX.Element | JSX.Element[]
  notificationsEnabled: boolean | null
  onChangeScheduling: (branchId: string, scheduling: boolean) => void
  onOpenRemoteDialog: () => void
  onChangeNotifications: (value: boolean) => void
  isSelected: boolean
  onSelect: () => void
}

const checkIfConnectionIsLost = (connection: Connection) =>
  connection.status === 'OFFLINE' && connection.disconnectReason === 'TIMEOUT'

export default function BranchTableItem({
  player,
  actions,
  notificationsEnabled,
  onChangeScheduling,
  onOpenRemoteDialog,
  onChangeNotifications,
  isSelected,
  onSelect,
}: BranchTableItemProps) {
  const online = player.connectionInfo.status === 'ONLINE'

  const handleRemoteClick = useCallback(() => {
    if (!online) return
    onOpenRemoteDialog()
  }, [online, onOpenRemoteDialog])

  const lostConnection = useMemo(
    () => checkIfConnectionIsLost(player.connectionInfo),
    [player.connectionInfo]
  )

  const changedAtLegible = useMemo(() => {
    if (!player.connectionInfo.statusChangedAt) return ''
    return `Desde: ${formatRelative(
      new Date(player.connectionInfo.statusChangedAt),
      new Date(),
      { locale: es }
    )}`
  }, [player.connectionInfo.statusChangedAt])

  return (
    <tr className={`select-none ${isSelected ? 'bg-slate-200/60' : ''}`}>
      <td className="cursor-pointer !pl-3" onClick={() => onSelect()}>
        <Checkbox standalone checked={isSelected} onChange={() => []} />
      </td>
      <td className="group relative">
        <Copy
          text={player.credential}
          className="hidden -right-2 group-hover:block absolute md:top-0 md:-left-3 z-20"
        />
        <Link
          to={player._id}
          className="text-sm sm:text-base hover:bg-slate-200 py-1 px-2 rounded-md w-full block line-clamp-1"
        >
          {player.credential}
        </Link>
      </td>
      <td className="w-40 flex flex-col text-center md:table-cell">
        {player?.tags?.map((tag) => (
          <p
            key={tag._id}
            className="rounded inline-flex px-[0.25rem] text-xs text-white mb-1"
            style={{ backgroundColor: tag.color }}
          >
            {tag.title}
          </p>
        ))}
      </td>
      <td className="hidden md:table-cell">
        <Switch
          checked={player.musicScheduling}
          onChange={() => {
            onChangeScheduling(player._id, !player.musicScheduling)
          }}
          label=""
        />
      </td>
      {notificationsEnabled !== null && (
        <td>
          <NotificationsToggle
            notificationsEnabled={notificationsEnabled}
            onChange={onChangeNotifications}
          />
        </td>
      )}
      <td>
        {player.linked || player.jwtId ? (
          <Tooltip text={changedAtLegible} delay={100} placement="left">
            <div
              className={`
              flex gap-x-1 md:gap-x-3 items-center group relative py-1 px-2 rounded-md
              text-sm md:text-base
              ${online ? 'hover:bg-slate-200 cursor-pointer' : ''}
            `}
              onClick={handleRemoteClick}
            >
              <div
                className={`
                text-[0px] md:text-base
                ${lostConnection ? 'opacity-40' : ''}
              `}
              >
                <PlaybackIndicator
                  status={player.playback.status}
                  connection={player.connectionInfo}
                />
              </div>
              {lostConnection && (
                <div className="opacity-60 ml-1">
                  <WifiSlash />
                </div>
              )}
              {player.socketInfo.connectError && (
                <div className="text-xs text-red-500 ml-1">
                  <Warning />
                </div>
              )}
            </div>
          </Tooltip>
        ) : (
          <div className="rounded py-0.5 px-2 border inline-flex text-xs">
            No vinculado
          </div>
        )}
      </td>
      <td className="hidden md:table-cell">
        {player.platform} - {player.appVersion}
      </td>
      <td className="hidden md:table-cell">
        <Tooltip text={player.os?.name} placement="left">
          <OperatingSystemLogo name={player.os?.name || ''} />
        </Tooltip>
      </td>
      <td className="hidden md:table-cell">
        <div className="flex items-center gap-2 justify-end">{actions}</div>
      </td>
    </tr>
  )
}
