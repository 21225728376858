import { CheckCircle, Circle } from 'phosphor-react'
import { HTMLAttributes } from 'react'

interface CheckItemProps extends HTMLAttributes<HTMLDivElement> {
  selected: boolean
  label: string | JSX.Element
  disabled?: boolean
}

export default function CheckItem({ selected, label, disabled = false, ...rest }: CheckItemProps) {
  return (
    <div
      className={`
        flex items-center space-x-2 my-1 cursor-pointer
        ${selected ? '' : 'opacity-50'}
        ${disabled ? 'pointer-events-none' : ''}
      `}
      {...rest}
    >
      {selected ? <CheckCircle /> : <Circle />}
      <span>
        {label}
      </span>
    </div>
  )
}