import { Megaphone } from 'phosphor-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { ErrorMessage } from './components/ErrorMessage'
import InfoHeader from './components/InfoHeader'
import { AdsPack, Download, Playlist } from '@ritmo/types'
import { usePlayer } from '../context'
import { baseApiUrls } from '../../../utils/consts'
import { baseFetcher } from '../../../lib/apis'

interface DownloadInfoProps {
  detail: Download
  playlist: Playlist | AdsPack
}

function AdsPackInfo({ playlist, detail }: DownloadInfoProps) {
  if (!playlist) return null
  return (
    <div>
      <div>
        {playlist.title} - {detail.status}
      </div>
      <small>
        {detail.blobsQ}/{detail.playlistSongsQ}
      </small>
      <br />
      <progress max={100} value={detail.percentage} />
    </div>
  )
}

export default function Ads() {
  const { clientId } = useParams()
  const { player } = usePlayer()
  const { data: adsPackArr } = useSWR<AdsPack[]>(
    `${baseApiUrls.AD}/${clientId}`,
    baseFetcher
  )

  const adsPacks = useMemo(() => {
    if (!adsPackArr) return {}
    return adsPackArr.reduce((acc, adsPack) => {
      acc[adsPack._id] = adsPack
      return acc
    }, {} as Record<string, AdsPack>)
  }, [adsPackArr])

  if (!player) return null

  const header = (
    <InfoHeader
      title="Anuncios"
      updated={player.storageInfo?.updated}
      Icon={Megaphone}
      isLive={player.connectionInfo.status === 'ONLINE'}
    />
  )

  if (player.streamOnly)
    return (
      <div>
        {header}
        <small>N/A (Stream only)</small>
      </div>
    )

  if (!player.storageInfo) return null

  if (!player.storageInfo.adsPacks)
    return (
      <div>
        {header}
        <ErrorMessage>
          La sucursal no contiene información de descarga por pack de anuncios
          (revisar versión)
        </ErrorMessage>
      </div>
    )

  const existentPacks = player.storageInfo.adsPacks

  return (
    <div>
      {header}
      {Object.keys(existentPacks).map((playerAdsPackId) => {
        const [, adsPackId] = playerAdsPackId.split(':')
        return (
          <AdsPackInfo
            key={adsPackId}
            detail={existentPacks[playerAdsPackId]}
            playlist={adsPacks[adsPackId]}
          />
        )
      })}
    </div>
  )
}
