import { IconButton } from '@ritmo/ui'
import { formatDistanceToNowStrict, isToday } from 'date-fns'
import { es } from 'date-fns/locale'
import { ArrowClockwise, Icon } from 'phosphor-react'
import { useMemo } from 'react'
import { live } from './live'

interface InfoHeaderProps {
  title: string
  updated?: string | Date
  Icon?: Icon
  onUpdateClicked?: () => void
  updating?: boolean
  isLive?: boolean
}

export default function InfoHeader({
  title,
  updated,
  Icon,
  onUpdateClicked,
  updating = false,
  isLive = false,
}: InfoHeaderProps) {
  const updateString = useMemo(() => {
    if (!updated) return ''
    const updatedDate = new Date(updated)
    const showDate = !isToday(updatedDate)
    const sinceStr = formatDistanceToNowStrict(updatedDate, {
      locale: es,
      addSuffix: true,
    })
    const formattedDate = showDate
      ? `(${updatedDate.toLocaleString()})`
      : `(${updatedDate.toLocaleTimeString()})`
    return `${sinceStr} ${formattedDate}`
  }, [updated])

  return (
    <div className="flex items-center justify-between mb-3 relative">
      {Icon ? (
        <Icon size={24} className="absolute -top-8 md:top-auto md:-left-10" />
      ) : null}
      <div className="flex items-center gap-x-4">
        <h4>{title}</h4>
        {isLive && live}
      </div>
      <div className="flex items-center gap-x-1">
        {onUpdateClicked ? (
          <IconButton onClick={() => onUpdateClicked()}>
            <ArrowClockwise className={updating ? 'animate-spin' : ''} />
          </IconButton>
        ) : null}
        {updated ? (
          <small className="text-xs md:text-sm text-right">
            {updateString}
          </small>
        ) : null}
      </div>
    </div>
  )
}
