import { ReactNode } from 'react'

interface DescProps {
  label: string
  value: ReactNode
}

export default function Desc({ label, value }: DescProps) {
  return (
    <div className="mb-2">
      <small>{label}</small><br />
      {value}
    </div>
  )
}