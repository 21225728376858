import { Player } from '@ritmo/types'
import { createContext, useContext } from 'react'
import { KeyedMutator } from 'swr'

export interface MonitorResponse {
  totalPlayers: number
  pagePlayers: Player[]
}

export interface MonitorContext {
  playersMutate: KeyedMutator<MonitorResponse>
  selectedPlayers: Record<string, boolean>
  toggleSelectedPlayer: (playerId: string) => void
  pageSelected: boolean
  togglePagePlayers: () => void
  resetAllItems: () => void
}

export const playersContext = createContext<MonitorContext>({
  playersMutate: () =>
    Promise.resolve({
      totalPlayers: 0,
      pagePlayers: [],
    }),
  selectedPlayers: {},
  toggleSelectedPlayer: () => [],
  pageSelected: false,
  togglePagePlayers: () => [],
  resetAllItems: () => [],
})

export const usePlayers = () => useContext(playersContext)
