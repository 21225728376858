import { handleFocus } from './listeners/handleFocus'

export function createCell(index: number, value: string): HTMLTableCellElement {
  const td = document.createElement('td')
  td.classList.add('border', 'focus:outline-none', 'cursor-cell', 'h-9', '!p-1')
  td.setAttribute('data-index', String(index))
  td.setAttribute('tabindex', '0')
  td.textContent = value

  td.addEventListener('focus', handleFocus)

  return td
}
