import { Outlet } from 'react-router-dom'
import Sections from './Sections'

export default function ClientLayout() {
  return (
    <>
      <Sections />
      <div className="h-10" />
      <Outlet />
    </>
  )
}
