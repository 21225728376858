import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'
import { Button, Loading } from '@ritmo/ui'
import BackButton from '../../components/BackButton'
import playerContext, { PlayerWith7DaysDownloads, usePlayer } from './context'
import BranchScreen from './detail/Branch'
import useBranchActions from '../../hooks/useBranchActions'
import BranchActions from '../Client/BranchActions'

function BranchDetailActionsButton() {
  const { player, mutate } = usePlayer()
  const { handleBranchAction, modals } = useBranchActions(mutate)

  if (!player) return null

  return (
    <>
      <div className="ml-auto">
        <BranchActions
          onDispatch={(type) => handleBranchAction(type, player)}
          isDisabled={!player.enabled}
          trigger={
            <Button variant="secondary" size="small">
              Acciones
            </Button>
          }
        />
      </div>
      {modals}
    </>
  )
}

export default function PlayerDetails() {
  const { playerId } = useParams()

  const { data: player, mutate } = useSWR<PlayerWith7DaysDownloads>(
    `${baseApiUrls.BRANCHES}/${playerId}`,
    baseFetcher,
    {
      refreshInterval: 10 * 1000,
    }
  )

  if (!player) {
    return <Loading className="text-accent text-center mt-6" />
  }

  return (
    <playerContext.Provider value={{ player, mutate }}>
      <div className="flex justify-between items-center sticky top-0 bg-slate-100 z-50 py-2">
        <BackButton title={player.title} />
        {!player.enabled && (
          <div className="px-2 rounded bg-slate-700 text-white ml-6">
            Inactiva
          </div>
        )}
        <BranchDetailActionsButton />
      </div>
      <div className="h-10" />
      <div className="px-12">
        <BranchScreen />
      </div>
    </playerContext.Provider>
  )
}
