import { Schedule } from '@ritmo/types'

const days = [1, 2, 3, 4, 5, 6, 0]

const ninteToFivePreset: Schedule[] = days.map(i => ({
  startDay: i.toString(),
  endDay: i.toString(),
  startHour: '09:00',
  endHour: '17:00',
}))

const lateNight: Schedule[] = days.map(i => ({
  startDay: i.toString(),
  endDay: (i == 6 ? 0 : i + 1).toString(),
  startHour: '19:00',
  endHour: '03:00',
}))

const afternoon: Schedule[] = days.map(i => ({
  startDay: i.toString(),
  endDay: i.toString(),
  startHour: '16:00',
  endHour: '23:00',
}))

const weekends: Schedule[] = [5, 6, 0].map((i) => ({
  startDay: i.toString(),
  endDay: i.toString(),
  startHour: '16:00',
  endHour: '23:00',
}))

const weekdays: Schedule[] = [1, 2, 3, 4, 5].map((i) => ({
  startDay: i.toString(),
  endDay: i.toString(),
  startHour: '08:00',
  endHour: '20:00',
}))

export const presets = [
  {
    id: '9to5',
    label: '9 a 17, todos los días',
    value: ninteToFivePreset,
  },
  {
    id: 'afternoon',
    label: 'Tarde, todos los días',
    value: afternoon,
  },
  {
    id: 'lateNight',
    label: 'Noche y madrugada',
    value: lateNight,
  },
  {
    id: 'weekend',
    label: 'Noche, fin de semana',
    value: weekends,
  },
  {
    id: 'weekdays',
    label: '8 a 20, días de semana',
    value: weekdays,
  }
]