import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function useRecaptchaToken() {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const generateRecaptchaToken = async () => {
    if (!executeRecaptcha) return null
    return await executeRecaptcha('admin_login')
  }

  return { generateRecaptchaToken }
}