import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'

const deleteTracks = async (id: string, type: 'bulk' | 'all', body: string[], collection: 'playlists' | 'ads') => {
  await save({
    method: 'put',
    url: `${baseApiUrls.PLAYLIST}/${collection}/delete-${type}-tracks/${id}`,
    payload: body,
    messages: {
      loading: 'Eliminando canciones',
      error: 'Error al eliminar canciones',
      success: 'Canciones eliminadas',
    },
  })
}

export default deleteTracks