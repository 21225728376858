import { Branch } from '@ritmo/types'
import { SelectionAll, SelectionSlash } from 'phosphor-react'
import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import CheckItem from '../CheckItem'
import { Field } from '@ritmo/ui'
import { baseFetcher } from '../../lib/apis'

const BranchesSelector = ({
  clientId,
  selectedBranches,
  toggleBranches,
  setBranches,
  disabled = false,
}: {
  clientId: string
  selectedBranches: string[]
  toggleBranches: (id: string) => void
  setBranches: (newState: string[]) => void
  disabled?: boolean
}) => {
  const { data: branches = [] } = useSWR<Branch[]>(
    `${baseApiUrls.CLIENT}/${clientId}/branches`,
    baseFetcher,
    { fallbackData: [] },
  )

  const BulkSelectionIcon = selectedBranches.length ? SelectionSlash : SelectionAll

  const toggleSelection = (
    <button
      className="text-sm text-accent-dark flex gap-x-1 items-center absolute right-0 top-0 z-10"
      onClick={() => {
        setBranches(selectedBranches.length ? [] : branches.map((b) => b._id))
      }}
      disabled={disabled}
    >
      <BulkSelectionIcon />
      {selectedBranches.length ? 'Deseleccionar todo' : 'Seleccionar todo'}
    </button>
  )

  return (
    <div className="relative">
      {toggleSelection}
      <Field label="Sucursales" >
        <div className="overflow-y-auto max-h-60">
          {branches.map((branch) => (
            <CheckItem
              key={branch._id}
              label={branch.title}
              selected={Boolean(selectedBranches.includes(branch._id))}
              onClick={() => toggleBranches(branch._id)}
              disabled={disabled}
            />
          ))}
        </div>
      </Field>
    </div>

  )
}

export default BranchesSelector
