import { useContext, useState } from 'react'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import EditBranch from './EditBranch'
import { Pencil } from 'phosphor-react'
import BranchActions from './BranchActions'
import BranchTableItem from './BranchTableItem'
import useBranchActions, {
  branchUpdateToastMessages,
  userUpdateToastMessages,
} from '../../hooks/useBranchActions'
import { Checkbox, IconButton } from '@ritmo/ui'
import { AuthContext } from '../../contexts/AuthContext'
import { Player } from '@ritmo/types'
import RemoteDialog from './RemoteDialog'
import { MonitorResponse, usePlayers } from '../../contexts/players'
import { isManager } from '../../lib/utils'

interface TableProps {
  pagePlayers: Player[]
  totalPlayers: number
}

const updateBranches2 = async (
  branchId: string,
  musicScheduling: boolean,
  newBranches: Player[],
  totalPlayers: number
): Promise<MonitorResponse> => {
  await save({
    payload: { musicScheduling },
    url: `${baseApiUrls.BRANCHES}/${branchId}/scheduling`,
    method: 'patch',
    messages: branchUpdateToastMessages,
  })
  return {
    pagePlayers: newBranches,
    totalPlayers,
  }
}

const toggleBranchNotify = async (branchId: string, notify: boolean) => {
  const endpoints = notify ? 'add-branch' : 'remove-branch'
  await save({
    payload: { branchId },
    url: `${baseApiUrls.USER}/${endpoints}`,
    method: 'patch',
    messages: userUpdateToastMessages,
  })
}

const Table = ({ pagePlayers, totalPlayers }: TableProps) => {
  const { user, fetchAndUpdate } = useContext(AuthContext)
  const [remoteDialogPlayer, setRemoteDialogPlayer] = useState<string | null>(
    null
  )
  const [playerToEdit, setPlayerToEdit] = useState<Player | null>(null)
  const {
    playersMutate,
    selectedPlayers,
    toggleSelectedPlayer,
    pageSelected,
    togglePagePlayers,
  } = usePlayers()

  const { handleBranchAction, modals } = useBranchActions(playersMutate)

  const updateBranches = async () => {
    await playersMutate()
    setPlayerToEdit(null)
  }

  const handleChangeScheduling = async (
    branchId: string,
    musicScheduling: boolean
  ) => {
    const newBranches = pagePlayers.map((player) => {
      if (player._id !== branchId) return player
      return {
        ...player,
      }
    })
    const optimisticData = {
      pagePlayers: newBranches,
      totalPlayers,
    }

    playersMutate(
      updateBranches2(branchId, musicScheduling, newBranches, totalPlayers),
      { optimisticData, rollbackOnError: true }
    )
  }

  const handleChangeNotifications = async (
    value: boolean,
    branchId: string
  ) => {
    await toggleBranchNotify(branchId, value)
    await fetchAndUpdate()
  }

  const isManagerUser = user && isManager(user)

  const thead = (
    <thead>
      <tr>
        <th className="!pl-3 cursor-pointer" onClick={togglePagePlayers}>
          <Checkbox standalone checked={pageSelected} onChange={() => []} />
        </th>
        <th>Credencial</th>
        <th>Tags</th>
        <th className="truncate max-w-0 hidden md:table-cell">Programación</th>
        {isManagerUser ? (
          <th className="truncate max-w-[56px]">Notificaciones</th>
        ) : null}
        <th>Estatus</th>
        <th className="hidden md:table-cell">Versión</th>
        <th className="hidden md:table-cell">OS</th>
        <th className="hidden md:table-cell" />
      </tr>
    </thead>
  )

  return (
    <>
      <table className="table-auto w-full">
        {thead}
        <tbody>
          {pagePlayers.map((player) => (
            <BranchTableItem
              key={player._id}
              player={player}
              notificationsEnabled={
                !isManagerUser
                  ? null
                  : user.notifyBranches?.includes(player._id)
              }
              onChangeScheduling={handleChangeScheduling}
              onOpenRemoteDialog={() => {
                setRemoteDialogPlayer(player._id)
              }}
              onChangeNotifications={(value) =>
                handleChangeNotifications(value, player._id)
              }
              actions={
                <>
                  {user?.role === 'admin' ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setPlayerToEdit(player)
                      }}
                    >
                      <Pencil className="text-accent" size={22} />
                    </IconButton>
                  ) : null}
                  <BranchActions
                    onDispatch={(type) => handleBranchAction(type, player)}
                    isDisabled={!player.enabled}
                  />
                </>
              }
              isSelected={selectedPlayers[player._id] || false}
              onSelect={() => toggleSelectedPlayer(player._id)}
            />
          ))}
        </tbody>
      </table>
      {playerToEdit ? (
        <EditBranch
          player={playerToEdit}
          onClose={() => setPlayerToEdit(null)}
          updateBranches={updateBranches}
        />
      ) : null}
      {remoteDialogPlayer ? (
        <RemoteDialog
          playerId={remoteDialogPlayer}
          onClose={() => setRemoteDialogPlayer(null)}
        />
      ) : null}
      {modals}
    </>
  )
}

export default Table
