import { Check } from 'phosphor-react'

export const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#00bcd4',
  '#009688',
  '#8bc34a',
  '#ff9800',
  '#795548',
  '#607d8b',
]

interface ColorPickerProps {
  value: string
  onChange: (color: string) => void
  disabled?: boolean
}

export default function ColorPicker({ value, onChange, disabled = false }: ColorPickerProps) {
  return (
    <div className={`grid grid-cols-6 gap-3 ${disabled ? 'pointer-events-none' : ''}`}>
      {colors.map(color => (
        <div
          key={color}
          style={{ backgroundColor: color }}
          className="w-5 h-5 rounded-full cursor-pointer flex items-center justify-center"
          onClick={() => onChange(color)}
        >
          {color === value ? <Check /> : null}
        </div>
      ))}
    </div>
  )
}