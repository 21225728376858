import { Button, Modal, useConfirmModal } from '@ritmo/ui'
import { baseApiUrls } from '../../utils/consts'
import { useState } from 'react'
import { baseFetcher } from '../../lib/apis'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'

export default function ManagerPassword() {
  const { clientId } = useParams<{ clientId: string }>()
  const [generatedPassword, setGeneratedPassword] = useState<string | null>(null)

  const generatePassword = async () => {
    try {
      const { password } = await baseFetcher<{ password: string }>(`${baseApiUrls.CLIENT}/${clientId}/generate-manager-password`)
      setGeneratedPassword(password)
    } catch {
      toast.error('No se pudo generar la contraseña')
    }
  }

  const { open, modal } = useConfirmModal({
    title: 'Generar contraseña?',
    description: 'Esta acción dejará obsoleta la contraseña actual del manager',
    onConfirm: generatePassword,
    confirmText: 'Sí, generar nueva contraseña'
  })

  return (
    <>
      <Button onClick={open} size="small">
        Generar
      </Button>
      <Modal open={generatedPassword !== null} >
        <h4 className="mb-2">
          Contraseña generada
        </h4>
        <p className="mb-4">
          Esta contraseña no será guardada, asegúrate de copiarla a un lugar seguro.
        </p>
        <h2>
          {generatedPassword}
        </h2>
        <div className="text-right mt-4">
          <Button onClick={() => setGeneratedPassword(null)}>
            Cerrar
          </Button>
        </div>
      </Modal>
      {modal}
    </>
  )
}
