export function getDataFromTable() {
  const tbody = document.querySelector('tbody')
  if (!tbody) return []
  const trs = tbody.querySelectorAll('tr')
  const data: string[] = []
  trs.forEach((tr) => {
    if (tr.classList.contains('hidden')) return
    const tds = tr.querySelectorAll('td')
    const tdsArr = Array.from(tds)
    const tdsTexts = tdsArr.map((td) => td.textContent)
    const rowText = tdsTexts.join('\r')
    data.push(rowText)
  })
  return data
}
