import { IconButton, Dropdown, MenuItem } from '@ritmo/ui'
import { ArrowClockwise, DotsThreeVertical, Password, SignOut, ThumbsDown, ThumbsUp, Trash } from 'phosphor-react'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

export enum BranchAction {
  GEN_PASSWORD,
  SIGN_OUT,
  FORCE_RELOAD,
  DISABLE,
  ENABLE,
  DELETE,
}

export interface BranchActionsProps {
  onDispatch: (action: BranchAction) => void
  isDisabled?: boolean
  trigger?: React.ReactNode
}

const defaultTrigger = (
  <IconButton size="small">
    <DotsThreeVertical className="text-accent" weight="bold" size={22} />
  </IconButton>
)

export default function BranchActions({ onDispatch, isDisabled = false, trigger = defaultTrigger }: BranchActionsProps) {
  const { user } = useContext(AuthContext)

  return (
    <Dropdown
      renderTrigger={trigger}
    >
      <MenuItem
        onClick={() => onDispatch(BranchAction.GEN_PASSWORD)}
      >
        <Password className="text-accent" size={24} />
        <span>Generar contraseña</span>
      </MenuItem>
      <MenuItem
        onClick={() => onDispatch(BranchAction.SIGN_OUT)}
      >
        <SignOut className="text-accent" size={24} />
        <span>Cerrar sesión</span>
      </MenuItem>
      {user?.role === 'admin' ? (
        <>
          <MenuItem
            onClick={() => onDispatch(BranchAction.FORCE_RELOAD)}
          >
            <ArrowClockwise className="text-accent" size={24} />
            <span>Limpiar cache</span>
          </MenuItem>
          <MenuItem
            onClick={() => onDispatch(isDisabled ? BranchAction.ENABLE : BranchAction.DISABLE)}
          >
            {isDisabled
              ? <ThumbsUp className="text-accent" size={24} />
              : <ThumbsDown className="text-accent" size={24} />
            }
            <span>{isDisabled ? 'Habilitar' : 'Deshabilitar'}</span>
          </MenuItem>
          <MenuItem
            onClick={() => onDispatch(BranchAction.DELETE)}
          >
            <Trash className="text-accent" size={24} />
            <span>Eliminar</span>
          </MenuItem>
        </>
      ) : <></>}
    </Dropdown>
  )
}