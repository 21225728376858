import { getCoordsFromCell } from '../getCoordsFromElement'
import { getTable } from '../getTable'
import { pressingShift } from './handleKeyDown'
import { selectedRange, selectRange } from '../selectRange'

let cursorSelecting = false

export function handleMouseDown(e: MouseEvent) {
  if (pressingShift && selectedRange) {
    e.preventDefault()
    selectRange(selectedRange[0], getCoordsFromCell(e.target as HTMLElement))
    return
  }
  cursorSelecting = true
}

export function handleMouseMove(e: MouseEvent) {
  if (!cursorSelecting || !selectedRange) return
  getTable().classList.add('select-none')
  selectRange(selectedRange[0], getCoordsFromCell(e.target as HTMLElement))
}

export function handleMouseUp() {
  getTable()?.classList.remove('select-none')
  cursorSelecting = false
}
