import { Plus } from 'phosphor-react'
import { ReactNode, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@ritmo/ui'
import { AuthContext } from '../../contexts/AuthContext'
import ViewSwitch from './ViewSwitch'

export default function TopBar({ children }: { children?: ReactNode }) {
  const { user } = useContext(AuthContext)
  const { pathname } = useLocation()
  const isList = pathname.includes('list')

  const toNew = isList ? '../new?list=true' : 'new'

  return (
    <div className="flex w-full justify-between items-center">
      <h4 className={`${isList ? '' : 'hidden'} md:block`}>
        Programación
      </h4>
      {children}
      <div className="flex items-center">
        <ViewSwitch />
        {user?.role === 'admin' ? (
          <Link to={toNew} className="ml-2 md:ml-4">
            <Button>
              <Plus />
              <span className="hidden md:block">
                &nbsp; Nueva programación
              </span>
            </Button>
          </Link>
        ) : null}
      </div>
    </div>
  )
}