import { createCell } from '../createCell'
import { getColumnsLength } from '../getColumnsLength'
import { getTable } from '../getTable'

export function handlePaste(e: ClipboardEvent) {
  e.preventDefault()
  if (!e.clipboardData) return

  const table = getTable()
  const columnsLength = getColumnsLength(table)

  const tbody = table.querySelector('tbody')
  if (!tbody) return

  const cellEl = e.target as HTMLTableCellElement
  if (!cellEl) return

  const rowStartEl = cellEl.parentElement as HTMLElement
  const startColIndex = Number(cellEl.dataset.index)
  const startRowIndex = Number(rowStartEl.dataset.index)

  const text = e.clipboardData.getData('text')

  text.split('\n').forEach((row, rowIndex) => {
    const columnsPaste = row.split('\t')
    const rowEl = tbody.querySelector(
      `tr[data-index="${rowIndex + startRowIndex}"]`
    )
    if (rowEl) {
      for (let i = startColIndex; i < columnsLength; i++) {
        const td = rowEl.children[i] as HTMLTableCellElement
        td.textContent = columnsPaste[i - startColIndex] || td.textContent
      }
      return
    }

    const tr = document.createElement('tr')
    tr.setAttribute('data-index', String(rowIndex + startRowIndex))

    for (let i = 0; i < columnsLength; i++) {
      const td = createCell(i, columnsPaste[i - startColIndex] || '')
      tr.appendChild(td)
    }

    tbody.appendChild(tr)
  })
}
