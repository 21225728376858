import { FormEvent, useState } from 'react'
import { KeyedMutator } from 'swr'
import { Song } from '@ritmo/types'
import { Field, Modal, ConfirmButtons } from '@ritmo/ui'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'

interface EditSongModalProps {
  open: boolean
  onClose: () => void
  song: Song
  mutate: KeyedMutator<Song[]>
}

type SongInfo = Pick<Song, 'title' | 'artist' | 'album' | 'year'>

export default function EditSongModal({ open, onClose, song, mutate }: EditSongModalProps) {

  const [saving, setSaving] = useState(false)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSaving(true)

    const formData = new FormData(e.currentTarget)
    const newSongInfo = Object.fromEntries(formData) as unknown as SongInfo

    await save({
      url: `${baseApiUrls.ASSETS}/tracks/${song._id}`,
      payload: newSongInfo,
      method: 'patch',
      messages: {
        loading: 'Guardando track',
        error: 'Error actualizando track',
        success: 'Track actualizado'
      }
    })
    setSaving(false)
    mutate()
    onClose()
  }

  return (
    <Modal open={open} >
      <h4 className="mb-4">
        Editar track
      </h4>
      <form onSubmit={handleSubmit} className="space-y-3 w-72">
        <Field label="Nombre">
          <input
            name="title"
            className="input w-full"
            required
            defaultValue={song.title}
          />
        </Field>
        <Field label="Artista">
          <input
            name="artist"
            className="input w-full"
            defaultValue={song.artist}
          />
        </Field>
        <Field label="Álbum">
          <input
            name="album"
            className="input w-full"
            defaultValue={song.album}
          />
        </Field>
        <Field label="Año">
          <input
            name="year"
            className="input w-full"
            defaultValue={song.year}
          />
        </Field>
        <div className="h-3" />
        <ConfirmButtons
          confirmText="Guardar"
          onCancel={onClose}
          disabled={saving}
        />
      </form>
    </Modal>
  )
}