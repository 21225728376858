import { Link, useParams } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import EditPhotoIcon from '../../components/EditPhotoIcon'
import { AdsPack } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import PlaylistActions from '../Playlists/PlaylistActions'
import UploadingTracks from '../Playlists/UploadingTracks'
import AdInfo from './AdInfo'
import SongsManager from '../../components/SongsManager'
import { Button, Cover } from '@ritmo/ui'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { ChartLineUp } from 'phosphor-react'

export default function AdsPackScreen() {
  const { adsPackId } = useParams()

  const { data: ad, mutate } = useSWR<AdsPack>(
    `${baseApiUrls.AD}/${adsPackId}/details`,
    baseFetcher
  )

  if (!ad) return null

  return (
    <>
      <div className="flex justify-between items-center">
        <BackButton title={ad.title} />
        <Link to="metrics">
          <Button variant="secondary">
            <ChartLineUp size={18} />
            &nbsp; Métricas
          </Button>
        </Link>
      </div>
      <div className="h-10" />
      <div className="md:flex gap-8">
        <div className="md:w-1/4">
          <Cover className="relative" image={ad.image}>
            <>
              <EditPhotoIcon
                resourceId={ad._id}
                onChange={mutate}
                type={'announcements'}
              />
              <div className="h-32 w-full absolute bottom-0 bg-gradient-to-t from-black/20 to-transparent z-10" />
            </>
          </Cover>
          <AdInfo ad={ad} onChange={mutate} />
          <PlaylistActions />
        </div>
        <div className="flex-1 mt-4 md:mt-0">
          <SongsManager title="Publicidades" allTracks={ad.songs} />
        </div>
      </div>
      <UploadingTracks playlistId={ad._id} />
    </>
  )
}
