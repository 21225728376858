import { Link, useParams } from 'react-router-dom'
import { baseApiUrls } from '../../utils/consts'
import { Button, PlaylistItem } from '@ritmo/ui'
import Card from '../../components/Card'
import { Plus } from 'phosphor-react'
import EmptyMessage from '../../components/EmptyMessage'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import { Playlist, User } from '@ritmo/types'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentPlaylistIdState, playbackState } from '../../atoms/playback'
import { HowlerContext } from '../../contexts/HowlerContext'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'

const Playlists = () => {
  const { clientId } = useParams()
  const { user } = useContext(AuthContext)
  const [currentPlaylistId, setCurrentPlaylistId] = useRecoilState(
    currentPlaylistIdState
  )
  const { requestSong, soundRef } = useContext(HowlerContext)
  const playbackStatus = useRecoilValue(playbackState)
  const isAdmin = user?.role === 'admin'

  const { data: playlists } = useSWR<Playlist[]>(
    `${baseApiUrls.PLAYLIST}/${clientId}`,
    baseFetcher
  )

  const handlePlay = (playlistId: string) => {
    if (playlistId === currentPlaylistId) {
      const sound = soundRef?.current
      return sound?.playing() ? sound?.pause() : sound?.play()
    }
    setCurrentPlaylistId(playlistId)
    requestSong(playlistId)
  }

  const _user = user as User & { permissions?: string[] }
  const canAccessSongs =
    !isAdmin && _user?.permissions?.includes('get_playlists_songs')

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <h4>Playlists</h4>
        {isAdmin ? (
          <Link to="new-playlist">
            <Button>
              <Plus />
              &nbsp; Nueva playlist
            </Button>
          </Link>
        ) : null}
      </div>
      <div className="cards-grid">
        {playlists?.map((playlist) =>
          isAdmin || canAccessSongs ? (
            <Card
              key={playlist._id}
              image={playlist.image}
              title={playlist.title}
              link={`${playlist._id}?sort=title&direction=asc`}
            />
          ) : (
            <PlaylistItem
              key={playlist._id}
              onClick={() => handlePlay(playlist._id)}
              isCurrent={currentPlaylistId === playlist._id}
              playing={
                playlist._id === currentPlaylistId && playbackStatus.playing
              }
              title={playlist.title}
              image={playlist.image}
            />
          )
        )}
      </div>
      {playlists && !playlists.length ? <EmptyMessage /> : null}
    </>
  )
}

export default Playlists
