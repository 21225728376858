import { Kanban, Rows } from 'phosphor-react'
import { Link, useLocation } from 'react-router-dom'
import { IconButton } from '@ritmo/ui'

export default function ViewSwitch() {

  const { pathname } = useLocation()
  const isList = pathname.includes('list')

  return (
    <div className="bg-slate-200 rounded-full h-9 flex items-center space-x-1">
      <Link className="flex" to={!isList ? 'list' : '.'}>
        <IconButton variant={isList ? 'primary' : 'custom'}>
          <Rows size={22} />
        </IconButton>
      </Link>
      <Link className="flex" to={!isList ? '.' : '..'}>
        <IconButton variant={!isList ? 'primary' : 'custom'}>
          <Kanban size={22} />
        </IconButton>
      </Link>
    </div>
  )
}