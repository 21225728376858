import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Button } from '@ritmo/ui'
import { baseApiUrls } from '../../utils/consts'
import { AdsPack } from '@ritmo/types'
import Card from '../../components/Card'
import { Plus } from 'phosphor-react'
import { format } from 'date-fns'
import { getAdStatus } from '../../lib/getAdStatus'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { ChangeEvent, useCallback, useMemo } from 'react'

const mapStatusClass = {
  active: 'bg-lime-500 text-white',
  pending: 'border border-slate-300',
  expired: 'bg-slate-500 text-white',
}

const AdCard = ({ title, image, _id, startDay, endDay }: AdsPack) => {
  const startInLocal = `${startDay}T00:00:00`
  const endInLocal = `${endDay}T00:00:00`

  const status = getAdStatus(startInLocal, endInLocal)

  const dateTag = (
    <div className={`${mapStatusClass[status]} rounded px-2 py-0.5 inline font-light text-xs`}>
      {format(new Date(startInLocal), 'dd/MM/yyyy')}
      &nbsp;→&nbsp;
      {format(new Date(endInLocal), 'dd/MM/yyyy')}
    </div>
  )

  const cardTitle = (
    <>
      {dateTag}
      <div className="line-clamp-1 mt-2">{title}</div>
    </>
  )

  return <Card image={image} title={cardTitle} link={_id} />
}

const Ads = () => {
  const { clientId } = useParams()
  const [params, setSearchParams] = useSearchParams()

  const { data: ads } = useSWR<AdsPack[]>(`${baseApiUrls.AD}/${clientId}`, baseFetcher)

  const filteredAds = useMemo(() => {
    if (!ads) return []
    return ads.filter((ad) => {
      const status = getAdStatus(`${ad.startDay}T00:00:00`, `${ad.endDay}T00:00:00`)
      if (params.get('expired_only') === 'true') {
        return status === 'expired'
      }
      return status !== 'expired'
    })
  }, [params, ads])

  const toggleShowExpiredChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setSearchParams((curr) => {
        curr.set('expired_only', e.target.value)
        return curr
      })
    },
    [setSearchParams]
  )

  const expiredSelect = (
    <select
      className="input"
      value={params.get('expired_only') === 'true' ? 'true' : 'false'}
      onChange={toggleShowExpiredChange}
    >
      <option value="false">Actuales o futuras</option>
      <option value="true">Expiradas</option>
    </select>
  )

  return (
    <>
      <div className="flex justify-end items-center">
        <h4 className="mr-auto">Publicidades</h4>
        <Link className="mr-2" to="shifts">
          <Button variant="secondary" className="p-2">
            Turnos
          </Button>
        </Link>
        <Link to="new-ad">
          <Button className="p-2">
            <Plus />
            &nbsp;Nueva publicidad
          </Button>
        </Link>
      </div>
      <div className="h-3" />
      {expiredSelect}
      <div className="cards-grid mt-8">
        {filteredAds.map((ad) => (
          <AdCard key={ad._id} {...ad} />
        ))}
      </div>
    </>
  )
}

export default Ads
