export const dateInputFormat = 'yyyy-MM-dd'

export const baseApiUrls = {
  AUTH: '/auth/v1',
  CLIENT: '/core/v1/clients',
  PLAYLIST: '/core/v1/playlists/dashboard',
  AD: '/core/v1/notices/dashboard',
  BRANCHES: '/core/v1/branches',
  SCHEDULE: '/core/v1/schedule',
  SHIFTS: '/core/v1/shifts',
  ASSETS: '/assets/v1',
  UPLOAD: '/core/v1/upload',
  STATS: '/core/v1/stats',
  B_SCHEDULE: '/core/v1/branches-schedule',
  TRACKS: '/core/v1/tracks',
  USER: '/core/v1/user',
  BUSINESSES: '/core/v1/businesses',
  LOCATION: '/core/v1/location',
  METRICS: '/metrics',
  TAGS: '/core/v1/tags',
}
