import { Equalizer } from 'phosphor-react'
import Desc from './components/Desc'
import InfoHeader from './components/InfoHeader'
import { usePlayer } from '../context'
import { PlaybackIndicator } from '@ritmo/ui'
import useSWR from 'swr'
import { baseApiUrls } from '../../../utils/consts'
import { baseFetcher } from '../../../lib/apis'
import { Song } from '@ritmo/types'

export default function PlaybackInfo() {
  const { player } = usePlayer()

  const trackId = player?.playback.trackId

  const { data: track } = useSWR<Song>(
    trackId && `${baseApiUrls.TRACKS}/${trackId}`,
    baseFetcher
  )

  if (!player) return null

  const streaming =
    typeof player.playback.streaming === 'undefined'
      ? '-'
      : String(player.playback.streaming)

  const playlistTitle = player.playback.playlistTitle

  return (
    <div>
      <InfoHeader
        title="Reproducción"
        updated={player.playback.updated}
        Icon={Equalizer}
        isLive={player.connectionInfo.status === 'ONLINE'}
      />
      <Desc label="Canción" value={`${track?.title} - ${track?.artist}`} />
      <Desc label="Playlist" value={playlistTitle} />
      <Desc
        label="Estatus"
        value={<PlaybackIndicator status={player.playback.status} />}
      />
      <Desc label="Streaming" value={streaming} />
    </div>
  )
}
