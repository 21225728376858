import { setPressingShift } from './handleKeyDown'
import { selectedRange, selectRange } from '../selectRange'

export function handleKeyUp(e: KeyboardEvent) {
  if (e.key === 'Shift') {
    setPressingShift(false)
  }
  if (!selectedRange) return
  const [start, end] = selectedRange
  selectRange(start, end)
}
