import { ArrowLeft } from 'phosphor-react'
import { Link, useSearchParams } from 'react-router-dom'
import { IconButton } from '@ritmo/ui'

export default function BackButton({
  title,
  to = '../',
}: {
  title?: string
  to?: string
}) {
  const [searchParams] = useSearchParams()

  const finalTo = searchParams.get('back') || to

  return (
    <div className="flex space-x-4 items-center">
      <Link to={finalTo}>
        <IconButton>
          <ArrowLeft size={26} />
        </IconButton>
      </Link>
      <h4>{title}</h4>
    </div>
  )
}
