import { Button, Modal } from '@ritmo/ui'
import { FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { baseApi, baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import useSWR from 'swr'
import { Business, WithId } from '@ritmo/types'
import { EditableTable } from '../../components/EditableTable/EditableTable'
import { formToSearchParams } from '../../utils/formToSearchParams'
import { getDataFromTable } from '../../components/EditableTable/getDataFromTable'
import { initTable } from '../../components/EditableTable/initTable'

interface BulkDataResponseSuccess {
  ok: true
  updatedPlayers: number
}

interface BulkDataResponseError {
  ok: false
  erroredCredentials: string[]
}

type BulkDataResponse = BulkDataResponseSuccess | BulkDataResponseError

const columns = [
  'Nombre',
  'Credencial',
  'Dirección',
  'Ciudad',
  'Provincia/Estado',
  'Contacto',
  'Email',
  'Teléfono',
]

export default function UpdateBulkPlayers() {
  const { clientId } = useParams()
  const [saving, setSaving] = useState(false)
  const [erroredCredentials, setErroredCredentials] = useState<string[]>([])
  const { data: business } = useSWR<WithId<Business>[]>(
    `${baseApiUrls.BUSINESSES}/by-client/${clientId}`,
    baseFetcher
  )

  const handleSave = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const toastId = toast.loading('Guardando...')
      setSaving(true)
      try {
        const htmlForm = e.currentTarget as HTMLFormElement
        const searchParams = formToSearchParams(htmlForm)
        const players = getDataFromTable()
        players.forEach((player) => {
          searchParams.append('players', player)
        })

        const { data } = await baseApi.patch<BulkDataResponse>(
          `${baseApiUrls.BRANCHES}/${clientId}/bulk`,
          searchParams
        )
        if (data.ok) {
          toast.success(`Se actualizaron ${data.updatedPlayers} players`)
          initTable()
        } else {
          setErroredCredentials(data.erroredCredentials)
        }
      } catch (err) {
        toast.error('No se pudieron agregar las sucursales')
      } finally {
        setSaving(false)
        toast.dismiss(toastId)
      }
    },
    [clientId]
  )

  return (
    <>
      <form onSubmit={handleSave} className="space-y-4">
        <EditableTable columns={columns} />
        <select className="input" name="businessId">
          <option value="">Razón social (opcional)</option>
          {business?.map((business) => (
            <option key={business._id} value={business._id}>
              {business.name}
            </option>
          ))}
        </select>
        <p className="bg-amber-200 rounded-md p-2">
          ⚠️ Atención! dejar sin seleccionar una razón social dejará todos los
          players que actualices sin razón social asignada.
        </p>
        <div>
          <Button disabled={saving} type="submit">
            Actualizar players
          </Button>
        </div>
      </form>
      <Modal open={erroredCredentials.length > 0}>
        <h4 className="mb-2">
          {erroredCredentials.length} players no encontrados:
        </h4>
        <ul className="mb-4">
          {erroredCredentials.map((credential) => (
            <li key={credential}>{credential}</li>
          ))}
        </ul>
        <Button onClick={() => setErroredCredentials([])}>Cerrar</Button>
      </Modal>
    </>
  )
}
