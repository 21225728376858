import { Client, Playlist } from '@ritmo/types'
import { Button } from '@ritmo/ui'
import { useContext, useState } from 'react'
import useSWR, { mutate } from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'
import SongsManagerContext from './conext'
import save from '../../utils/save'

export function CopyFromPlaylistForm() {

  const [selectedClient, setSelectedClient] = useState('')
  const [sourcePlaylist, setSourcePlaylist] = useState('')
  const { packId } = useContext(SongsManagerContext)
  const [copying, setCopying] = useState(false)

  const { data: clients } = useSWR<Client[]>(baseApiUrls.CLIENT, baseFetcher)

  const { data: playlists } = useSWR<Playlist[]>(
    selectedClient && `${baseApiUrls.PLAYLIST}/${selectedClient}`,
    baseFetcher
  )

  const handleCopy = async () => {
    setCopying(true)
    try {
      await save({
        url: `${baseApiUrls.PLAYLIST}/${packId}/copy-songs`,
        method: 'put',
        payload: {
          source: sourcePlaylist,
        },
        messages: {
          loading: 'Copiando canciones...',
          success: 'Canciones copiadas',
          error: 'Error al copiar las canciones',
        },
      })
      await mutate(`${baseApiUrls.PLAYLIST}/${packId}/details`)
    } catch (err) {
      console.log(err)
    } finally {
      setCopying(false)
    }
  }

  return (
    <form className="text-center mt-10">
      <select
        className="input w-full max-w-md"
        value={selectedClient}
        onChange={e => setSelectedClient(e.target.value)}
      >
        <option value="">o copiar desde...</option>
        {clients?.map(client => (
          <option
            key={client._id}
            value={client._id}
          >
            {client.title}
          </option>
        ))}
      </select>
      {selectedClient ? (
        <select
          className="input w-full max-w-md mt-4"
          value={sourcePlaylist}
          onChange={e => setSourcePlaylist(e.target.value)}
        >
          <option value="">Selecciona una playlist</option>
          {playlists?.map(playlist => (
            <option
              key={playlist._id}
              value={playlist._id}
            >
              {playlist.title}
            </option>
          ))}
        </select>
      ) : null}
      {sourcePlaylist ? (
        <div className="mt-4">
          <Button
            onClick={handleCopy}
            disabled={copying}
          >
            Copiar canciones
          </Button>
        </div>
      ) : null}
    </form>
  )
}
