
interface TabProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean
}

function Tab({ active, ...props }: TabProps) {
  return (
    <div
      className={`
        cursor-pointer opacity-70 text-sm rounded px-3 border
        ${active ? 'border-slate-500' : ''}
      `}
      {...props}
    />
  )
}

interface ShiftsOneShotTabsProps {
  view: 'shifts' | 'one-shot'
  onChange: (view: 'shifts' | 'one-shot') => void
}

export default function ShiftsOneShotTabs({ view, onChange }: ShiftsOneShotTabsProps) {
  return (
    <div
      className="flex items-center gap-3 mb-2"
    >
      <Tab active={view === 'shifts'} onClick={() => onChange('shifts')} >
        Turnos
      </Tab>
      <Tab active={view === 'one-shot'} onClick={() => onChange('one-shot')} >
        Unica vez
      </Tab>
    </div>
  )
}