import { Cover } from '@ritmo/ui'
import { Link } from 'react-router-dom'

interface CardProps {
  image?: string
  link: string
  title: string | JSX.Element
}

const Card = ({ image, link, title, }: CardProps) => (
  <Link
    to={link}
    className="group cursor-pointer transition-all hover:bg-slate-200/50 rounded-2xl p-2 md:p-3 no-highlight"
    data-letter={title.toString().charAt(0).toLowerCase()}
  >
    <Cover image={image} />
    <div className="font-bold mt-2">{title}</div>
  </Link>
)

export default Card
