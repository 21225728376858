import { addDays, differenceInDays, format } from 'date-fns'

export function getLegiblePeriod(start: string) {
  const daysSub = Number(start.slice(0, -1))
  const startDate = addDays(new Date(), daysSub) // daysSub is negative
  const legibleStart = format(startDate, 'dd/MM/yyyy')
  const legibleEnd = format(new Date(), 'dd/MM/yyyy')
  const daysDifference = differenceInDays(new Date(), startDate)
  return `Reporte del ${legibleStart} → ${legibleEnd} (${daysDifference} días)`
}
