import { PagesNav as PagesNavFromUI } from '@ritmo/ui'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

interface PagesNavProps {
  rowCount: number
  pageSize: number
}

export function PagesNav({ pageSize, rowCount }: PagesNavProps) {
  const [params, setSearchParams] = useSearchParams()
  const page = Number(params.get('page') || 1)

  const handleChange = useCallback(
    (newPage: number) => {
      setSearchParams((curr) => {
        curr.set('page', newPage.toString())
        return curr
      })
    },
    [setSearchParams]
  )

  return (
    <PagesNavFromUI
      rowCount={rowCount}
      pageSize={pageSize}
      page={page}
      onPageChange={handleChange}
    />
  )
}
