import {
  Headphones,
  Calendar,
  Megaphone,
  Broadcast,
  GearSix,
} from 'phosphor-react'
import { NavLink } from 'react-router-dom'
import useClient from '../../hooks/useClient'
import { useAuth } from '../../contexts/AuthContext'

const activeBar = (
  <div className="absolute bottom-0 bg-accent h-[4px] rounded-t-full w-6 md:w-10" />
)

const sections = [
  {
    icon: <Broadcast size={26} className="text-accent" />,
    text: 'Players',
    link: 'players',
  },
  {
    icon: <Headphones size={26} className="text-accent" />,
    text: 'Playlists',
    link: 'playlists',
  },
  {
    icon: <Calendar size={26} className="text-accent" />,
    text: 'Programación',
    link: 'schedule',
  },
  {
    icon: <Megaphone size={26} className="text-accent" />,
    text: 'Publicidad',
    link: 'ads',
  },
]

const Sections = () => {
  const { client } = useClient()
  const { user } = useAuth()

  return (
    <div className="flex justify-between items-stretch print:hidden">
      <NavLink to="">
        <div className="flex items-center gap-x-3">
          <img
            src={client?.image}
            className="aspect-square w-14 rounded-md my-2 object-cover"
          />
          <h3 className="first-letter:uppercase text-sm md:text-base">
            {client?.title}
          </h3>
        </div>
      </NavLink>
      <div className="flex gap-4 sm:gap-5 md:gap-6 border-b">
        {sections.map((section, i) => (
          <NavLink
            to={section.link}
            key={i}
            className="flex relative justify-center gap-2 items-center"
          >
            {({ isActive }) => (
              <>
                {section.icon}
                <span className="hidden md:block">{section.text}</span>
                {isActive && activeBar}
              </>
            )}
          </NavLink>
        ))}
      </div>
      {user?.role === 'admin' ? (
        <NavLink to="settings" className="flex items-center justify-center">
          {({ isActive }) => (
            <div
              className={`
                ${isActive ? 'bg-accent/10 text-accent' : ''}
                flex relative justify-center gap-2 items-center p-2 rounded
              `}
            >
              <GearSix size={20} />
            </div>
          )}
        </NavLink>
      ) : null}
    </div>
  )
}

export default Sections
