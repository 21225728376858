import { endOfDay, isAfter, isBefore } from 'date-fns'

type AdStatus = 'pending' | 'active' | 'expired'

export const getAdStatus = (startDay: string, endDay: string): AdStatus => {
  const pending = isAfter(new Date(startDay), new Date())
  if (pending) return 'pending'
  const endDate = endOfDay(new Date(endDay))
  const expired = isBefore(endOfDay(endDate), new Date())
  if (expired) return 'expired'
  return 'active'
}