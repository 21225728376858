import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'
import { Link, useSearchParams } from 'react-router-dom'
import { IconButton } from '@ritmo/ui'
import { ArrowSquareOut, CheckCircle, X } from 'phosphor-react'
import { SortButton } from '../../components/SortButton'
import { OperatingSystemLogo } from '../../components/OperatingSystemLogo'

type HighDownloader = {
  _id: string
  title: string
  downloads: string
  client: string
  clientId: string
  streamOnly: string
  storageInfo: {
    usageInMB: number
    quotaInMB: number
  }
  os: {
    name: string
  }
}

function HighDownloaderRow(player: HighDownloader) {
  const availableSpace =
    player.storageInfo.quotaInMB - player.storageInfo.usageInMB
  const alert = availableSpace < 1024 * 5
  const availableSpaceInGB = (availableSpace / 1024).toFixed(2)

  return (
    <tr>
      <td>{player.title}</td>
      <td>{player.client}</td>
      <td>{player.downloads}</td>
      <td>
        <span className={alert ? 'text-red-500' : ''}>
          {availableSpaceInGB} GB
        </span>
      </td>
      <td>
        <OperatingSystemLogo name={player.os?.name} />
      </td>
      <td>
        {player.streamOnly ? (
          <CheckCircle size={20} className="text-green-500" />
        ) : (
          <X size={20} className="text-red-500" />
        )}
      </td>
      <td>
        <Link
          to={`/clients/${player.clientId}/players/${player._id}?back=${location.pathname}`}
        >
          <IconButton variant="secondary">
            <ArrowSquareOut size={18} />
          </IconButton>
        </Link>
      </td>
    </tr>
  )
}

export function DownloadMetrics() {
  const [searchParams, setSearchParams] = useSearchParams({
    downloadsCount: '10000',
  })

  const { data } = useSWR<HighDownloader[]>(
    `${baseApiUrls.METRICS}/high-downloaders?${searchParams.toString()}`,
    baseFetcher
  )

  return (
    <>
      <h2>Descargas</h2>
      <div className="h-2" />
      <p>
        Players que descargaron más de&nbsp;&nbsp;
        <select
          className="input"
          value={searchParams.get('downloadsCount') as string}
          onChange={(e) =>
            setSearchParams((curr) => {
              curr.set('downloadsCount', e.target.value)
              return curr
            })
          }
        >
          <option value="5000">5k</option>
          <option value="10000">10k</option>
          <option value="15000">15k</option>
          <option value="20000">20k</option>
          <option value="30000">30k</option>
        </select>
        &nbsp;&nbsp;canciones en el último mes
      </p>
      <div className="h-8" />
      <table className="w-full max-w-4xl">
        <thead>
          <tr>
            <th>
              Nombre <SortButton field="title" />
            </th>
            <th>
              Cliente <SortButton field="client" />
            </th>
            <th>Descargas</th>
            <th>Espacio</th>
            <th>
              OS <SortButton field="os.name" />
            </th>
            <th>
              Stream only <SortButton field="streamOnly" />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((player) => (
            <HighDownloaderRow key={player._id} {...player} />
          ))}
        </tbody>
      </table>
    </>
  )
}
