import { Switch } from '@ritmo/ui'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import useSWR from 'swr'
import { User } from '@ritmo/types'
import useClient from '../../hooks/useClient'
import { baseFetcher } from '../../lib/apis'
import { MANAGER_PERMISSIONS } from '../../types'
import { AuthContext } from '../../contexts/AuthContext'
import { useContext } from 'react'
import Copy from '../../components/Copy'
import ManagerPassword from './ManagerPassword'

export default function ManagerPermissions() {
  const { client } = useClient()
  const { user } = useContext(AuthContext)

  const { data: managers, mutate: mutateManager } = useSWR<
    (User & { cloudant_id: string; permissions?: string[] })[]
  >(`${baseApiUrls.CLIENT}/${client?._id}/managers`, baseFetcher)
  const manager = managers?.[0]

  if (!manager || !client) return null

  const managerId = manager?._id
  const managerPermissions = manager?.permissions || []

  const updatePermissions = async (permission: string) => {
    await save({
      url: `${baseApiUrls.CLIENT}/${client._id}/${managerId}/permissions`,
      payload: {
        permissions: managerPermissions?.includes(permission)
          ? managerPermissions?.filter((p) => p !== permission)
          : [...managerPermissions, permission],
      },
      method: 'patch',
      messages: {
        loading: 'Actualizando manager',
        error: 'Error actualizando manager',
        success: 'Manager actualizado',
      },
    })
    mutateManager()
  }

  return (
    <>
      <h4>Manager</h4>
      Usuario:&nbsp;
      <Copy text={manager.username} />
      {manager.username}
      <br />
      Contraseña:&nbsp;
      <ManagerPassword />
      <div className="mt-4 w-full flex justify-between items-center mb-2">
        <Switch
          checked={
            managerPermissions?.includes(
              MANAGER_PERMISSIONS.GET_PLAYLISTS_SONGS
            ) || false
          }
          onChange={() =>
            updatePermissions(MANAGER_PERMISSIONS.GET_PLAYLISTS_SONGS)
          }
          disabled={user?.role !== 'admin'}
          label="Autorizado para ver canciones de playlists"
        />
      </div>
      <div className="mt-4 w-full flex justify-between items-center mb-2">
        <Switch
          checked={
            managerPermissions?.includes(
              MANAGER_PERMISSIONS.VIEW_USAGE_METRICS
            ) || false
          }
          onChange={() =>
            updatePermissions(MANAGER_PERMISSIONS.VIEW_USAGE_METRICS)
          }
          disabled={user?.role !== 'admin'}
          label="Autorizado para ver métricas de uso"
        />
      </div>
    </>
  )
}
