import { Admin, Manager } from '@ritmo/types'
import { format } from 'date-fns'

export const shuffle = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)]

export const formatDay = (str: string) => format(new Date(str), 'd/M/yyyy')

export function scrollToTop() {
  document.getElementById('main')?.scroll(0, 0)
}

export function isManager(user: Admin | Manager): user is Manager {
  return user.role === 'manager'
}