import useSWR from 'swr'
import BackButton from '../../components/BackButton'
import { baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import { useParams, useSearchParams } from 'react-router-dom'
import { loading } from '../../components/loading'
import PlayerUsageChart, {
  PlayerRowRecord,
  getDurationString,
} from '../../components/PlayerUsageChart'
import EmptyMessage from '../../components/EmptyMessage'
import { Button } from '@ritmo/ui'
import { getLegiblePeriod } from '../../lib/getLegiblePeriod'
import { useMemo } from 'react'
import { PrintHeader } from '../../components/PrintHeader'

interface PlayerRow {
  _id: string
  title: string
  duration: number
  records: PlayerRowRecord[]
}

type PlayersUsageResponse = {
  playerRows: PlayerRow[]
  maxValue: number
}

export function PlayerMetrics() {
  const { clientId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams({ start: '-7d' })
  const { data } = useSWR<PlayersUsageResponse>(
    `${baseApiUrls.CLIENT}/${clientId}/usage?${searchParams.toString()}`,
    baseFetcher
  )

  const legiblePeriod = useMemo(() => {
    return getLegiblePeriod(searchParams.get('start') || '-30d')
  }, [searchParams])

  if (!data) return loading
  const { playerRows, maxValue } = data
  const start = searchParams.get('start') || '-7d'
  const daysNumber = Math.abs(Number(start.slice(0, -1)))

  const headerAndSelect = (
    <div className="print:hidden contents">
      <div className="flex justify-between items-center">
        <BackButton title="Métricas de uso" />
        <Button variant="secondary" onClick={window.print}>
          Descargar reporte
        </Button>
      </div>
      <div className="h-8" />
      <select
        className="input"
        value={start}
        onChange={(e) => {
          setSearchParams((curr) => {
            curr.set('start', e.target.value)
            return curr
          })
        }}
      >
        <option value="-7d">Últimos 7 días</option>
        <option value="-30d">Últimos 30 días</option>
        <option value="-60d">Últimos 60 días</option>
      </select>
      <div className="h-8" />
    </div>
  )

  const printElements = (
    <div className="hidden print:contents">
      <PrintHeader />
      <div className="h-8" />
      <h1>Métricas de uso</h1>
      <div className="h-2" />
      {legiblePeriod}
      <div className="h-4" />
    </div>
  )

  if (!playerRows.length) {
    return (
      <>
        {headerAndSelect}
        {printElements}
        <div className="h-4" />
        <EmptyMessage title="No hay métricas para el período seleccionado" />
      </>
    )
  }

  const isSevenDays = daysNumber === 7

  return (
    <>
      {headerAndSelect}
      {printElements}
      <div className="h-4" />
      Puntos de escucha
      <h2>{playerRows.length}</h2>
      <div className="h-4" />
      <table className="w-full">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Promedio diario</th>
            <th>
              Distribución{' '}
              {isSevenDays
                ? '(una columna cada cuatro horas)'
                : '(una columna por día)'}
            </th>
          </tr>
        </thead>
        <tbody>
          {playerRows.map((playerRow) => (
            <tr key={playerRow._id}>
              <td>{playerRow.title}</td>
              <td>{getDurationString(playerRow.duration / daysNumber)}</td>
              <td>
                <PlayerUsageChart
                  playerTitle={playerRow.title}
                  records={playerRow.records || []}
                  hourDetailInTooltip={isSevenDays}
                  maxValue={maxValue}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
