import { SortButton } from '../SortButton'

export const songsTableHeader = (
  <thead>
    <tr>
      <th colSpan={2}>
        Canción&nbsp;
        <SortButton field="title" />
      </th>
      <th className="w-3/12 hidden lg:table-cell">
        Artista&nbsp;
        <SortButton field="artist" />
      </th>
      <th className="w-2/12 hidden lg:table-cell">Album</th>
      <th className="w-2/12 hidden lg:table-cell">Duración</th>
      <th />
    </tr>
  </thead>
)
