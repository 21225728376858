import { format, isSameDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Calendar } from 'phosphor-react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import InfoHeader from './components/InfoHeader'
import { getUTCNow } from './programming/dates'
import { getTodaysSchedule } from './programming/todaysSchedule'
import { ScheduleSet } from '@ritmo/types'
import { baseApiUrls } from '../../../utils/consts'
import { baseFetcher } from '../../../lib/apis'
import { usePlayer } from '../context'

const header = <InfoHeader title="Programación para hoy" Icon={Calendar} />

export default function ScheduleInfo() {
  const { clientId } = useParams()
  const { player } = usePlayer()
  const { data: scheduleSets } = useSWR<ScheduleSet[]>(
    `${baseApiUrls.SCHEDULE}/${clientId}`,
    baseFetcher
  )

  if (!player) return null

  const localTime = utcToZonedTime(
    getUTCNow(),
    player.timeZone || 'America/Buenos_Aires'
  )

  const todaysSchedule = getTodaysSchedule(scheduleSets, localTime)

  if (!player.musicScheduling) {
    return (
      <div>
        {header}
        <small>Programación no habilitada para esta sucursal</small>
      </div>
    )
  }

  const dayDifferenceTip = !isSameDay(localTime, new Date()) ? (
    <small>
      Corresponde a la fecha actual de la sucursal:{' '}
      {localTime.toLocaleDateString()}.
    </small>
  ) : null

  return (
    <div>
      {header}
      {dayDifferenceTip}
      <div className="space-y-3">
        {todaysSchedule.map(({ set, range }, i) => (
          <div key={i} className="relative w-full max-w-md">
            <div className="py-4 overflow-y-auto">
              {format(range.start, 'HH:mm (dd/LL)')}
              <span className="opacity-50 mx-2">→</span>
              {format(range.end, 'HH:mm (dd/LL)')}
              <span className="opacity-20 mx-4">⎯⎯</span>
              {set.title}
            </div>
          </div>
        ))}
        {!todaysSchedule.length ? (
          <small>No hay programación para hoy</small>
        ) : null}
      </div>
    </div>
  )
}
