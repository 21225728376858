import { Button, Field } from '@ritmo/ui'
import { useNavigate, } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import { FormEvent } from 'react'

interface NewClient {
  title: string
  email: string
}

export default function NewClient() {
  const navigate = useNavigate()

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const newClient = Object.fromEntries(formData) as unknown as NewClient

    const result = await save<{ _id: string }, NewClient>({
      url: baseApiUrls.CLIENT,
      method: 'post',
      messages: {
        error: 'Error al crear el cliente',
        success: 'Cliente creado',
        loading: 'Creando cliente...'
      },
      payload: newClient
    })
    if (!result?._id) return
    return navigate(`../${result._id}`)
  }
  return (
    <>
      <div className="h-8" />
      <BackButton title="Nuevo cliente" />
      <form
        className="max-w-xs mx-auto mt-6 space-y-6"
        onSubmit={handleSubmit}
      >
        <Field label="Nombre">
          <input
            placeholder="Cliente"
            minLength={3}
            className="input w-full"
            required
            name="title"
          />
        </Field>
        <Field label="Email">
          <input
            placeholder="info@client.com"
            className="input w-full"
            required
            type="email"
            name="email"
          />
        </Field>
        <Button
          className="w-full"
          type="submit"
        >
          Crear cliente
        </Button>
      </form>
    </>
  )
}
