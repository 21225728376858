import { Business, Player, WithId } from '@ritmo/types'
import { Field } from '@ritmo/ui'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import useClient from '../../hooks/useClient'
import { useState } from 'react'
import slugify from 'slugify'

interface PlayerFormFieldsProps {
  player?: Player
}

export function PlayerFormFields({ player }: PlayerFormFieldsProps) {
  const { client } = useClient()
  const [title, setTitle] = useState(player?.title || '')
  const [credential, setCredential] = useState(player?.credential || '')

  const { data: business } = useSWR<WithId<Business>[]>(
    `${baseApiUrls.BUSINESSES}/by-client/${client?._id}`,
    baseFetcher
  )

  return (
    <>
      <Field label="Título">
        <input
          name="title"
          className="input w-full"
          value={title}
          onChange={(e) => {
            if (!player?._id) {
              setCredential(
                slugify(
                  `${client?.title} ${e.target.value}`
                ).toLocaleLowerCase()
              )
            }
            setTitle(e.target.value)
          }}
          required
        />
      </Field>
      <Field label="Credencial">
        <input
          name="credential"
          className="input w-full"
          value={credential}
          onChange={(e) => setCredential(e.target.value)}
          required
        />
      </Field>
      <Field label="Dirección">
        <input
          name="address"
          className="input w-full"
          defaultValue={player?.address}
          required
        />
      </Field>
      <Field label="Ciudad">
        <input
          name="city"
          className="input w-full"
          defaultValue={player?.city}
          required
        />
      </Field>
      <Field label="Provincia/Estado">
        <input
          name="state"
          className="input w-full"
          defaultValue={player?.state}
          required
        />
      </Field>
      <Field label="Razón Fiscal">
        {!business ? (
          <select key="placeholder" className="input w-full" />
        ) : (
          <select
            name="businessId"
            className="input w-full"
            defaultValue={player?.businessId}
          >
            <option value="">Seleccionar razón social</option>
            {business?.map((b) => (
              <option key={b._id} value={b._id}>
                {b.name}
              </option>
            ))}
          </select>
        )}
      </Field>
      <Field label="Contacto">
        <input
          name="contactName"
          className="input w-full"
          defaultValue={player?.contactName}
        />
      </Field>
      <Field label="Email">
        <input
          name="contactEmail"
          className="input w-full"
          defaultValue={player?.contactEmail}
        />
      </Field>
      <Field label="Teléfono">
        <input
          name="contactPhone"
          className="input w-full"
          defaultValue={player?.contactPhone}
        />
      </Field>
    </>
  )
}
