import Ads from './Ads'
import BasicInfo from './BasicInfo'
import Connection from './Connection'
import Device from './Device'
import MusicDownload from './MusicDownload'
import Playback from './Playback'
import ScheduleInfo from './ScheduleInfo'
import Storage from './Storage'
import SentryLink from './SentryLink'
import LastDownloads from './LastDownloads'

export default function BranchScreen() {
  return (
    <>
      <div className="space-y-20">
        <BasicInfo />
        <Connection />
        <Playback />
        <LastDownloads />
        <Storage />
        <MusicDownload />
        <Ads />
        <ScheduleInfo />
        <Device />
      </div>
      <div className="h-14" />
      <SentryLink />
      <div className="h-14" />
    </>
  )
}