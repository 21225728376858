import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'
import { useSearchParams } from 'react-router-dom'
import { IconButton, Tooltip } from '@ritmo/ui'
import { PagesNav } from '../../components/PagesNav'
import { SortButton } from '../../components/SortButton'
import { loading } from '../../components/loading'
import { ArrowSquareOut } from 'phosphor-react'
import { useMemo } from 'react'
import SearchInput from '../../components/SearchInput'

interface Dislike {
  _id: string
  trackId: string
  trackTitle: string
  trackArtist?: string
  clientId: string
  clientName: string
  dislikes: number
  deleted: boolean
  players: { title: string; _id: string }[]
}

interface Response {
  count: number
  dislikes: Dislike[]
}

function DislikeRow(dislike: Dislike) {
  const playersTitles = useMemo(() => {
    return dislike.players.map((player) => player.title).join(', ')
  }, [dislike.players])

  return (
    <tr className={dislike.deleted ? 'line-through opacity-50' : ''}>
      <td>
        <span className="line-clamp-1">{dislike.trackTitle}</span>
      </td>
      <td>
        <span className="line-clamp-1">{dislike.trackArtist}</span>
      </td>
      <td>{dislike.dislikes}</td>
      <td>{dislike.clientName}</td>
      <td>
        <Tooltip text={playersTitles}>
          <span className="line-clamp-1">
            {dislike.players[0].title}
            {dislike.players.length > 1 && (
              <span className="text-accent">
                {' '}
                +{dislike.players.length - 1}
              </span>
            )}
          </span>
        </Tooltip>
      </td>
      <td>
        <a
          href={`/clients/${dislike.clientId}/playlists`}
          target="_blank"
          rel="noreferrer"
        >
          <IconButton variant="secondary">
            <ArrowSquareOut size={18} />
          </IconButton>
        </a>
      </td>
    </tr>
  )
}

export function DislikeMetrics() {
  const [searchParams] = useSearchParams()

  const { data } = useSWR<Response>(
    `${baseApiUrls.METRICS}/dislikes?${searchParams.toString()}`,
    baseFetcher
  )

  if (!data) return loading

  return (
    <>
      <h2>Dislikes</h2>
      <div className="h-2" />
      <SearchInput
        className="w-80"
        placeholder="Buscar por nombre, artista o cliente"
      />
      <div className="h-8" />
      <h3>{data.count} resultados</h3>
      <table className="w-full max-w-4xl">
        <thead>
          <tr>
            <th className="w-3/12">
              Titulo <SortButton field="_id" />
            </th>
            <th className="w-3/12">
              Artista <SortButton field="artist" />
            </th>
            <th>
              Dislikes <SortButton field="dislikes" />
            </th>
            <th>
              Cliente <SortButton field="clientName" />
            </th>
            <th>
              Players <SortButton field="players" />
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data.dislikes.map((dislike) => (
            <DislikeRow key={dislike._id} {...dislike} />
          ))}
        </tbody>
      </table>
      <div className="text-center">
        <PagesNav rowCount={data.count} pageSize={40} />
      </div>
    </>
  )
}
