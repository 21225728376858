import { getCoordsFromCell } from '../getCoordsFromElement'
import { getTable } from '../getTable'
import { selectRange } from '../selectRange'

export function handleFocus(e: FocusEvent) {
  const el = e.target as HTMLElement
  const contentEditableCell = getTable().querySelector(
    '[contentEditable="true"]'
  )
  contentEditableCell?.removeAttribute('contentEditable')

  const cellCoords = getCoordsFromCell(el)
  selectRange(cellCoords, cellCoords)

  if (
    typeof window.getSelection != 'undefined' &&
    typeof document.createRange != 'undefined'
  ) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    if (!sel) return
    sel.removeAllRanges()
    sel.addRange(range)
  }
}
