import { useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import { baseFetcher } from '../../lib/apis'
import useSWR, { mutate } from 'swr'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import TagForm from '../../components/TagForm'
import { loading } from '../../components/loading'
import { Tag } from '@ritmo/types'

export const UpdateTag = () => {
  const navigate = useNavigate()

  const { tagId } = useParams<{
    clientId: string
    tagId: string
  }>()

  const { data: tag } = useSWR<Tag>(`${baseApiUrls.TAGS}/${tagId}`, baseFetcher)

  const messages = {
    success: 'Tag actualizado',
    error: 'Error al actualizar el tag',
    loading: 'Actualizando tag',
  }

  const onSubmit = async (formData: { color: string }) => {
    try {
      if (!tag) return
      const r = await save<string, { color: string }>({
        url: `${baseApiUrls.TAGS}/${tag?._id}`,
        method: 'PUT',
        payload: formData,
        messages,
      })
      await mutate(`${baseApiUrls.TAGS}/${tagId}`)
      if (r) {
        navigate('..')
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (!tag) {
    return loading
  }

  return (
    <>
      <div className="flex items-center">
        <BackButton />
        <h4>Actualizar tag</h4>
      </div>
      <div className="h-6" />
      <TagForm tag={tag} onSubmit={onSubmit} />
    </>
  )
}
