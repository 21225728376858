import { useRecoilState, useRecoilValue } from 'recoil'
import { currentSongState, playbackState } from '../../atoms/playback'
import { ControlButtons, PlayBar, ProgressBar, ProgressInfo } from '@ritmo/ui'
import { HowlerContext } from '../../contexts/HowlerContext'
import { useContext } from 'react'

export function DashboardPlayBar() {
  const song = useRecoilValue(currentSongState)

  const { soundRef, requestSong } = useContext(HowlerContext)
  const [playback, setPlayback] = useRecoilState(playbackState)

  const { playing, timer, step } = playback

  const playOrPause = () => {
    if (!song || !soundRef?.current) return
    if (soundRef.current.playing()) {
      soundRef.current.pause()
    } else {
      soundRef.current.play()
    }
  }

  const nextSong = () => {
    if (soundRef?.current?.state() !== 'unloaded') {
      requestSong()
    }
  }

  const handleSeek = (percentage: number) => {
    console.log(percentage)
    const duration = soundRef?.current?.duration()
    const newSeek = duration ? duration * (percentage / 100) : 0
    soundRef?.current?.seek(newSeek)
    setPlayback((pl) => ({ ...pl, step: percentage }))
    if (playing) {
      soundRef?.current?.play()
    }
  }

  return (
    <div className="contents print:hidden">
      <PlayBar
        track={song}
        controlButtons={
          <ControlButtons
            onNextSong={nextSong}
            onPlayOrPause={playOrPause}
            playing={playing}
          />
        }
        progressInfo={
          <ProgressInfo
            step={step}
            timer={timer}
            className="my-2 hidden md:block"
            onSeek={handleSeek}
          />
        }
        toast={
          <ProgressBar
            step={step}
            className="block absolute -top-[4px] md:hidden"
            onSeek={handleSeek}
          />
        }
      />
    </div>
  )
}
