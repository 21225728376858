import { useParams } from 'react-router-dom'
import { Client } from '@ritmo/types'
import { baseApiUrls } from '../utils/consts'
import useSWR from 'swr'
import { baseFetcher } from '../lib/apis'

export default function useClient() {
  const { clientId } = useParams()
  const { data, mutate } = useSWR<Client>(
    `${baseApiUrls.CLIENT}/${clientId}`,
    baseFetcher
  )
  return { client: data, mutate }
}
