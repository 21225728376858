import { useSearchParams } from 'react-router-dom'
import SearchInput from '../../components/SearchInput'

const Tools = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="flex lg:w-2/4 gap-x-3">
      <SearchInput minChars={1} debounceTime={200} />
      <select
        className="input w-full flex-1 hidden md:block"
        value={searchParams.get('status') || 'enabled'}
        onChange={(e) => {
          setSearchParams((curr) => {
            curr.set('status', e.target.value)
            return curr
          })
        }}
      >
        <option value="all">Todos</option>
        <option value="enabled">Habilitados</option>
        <option value="disabled">Deshabilitados</option>
      </select>
      <select
        className="input w-full flex-1 hidden md:block"
        value={searchParams.get('direction') || 'asc'}
        onChange={(e) => {
          setSearchParams((curr) => {
            curr.set('sort', 'title')
            curr.set('direction', e.target.value)
            return curr
          })
        }}
      >
        <option value="asc">A - Z</option>
        <option value="desc">Z - A</option>
      </select>
    </div>
  )
}

export default Tools
