import { usePlayer } from '../context'

export default function SentryLink() {
  const { player } = usePlayer()

  const id = encodeURIComponent(player?._id || '')

  const url = `https://sentry.io/organizations/ritmo-iw/issues/?environment=production&project=6540081&query=is%3Aunresolved%20user%3A%22id%3A${id}%22&statsPeriod=14d`

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="underline"
    >
      Sentry →
    </a>
  )
}