import axios from 'axios'

export const baseApi = axios.create({
  baseURL: import.meta.env.VITE_API_URL as string,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    'Refresh-Token': localStorage.getItem('refresh_token') || ''
  },
})

baseApi.interceptors.response.use(r => {
  if (r.headers['ritmo-access-token']) {
    localStorage.setItem('access_token', r.headers['ritmo-access-token'])
    localStorage.setItem('refresh_token', r.headers['ritmo-refresh-token'])
  }
  return r
})

export const baseFetcher = <T>(url: string) => baseApi.get<T>(url).then(res => res.data)
