import { Tag } from '@ritmo/types'
import { Link, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import { Button, IconButton, Modal, useArrayState } from '@ritmo/ui'
import { X } from 'phosphor-react'
import save from '../../utils/save'
import { useCallback } from 'react'
import CheckItem from '../../components/CheckItem'
import { usePlayers } from '../../contexts/players'

interface TagsModalProps {
  onClose: () => void
  players: string[]
}

export function TagsModal({ onClose, players }: TagsModalProps) {
  const { clientId } = useParams()
  const { data: tags = [] } = useSWR<Tag[]>(
    `${baseApiUrls.TAGS}/by-client/${clientId}`,
    baseFetcher,
    { fallbackData: [] }
  )

  const { playersMutate, resetAllItems } = usePlayers()

  const [selectedTags, , toggleTag] = useArrayState<string>([])

  const handleSave = useCallback(async () => {
    await save({
      payload: players.map((_id) => ({ _id, tags: selectedTags })),
      url: `${baseApiUrls.BRANCHES}/${clientId}/set-tags`,
      method: 'patch',
      messages: {
        loading: 'Guardando tags',
        error: 'Error al guardar tags',
        success: 'Tags guardados',
      },
    })
    playersMutate()
    resetAllItems()
    onClose()
  }, [selectedTags, players, clientId, onClose])

  return (
    <Modal className="w-full max-w-lg" open>
      <div className="flex items-center justify-between">
        <h3>Reemplazar tags</h3>
        <IconButton onClick={onClose}>
          <X size={24} />
        </IconButton>
      </div>
      <div className="h-6" />
      {!tags.length && (
        <p className="text-gray-500 text-center">
          No hay tags disponibles
          <br />
          <br />
          <Link to="tags">
            <Button variant="secondary">Crear tag</Button>
          </Link>
        </p>
      )}
      {tags.map((tag) => (
        <CheckItem
          label={
            <div className="flex items-center">
              {tag.title}
              <span
                className="w-3 aspect-square rounded-full inline-block ml-2"
                style={{ backgroundColor: tag.color }}
              />
            </div>
          }
          selected={selectedTags.includes(tag._id)}
          key={tag._id}
          onClick={() => toggleTag(tag._id)}
        />
      ))}
      <div className="h-6" />
      <Button disabled={!tags.length} onClick={handleSave}>
        {players.length === 1
          ? 'Reemplazar tags en el player seleccionado'
          : `Reemplazar tags en los ${players.length} players`}
      </Button>
    </Modal>
  )
}
