import { ArrowLeft } from 'phosphor-react'
import { Link } from 'react-router-dom'

export default function BackButtonLarge({ title, to = '../' }: { title?: string, to?: string }) {
  return (
    <div>
      <Link
        to={to}
        className="inline-block pr-2 pt-2 pb-2"
      >
        <ArrowLeft size={30} />
      </Link>
      <h3>
        {title}
      </h3>
    </div>
  )
}