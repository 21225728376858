import { Clipboard } from 'phosphor-react'
import toast from 'react-hot-toast'
import { IconButton } from '@ritmo/ui'

interface CopyProps {
  text: string
  className?: string
  toastText?: string
}

export default function Copy({ text, className, toastText = 'Copiado!' }: CopyProps) {

  const handleClick = () => {
    navigator.clipboard.writeText(text)
    toast(toastText)
  }

  return (
    <IconButton
      size="small"
      onClick={handleClick}
      className={className}
    >
      <Clipboard />
    </IconButton>
  )
}
