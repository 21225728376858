import { AndroidLogo, AppleLogo, LinuxLogo, WindowsLogo } from 'phosphor-react'

export function OperatingSystemLogo({ name }: { name?: string }) {
  if (!name) return null
  if (name.toLowerCase().startsWith('windows')) {
    return <WindowsLogo size={18} />
  }
  if (name.toLowerCase().startsWith('mac')) {
    return <AppleLogo size={18} />
  }
  if (name.toLowerCase().startsWith('android')) {
    return <AndroidLogo size={18} />
  }
  if (name.toLowerCase().startsWith('linux')) {
    return <LinuxLogo size={18} />
  }
  return <>{name}</>
}
