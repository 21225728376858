import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import { useMemo, useState } from 'react'

interface Country {
  id: number
  name: string
  iso2: string
}

function findName(countries: Country[], iso2: string) {
  return countries.find((country) => country.iso2 === iso2)?.name || ''
}

interface CountrySelectorProps {
  initialCode?: string
}

export const CountrySelector = ({ initialCode }: CountrySelectorProps) => {
  const [countryCode, setCountryCode] = useState(initialCode)
  const { data: countries, isValidating } = useSWR<Country[]>(
    `${baseApiUrls.LOCATION}/countries`,
    baseFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const countryName = useMemo(
    () => findName(countries || [], countryCode || ''),
    [countries, countryCode]
  )

  return (
    <>
      <select
        name="country"
        className="input w-full"
        value={countryCode || initialCode}
        onChange={(e) => {
          setCountryCode(e.currentTarget.value)
        }}
        disabled={!countries || isValidating}
        required
      >
        <option value="">
          {isValidating ? 'Cargando...' : 'Selecciona un país'}
        </option>
        {countries?.map((country) => (
          <option key={country.id} value={country.iso2}>
            {country.name}
          </option>
        ))}
      </select>
      <input type="hidden" name="countryName" defaultValue={countryName} />
    </>
  )
}
