export const getDuration = (time: number) => {
  const hr = ~~(time / 3600)
  const min = ~~((time % 3600) / 60)
  const sec = Math.round(time % 60)
  let sec_min = ''
  if (hr > 0) {
    sec_min += '' + hr + ':' + (min < 10 ? '0' : '')
  }
  sec_min += '' + min + ':' + (sec < 10 ? '0' : '')
  sec_min += '' + sec
  return sec_min
}