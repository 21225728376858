import {
  isAfter,
  addWeeks,
  subWeeks,
  setDay,
} from 'date-fns'
import { Schedule } from '@ritmo/types'

const getMockedDate = (dayOfWeek: string, hourAndMinutes: string, pivotDate = new Date()) => {
  const d = setDay(pivotDate, Number(dayOfWeek))
  const [hour, minutes] = hourAndMinutes.split(':')
  d.setHours(Number(hour), Number(minutes), 0, 0)
  return d
}

export interface Event {
  start: Date
  end: Date
  isCopy?: boolean
}

const getRangesFromSch = (schedule: Schedule[], pivotDate: Date = new Date) => schedule.reduce((acc: Event[], config) => {
  const { startDay, startHour, endDay, endHour } = config
  if (!startHour || !endHour) return acc
  const start = getMockedDate(startDay, startHour, pivotDate)
  const end = getMockedDate(endDay, endHour, pivotDate)
  if (isAfter(start, end)) {
    // is cross week, create two ranges
    acc.push({ start: subWeeks(start, 1), end })
    acc.push({ start, end: addWeeks(end, 1), isCopy: true })
  } else {
    acc.push({ start, end })
  }
  return acc
}, [])

export default getRangesFromSch