import { atomFamily } from 'recoil'

export interface UploadInfo {
  status: 'pending' | 'uploading' | 'complete' | 'already in playlist' | 'already uploaded' | 'error' | 'compressing' | 'converting to mp3' | 'convertion completed' | 'error converting file'
  image?: string
  progress: number
}

export interface UploadState {
  [fileName: string]: UploadInfo
}

export const uploadStateFamily = atomFamily<UploadState, string>({
  key: 'uploadState',
  default: {}
})
