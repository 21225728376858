import { useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import BranchesSelector from '../../components/BranchesSelector/BranchesSelector'
import { AdsPack, Shift } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import { Button, DaysIndicator, Field, useArrayState } from '@ritmo/ui'
import CheckItem from '../../components/CheckItem'
import { baseFetcher } from '../../lib/apis'
import ShiftsOneShotTabs from './ShiftsOneShotTabs'
import toast from 'react-hot-toast'
import TagsSelector from '../../components/TagsSelector/TagsSelector'
import Tabs from '../../components/Tabs'

export default function AdInfo({ ad, onChange }: { ad: AdsPack, onChange?: () => void}) {

  const { clientId, adsPackId } = useParams()
  const { data: shifts } = useSWR<Shift[]>(
    `${baseApiUrls.SHIFTS}/${clientId}`,
    baseFetcher
  )

  const [newName, setNewName] = useState(ad.title)
  const [adDays, , toggleDays] = useArrayState(ad.days)
  const [adShifts, setShifts, toggleDaysShifts] = useArrayState(ad.shifts)
  const [adBranches, setBranches, toggleBranches] = useArrayState(ad.branches)
  const [adTags, setTags, toggleTags] = useArrayState(ad.tags) 
  const [newStart, setNewStart] = useState(ad.startDay)
  const [newEnd, setNewEnd] = useState(ad.endDay)
  const [addView, setAddView] = useState<string>(ad.tags.length > 0 ? 'tags' : 'branches')
  const [timeView, setTimeView] = useState<'shifts' | 'one-shot'>(ad.oneShotTime ? 'one-shot' : 'shifts')
  const [oneShotTime, setOneShotTime] = useState(ad.oneShotTime || '00:00')

  const saveChanges = async () => {
    if (!newStart || !newEnd) return toast.error('Debes agregar fecha de inicio y final')
    if (!adBranches.length) return toast.error('Debes seleccionar al menos una sucursal')
    if (!adDays.length) return toast.error('Debes seleccionar al menos un día')
    if (!adShifts.length && timeView === 'shifts') return toast.error('Debes seleccionar al menos un turno')
    if (!newName.trim()) return toast.error('Debes agregar un nombre')

    const newShifts = timeView === 'one-shot' ? [] : adShifts
    setShifts(newShifts)
    const newOneShotTime = timeView === 'one-shot' ? oneShotTime : undefined
    setOneShotTime(newOneShotTime || '00:00')
    await save({
      url: `${baseApiUrls.AD}/${adsPackId}`,
      method: 'patch',
      payload: {
        title: newName.trim(),
        shifts: newShifts,
        days: adDays,
        branches: adBranches,
        tags: adTags,
        startDay: newStart,
        endDay: newEnd,
        oneShotTime: newOneShotTime,
      },
      messages: {
        loading: 'Actualizando publicidad',
        error: 'Error al actualizar',
        success: 'Publicidad actualizada'
      }
    })
    onChange && onChange()
  }

  if (!clientId) return null

  return (
    <>
      <div className="mt-6 space-y-10">
        <Field label="Nombre">
          <input
            className="input w-full"
            defaultValue={ad.title}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Field>
        <Tabs
          tabs={[
            {
              name: 'branches',
              label: 'Sucursales',
            },
            {
              name: 'tags',
              label: 'Tags',
            },
          ]}
          view={addView}
          onChange={setAddView}
        />
        {addView === 'branches' ? (
          <BranchesSelector
            clientId={clientId}
            selectedBranches={adBranches}
            toggleBranches={toggleBranches}
            setBranches={setBranches}
          />
        ) : (
          <TagsSelector
            clientId={clientId}
            selectedTags={adTags}
            toggleTags={toggleTags}
            setTags={setTags}
          />
        )}
        {shifts ? (
          <div>
            <ShiftsOneShotTabs
              view={timeView}
              onChange={setTimeView}
            />
            {timeView === 'shifts' ? (
              <div className="flex flex-wrap items-center gap-2">
                {shifts.map(shift => (
                  <CheckItem
                    key={shift._id}
                    selected={Boolean(adShifts.includes(shift._id))}
                    onClick={() => toggleDaysShifts(shift._id)}
                    label={(
                      <>
                        {shift.title}&nbsp;
                        <span className="text-sm opacity-60">
                          ({shift.start} - {shift.end})
                        </span>
                      </>
                    )}
                  />
                ))}
              </div>
            ) : (
              <input
                className="input w-full"
                type="time"
                defaultValue={oneShotTime}
                onChange={e => setOneShotTime(e.target.value)}
              />
            )}

          </div>
        ) : null}
        <Field label="Días">
          <DaysIndicator
            days={adDays}
            onDayClicked={toggleDays}
          />
        </Field>
        <div className="space-y-5">
          <Field label="Inicio">
            <input
              className="input w-full"
              defaultValue={ad.startDay}
              onChange={e => setNewStart(e.target.value)}
              type="date"
              max={newEnd}
            />
          </Field>
          <Field label="Final">
            <input
              className="input w-full"
              defaultValue={ad.endDay}
              onChange={e => setNewEnd(e.target.value)}
              min={newStart}
              type="date"
            />
          </Field>
        </div>
      </div>
      <Button
        className="w-full mt-12"
        onClick={saveChanges}
      >
        Guardar cambios
      </Button>
    </>
  )
}