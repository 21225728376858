import { BranchesSchedule, Schedule } from '@ritmo/types'
import { Button, Dropdown, MenuItem } from '@ritmo/ui'
import { CaretDown, Plus } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { ScheduleTable } from '../../../components/ScheduleTable'
import { baseFetcher } from '../../../lib/apis'
import { baseApiUrls } from '../../../utils/consts'
import save from '../../../utils/save'
import { presets } from './presets'
import SchedulePreview from './SchedulePreview'

const emptySchedule = {
  startDay: '',
  startHour: '',
  endDay: '',
  endHour: '',
}

export function CustomSchDetail() {

  const { customSchId } = useParams()
  const { data: customSchedule } = useSWR<BranchesSchedule>(
    `${baseApiUrls.B_SCHEDULE}/${customSchId}`,
    baseFetcher,
  )

  const [saving, setSaving] = useState(false)
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const [placeholder, setPlaceholder] = useState<Schedule[] | null>(null)

  const addSchedule = () => {
    setSchedules(curr => [...curr, emptySchedule])
  }

  useEffect(() => {
    if (customSchedule) {
      setSchedules(customSchedule.schedule)
    }
  }, [customSchedule])

  if (!customSchedule) return null

  const updateSchedule = async () => {
    setSaving(true)
    await save({
      url: `${baseApiUrls.B_SCHEDULE}/${customSchId}`,
      method: 'PATCH',
      payload: { schedule: schedules },
      messages: {
        loading: 'Actualizando horarios',
        success: 'Horarios actualizados',
        error: 'Error actualizando horarios',
      }
    })
    setSaving(false)
  }

  const dropdDown = (
    <Dropdown
      renderTrigger={
        <Button className="flex items-center gap-x-2">
          Preset
          <CaretDown />
        </Button>
      }
    >
      {presets.map(preset => (
        <MenuItem
          key={preset.id}
          onMouseEnter={() => setPlaceholder(preset.value)}
          onMouseLeave={() => setPlaceholder(null)}
          onClick={() => {
            setPlaceholder(null)
            setSchedules(preset.value)
          }}
        >
          {preset.label}
        </MenuItem>
      ))}
    </Dropdown>
  )

  return (
    <div className="flex gap-x-20 relative" >
      <div className="w-7/12">
        <div className="text-right mt-6 flex justify-end gap-x-4 items-center">
          <h3 className="mr-auto">Horarios</h3>
          {dropdDown}
          <Button
            variant="custom"
            className="border"
            onClick={addSchedule}
          >
            <Plus />&nbsp; Agregar horario
          </Button>
        </div>
        <div className="h-8" />
        <div className={`min-h-[350px] ${placeholder ? 'opacity-50' : ''}`}>
          <ScheduleTable
            schedules={placeholder || schedules}
            onChange={setSchedules}
          />
        </div>
        <div className="text-right mt-10">
          <Button
            onClick={updateSchedule}
            disabled={saving}
          >
            Guardar
          </Button>
        </div>
      </div>
      <div className="mt-10">
        <div className={`sticky top-10 ${placeholder ? 'opacity-50' : ''}`}>
          <SchedulePreview
            schedules={placeholder || schedules}
          />
        </div>
      </div>
    </div>
  )
}