import { addRow } from './addRow'
import { getTable } from './getTable'
import { CellCoords } from './types'

type SelectedRange = [CellCoords, CellCoords]

export let selectedRange: SelectedRange | null = null

export function selectRange(start: CellCoords, end: CellCoords) {
  const table = getTable()

  const tbody = table.querySelector('tbody')
  if (!tbody) return

  selectedRange = [start, end]

  const xStart = Math.min(start.x, end.x)
  const xEnd = Math.max(start.x, end.x)
  const yStart = Math.min(start.y, end.y)
  const yEnd = Math.max(start.y, end.y)

  const firstEl = tbody.querySelector(
    `tr[data-index="${yStart}"] td[data-index="${xStart}"]`
  )
  const lastEl = tbody.querySelector(
    `tr[data-index="${yEnd}"] td[data-index="${xEnd}`
  )
  if (!firstEl || !lastEl) return
  const { left: firstLeft, top: firstTop } = firstEl.getBoundingClientRect()
  const { right: lastRight, bottom: lastBottom } =
    lastEl.getBoundingClientRect()

  const rangeIndicator = document.getElementById('tableRangeIndicator')
  if (!rangeIndicator) return

  const { left: tabletLeft, top: tabletTop } = table.getBoundingClientRect()

  const normalizedLeft = firstLeft - tabletLeft
  const normalizedTop = firstTop - tabletTop
  const normalizedRight = lastRight - tabletLeft
  const normalizedBottom = lastBottom - tabletTop

  rangeIndicator.style.left = `${normalizedLeft}px`
  rangeIndicator.style.top = `${normalizedTop}px`
  rangeIndicator.style.width = `${normalizedRight - normalizedLeft}px`
  rangeIndicator.style.height = `${normalizedBottom - normalizedTop}px`

  rangeIndicator.classList.remove('hidden')
}

export function selectOneUp() {
  if (!selectedRange) return
  const [start] = selectedRange
  const firstEl = getTable().querySelector(
    `tr[data-index="${start.y - 1}"] td[data-index="${start.x}"]`
  ) as HTMLTableCellElement
  firstEl?.focus()
}

export function selectOneDown() {
  if (!selectedRange) return
  const [start] = selectedRange
  const firstEl = getTable().querySelector(
    `tr[data-index="${start.y + 1}"] td[data-index="${start.x}"]`
  ) as HTMLTableCellElement
  if (firstEl) {
    firstEl.focus()
  } else {
    addRow()
    selectOneDown()
  }
}

export function selectOneLeft() {
  if (!selectedRange) return
  const [start] = selectedRange
  const firstEl = getTable().querySelector(
    `tr[data-index="${start.y}"] td[data-index="${start.x - 1}"]`
  ) as HTMLTableCellElement
  firstEl?.focus()
}

export function selectOneRight() {
  if (!selectedRange) return
  const [start] = selectedRange
  const firstEl = getTable().querySelector(
    `tr[data-index="${start.y}"] td[data-index="${start.x + 1}"]`
  ) as HTMLTableCellElement
  firstEl?.focus()
}
