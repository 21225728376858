import { Tag } from '@ritmo/types'
import { SelectionAll, SelectionSlash } from 'phosphor-react'
import CheckItem from '../CheckItem'
import { Field } from '@ritmo/ui'
import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'

const TagsSelector = ({
  clientId,
  selectedTags,
  toggleTags,
  setTags,
  disabled = false,
}: {
  clientId: string
  selectedTags: string[]
  toggleTags: (id: string) => void
  setTags: (newState: string[]) => void
  disabled?: boolean
}) => {
  const { data: tags = [] } = useSWR<Tag[]>(
    `${baseApiUrls.TAGS}/by-client/${clientId}`,
    baseFetcher,
    { fallbackData: [] }
  )

  const BulkSelectionIcon = selectedTags.length ? SelectionSlash : SelectionAll

  const toggleSelection = (
    <button
      className="text-sm text-accent-dark flex gap-x-1 items-center absolute right-0 top-0 z-10"
      onClick={() => {
        setTags(selectedTags.length ? [] : tags.map((b) => b._id))
      }}
      disabled={disabled}
    >
      <BulkSelectionIcon />
      {selectedTags.length ? 'Deseleccionar todo' : 'Seleccionar todo'}
    </button>
  )

  return (
    <div className="relative">
      {toggleSelection}
      <Field label="Tags">
        <div className="overflow-y-auto max-h-60">
          {tags.map((tag) => (
            <CheckItem
              key={tag._id}
              label={tag.title}
              selected={Boolean(selectedTags.includes(tag._id))}
              onClick={() => toggleTags(tag._id)}
              disabled={disabled}
            />
          ))}
        </div>
      </Field>
    </div>
  )
}

export default TagsSelector
