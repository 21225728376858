import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import useSWR, { mutate as globalMutate } from 'swr'
import { useConfirmModal } from '@ritmo/ui'
import { PagesNav } from '../../components/PagesNav'
import { Song } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import DeleteTracksButton from '../../views/Playlists/DeleteTracksButton'
import DragAndDrop from '../DragAndDrop'
import SearchInput from '../SearchInput'
import SongsManagerContext, { SongsManagerContextProps } from './conext'
import EditSongModal from './EditSongModal'
import SongsTable from './SongsTable'
import useAddSongs from './useAddSongs'
import usePlayback from './usePlayback'
import { baseFetcher } from '../../lib/apis'
import { CopyFromPlaylistForm } from './CopyFromPlaylistForm'
import { DownloadTracksCsvButton } from './DownloadTracksCsvButton'

interface SongsManagerProviderProps {
  allTracks: string[]
  title: string
  readOnly?: boolean
}

export default function SongsManager({
  allTracks,
  title,
  readOnly = false,
}: SongsManagerProviderProps) {
  const [selectedTracks, setSelectedTracks] = useState<string[]>([])
  const { playlistId, adsPackId } = useParams()
  const baseUrl = adsPackId ? baseApiUrls.AD : baseApiUrls.PLAYLIST
  const packId = (playlistId || adsPackId) as string
  const [selectedSong, setSelectedSong] = useState<Song | null>(null)
  const [editModal, setEditModal] = useState(false)
  const [params] = useSearchParams()

  const { data: songs, mutate } = useSWR<Song[]>(
    `${baseUrl}/${packId}/songs?${params.toString()}`,
    baseFetcher,
    { fallbackData: [] }
  )

  const deleteSong = async () => {
    await save({
      url: `${baseUrl}/delete-song/${packId}/${selectedSong?._id}`,
      method: 'delete',
      messages: {
        loading: 'Eliminando cancion...',
        success: 'Cancion eliminada',
        error: 'Error al eliminar la cancion',
      },
    })
    mutate()
    globalMutate(`${baseUrl}/${packId}/details`)
    setSelectedSong(null)
  }

  const { open: openDeleteModal, modal: deleteModal } = useConfirmModal({
    title: 'Eliminar track?',
    description: `${selectedSong?.title} - ${selectedSong?.artist}`,
    onConfirm: deleteSong,
  })

  const contextValue: SongsManagerContextProps = {
    openDeleteModal,
    ...usePlayback(),
    songs: songs || [],
    selectedTracks,
    setSelectedTracks,
    allTracks,
    mutate,
    setSelectedSong,
    setEditModal,
    addSongs: useAddSongs(packId),
    packId,
    readOnly,
  }

  const header = (
    <div className="flex items-center gap-3 flex-wrap">
      <h5 className="mr-auto shrink-0">
        {title} ({allTracks.length})
      </h5>
      <SearchInput />
      {!readOnly ? <DeleteTracksButton /> : null}
      <DownloadTracksCsvButton />
    </div>
  )

  const empty = readOnly ? null : !!playlistId && <CopyFromPlaylistForm />

  return (
    <SongsManagerContext.Provider value={contextValue}>
      {!readOnly ? (
        <>
          <DragAndDrop />
          <div className="h-4" />
        </>
      ) : null}
      {allTracks.length === 0 ? (
        empty
      ) : (
        <>
          {header}
          <div className="h-3" />
          <SongsTable />
          <div className="text-center">
            <PagesNav rowCount={allTracks.length} pageSize={40} />
          </div>
        </>
      )}
      {deleteModal}
      {selectedSong && (
        <EditSongModal
          open={editModal}
          onClose={() => setEditModal(false)}
          song={selectedSong}
          mutate={mutate}
        />
      )}
    </SongsManagerContext.Provider>
  )
}
