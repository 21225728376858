import { Button, Modal, ModalProps } from '@ritmo/ui'
import { baseApiUrls } from '../../utils/consts'
import { useParams } from 'react-router-dom'
import { useState } from 'react'

const token = localStorage.getItem('access_token')

type CredentialsReportModalProps = Pick<ModalProps, 'open'> & {
  onClose: () => void
}

export default function CredentialsReportModal(props: CredentialsReportModalProps) {

  const { clientId } = useParams<{ clientId: string }>()
  const [downloading, setDownloading] = useState(false)

  const handleDownloadClick = () => {
    // actual download is handled by <a> tag
    setDownloading(true)
    setTimeout(() => {
      setDownloading(false)
      props.onClose()
    }, 5000)
  }

  return (
    <Modal {...props} >
      <h4>
        Reporte de credenciales
      </h4>
      <div className="h-4" />
      <p className="bg-amber-200 rounded-md p-2">
        ⚠️ Atención! Descargar este reporte generará nuevas contraseñas en todos los puntos de escucha, dejando las contraseñas actuales obsoletas.
      </p>
      <div className="h-4" />
      <div className="flex justify-end gap-x-3">
        <Button
          variant="secondary"
          onClick={() => props.onClose()}
          type="button"
          disabled={downloading}
        >
          Cancelar
        </Button>
        <a
          href={`${import.meta.env.VITE_API_URL}${baseApiUrls.CLIENT}/${clientId}/credentials-file?token=${token}`}
          download
          className={downloading ? 'pointer-events-none' : ''}
        >
          <Button
            type="button"
            disabled={downloading}
            onClick={handleDownloadClick}
          >
            Generar contraseñas y descargar reporte
          </Button>
        </a>
      </div>
    </Modal>
  )
}
