import './index.css'
import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import * as Sentry from '@sentry/react'
import { Buffer } from 'buffer'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

if (typeof window !== 'undefined' && typeof window.Buffer === 'undefined') {
  window.Buffer = Buffer
  window.process = {
    ...window.process,
  }
}

if (import.meta.env.VITE_APP_ENV) {
  Sentry.init({
    dsn: 'https://d8dec2363fe34cab9845d538bc7eb4ab@o1302526.ingest.sentry.io/6545928',
    tracesSampleRate: import.meta.env.PROD ? 0.2 : 1.0,
    environment: import.meta.env.PROD ? 'production' : 'development',
  })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY as string}>
        <App />
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  </React.StrictMode>
)
