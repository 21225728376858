import { useContext, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import useSWR from 'swr'
import { ScheduleSet, Playlist, BaseModel, Schedule } from '@ritmo/types'
import { Field, useMultipleSelect, useConfirmModal, Button } from '@ritmo/ui'
import { Plus } from 'phosphor-react'
import ColorPicker, { colors } from '../../components/ColorPicker'
import BackButton from '../../components/BackButton'
import toast from 'react-hot-toast'
import CheckItem from '../../components/CheckItem'
import { AuthContext } from '../../contexts/AuthContext'
import { ScheduleTable } from '../../components/ScheduleTable'
import { baseFetcher } from '../../lib/apis'
import { AxiosError } from 'axios'

type ScheduleToEdit = Omit<
  ScheduleSet,
  keyof BaseModel | 'enabled' | 'clientId'
>

export default function SchedulerDetail() {
  const { scheduleSetId } = useParams()
  const { data, mutate } = useSWR<ScheduleSet>(
    `${baseApiUrls.SCHEDULE}/${scheduleSetId}/detail`,
    baseFetcher
  )
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const fromListView = searchParams.get('list')
  const backUrl = `../${fromListView ? 'list' : ''}`

  if (!data) return null

  const deleteSet = async () => {
    await save({
      url: `${baseApiUrls.SCHEDULE}/${scheduleSetId}`,
      method: 'DELETE',
      messages: {
        loading: 'Eliminando programación',
        success: 'Programación eliminada',
        error: 'Error al eliminar la programación',
      },
    })
    navigate(backUrl)
  }

  const saveSchedule = async (scheduleSet: ScheduleToEdit) => {
    const newSchedule = {
      title: scheduleSet.title,
      color: scheduleSet.color,
      start: scheduleSet.start,
      end: scheduleSet.end,
      playlists: scheduleSet.playlists,
      schedule: scheduleSet.schedule,
    }
    try {
      const result = await save({
        url: `${baseApiUrls.SCHEDULE}/${scheduleSetId}`,
        method: 'PATCH',
        payload: newSchedule,
        messages: {
          loading: 'Guardando programación',
          success: 'Guardado!',
          error: 'Error guardando programación',
          callbackError: (err: AxiosError) => {
            if (err?.response?.status === 409) {
              const data = err?.response?.data || []
              const schedules = data.map((el: { title: string }) => el.title)
              return `La programación ${
                newSchedule.title
              } se superpone con ${schedules.join(', ')}`
            }

            return 'Error guardando programación'
          },
        },
      })

      if (result) {
        await mutate(result, {
          optimisticData: { ...data, ...newSchedule },
          rollbackOnError: true,
        })
        navigate(backUrl)
      }
    } catch (err) {
      toast.error('Error guardando la programación')
    }
  }

  return (
    <SchedulerDetailWithData
      scheduleSet={data}
      onDelete={deleteSet}
      onSave={saveSchedule}
      backUrl={backUrl}
    />
  )
}

export function NewSchedule() {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const fromListView = searchParams.get('list')
  const backUrl = `../${fromListView ? 'list' : ''}`

  if (!clientId) return null

  const emptySchedule: ScheduleToEdit = {
    title: '',
    color: colors[Math.floor(Math.random() * colors.length)],
    start: '',
    end: '',
    schedule: Array.from({ length: 7 }, (_, day) => ({
      startDay: String(day),
      startHour: '',
      endDay: String(day),
      endHour: '',
    })),
    playlists: [],
  }

  const createSchedule = async (newSchedule: ScheduleToEdit) => {
    const result = await save({
      url: `${baseApiUrls.SCHEDULE}`,
      method: 'POST',
      payload: { clientId, ...newSchedule },
      messages: {
        loading: 'Guardando programación',
        success: 'Guardado!',
        error: 'Error guardando programación',
        callbackError: (err: AxiosError) => {
          if (err?.response?.status === 409) {
            const data = err?.response?.data || []
            const schedules = data.map((el: { title: string }) => el.title)
            return `La programación ${
              newSchedule.title
            } se superpone con ${schedules.join(', ')}`
          }

          return 'Error guardando programación'
        },
      },
    })

    if (result) navigate(backUrl)
  }

  return (
    <SchedulerDetailWithData
      scheduleSet={emptySchedule}
      onSave={createSchedule}
      backUrl={backUrl}
    />
  )
}

interface SchedulerDetailWithDataProps {
  scheduleSet: ScheduleToEdit
  onSave: (newSet: ScheduleToEdit) => void
  onDelete?: () => void
  backUrl: string
}

function SchedulerDetailWithData({
  scheduleSet,
  onDelete,
  onSave,
  backUrl,
}: SchedulerDetailWithDataProps) {
  const { clientId } = useParams()
  const [editingSchedule, setEditingSchedule] =
    useState<ScheduleToEdit>(scheduleSet)
  const { data: playlists } = useSWR<Playlist[]>(
    `${baseApiUrls.PLAYLIST}/${clientId}`,
    baseFetcher
  )
  const toggle = useMultipleSelect(editingSchedule.playlists)
  const { user } = useContext(AuthContext)
  const isAdmin = user?.role === 'admin'

  const addSchedule = () => {
    const result = { ...editingSchedule }
    result.schedule.push({
      startDay: '',
      startHour: '',
      endDay: '',
      endHour: '',
    })
    setEditingSchedule(result)
  }

  const editInfo = (
    name: keyof Pick<ScheduleSet, 'title' | 'color' | 'start' | 'end'>,
    value: string
  ) => {
    const result = { ...editingSchedule }
    result[name] = value
    setEditingSchedule(result)
  }

  const { open: openDeleteModal, modal: deleteModal } = useConfirmModal({
    title: 'Eliminar programación?',
    description: 'Esta acción no se puede deshacer',
    confirmText: 'Si, eliminar',
    onConfirm: () => onDelete && onDelete(),
  })

  const togglePlaylist = (playlistId: string) => {
    setEditingSchedule({
      ...editingSchedule,
      playlists: toggle(playlistId),
    })
  }

  const editTitle = isAdmin
    ? 'Editar programación'
    : 'Detalles de la programación'

  const setSchedules = (newSchedules: Schedule[]) => {
    const result = { ...editingSchedule }
    result.schedule = newSchedules
    setEditingSchedule(result)
  }

  return (
    <>
      <BackButton
        to={backUrl}
        title={!onDelete ? 'Crear programación' : editTitle}
      />
      <div className="h-10" />
      <div className="md:flex gap-x-16">
        <div className="md:w-2/12 space-y-5">
          <Field label="Nombre">
            <input
              className="input w-full"
              value={editingSchedule.title}
              onChange={(e) => editInfo('title', e.currentTarget.value)}
              disabled={!isAdmin}
            />
          </Field>
          <Field label="Inicio">
            <input
              className="input w-full"
              type="date"
              value={editingSchedule.start}
              onChange={(e) => editInfo('start', e.currentTarget.value)}
              disabled={!isAdmin}
            />
          </Field>
          <Field label="Final">
            <input
              className="input w-full"
              type="date"
              value={editingSchedule.end}
              onChange={(e) => editInfo('end', e.currentTarget.value)}
              disabled={!isAdmin}
            />
          </Field>
          <Field label="Playlists">
            {playlists?.map((playlist) => (
              <CheckItem
                key={playlist._id}
                selected={editingSchedule.playlists.includes(playlist._id)}
                label={playlist.title}
                onClick={() => togglePlaylist(playlist._id)}
                disabled={!isAdmin}
              />
            ))}
          </Field>
          <Field label="Color" className="max-w-[180px]">
            <ColorPicker
              value={editingSchedule.color}
              onChange={(value) => editInfo('color', value)}
              disabled={!isAdmin}
            />
          </Field>
        </div>
        <div className="md:w-2/4 mt-4 md:mt-0 flex-1">
          <div className="flex items-center justify-between mb-5">
            <h4>Horarios</h4>
            {isAdmin && (
              <Button variant="custom" className="border" onClick={addSchedule}>
                <Plus />
                &nbsp; Agregar horario
              </Button>
            )}
          </div>
          <ScheduleTable
            schedules={editingSchedule.schedule}
            onChange={setSchedules}
            readOnly={!isAdmin}
          />
        </div>
      </div>
      <hr className="my-10" />
      {isAdmin && (
        <div className="flex items-center">
          {onDelete ? (
            <Button variant="danger" onClick={openDeleteModal}>
              Eliminar
            </Button>
          ) : null}
          <Button onClick={() => onSave(editingSchedule)} className="ml-auto">
            Guardar
          </Button>
        </div>
      )}
      {deleteModal}
    </>
  )
}
