import { Progress } from '@ritmo/ui'
import { UploadInfo } from '../atoms/upload'

interface UploadingTrackProps extends UploadInfo {
  name: string
}

export default function UploadingTrack({
  name,
  progress,
  status,
  image,
}: UploadingTrackProps) {

  return (
    <div className="flex items-center w-full">
      <div className="w-10 aspect-square mr-4">
        {image ? (
          <img src={image} className="w-full h-full rounded-md" />
        ) : null}
      </div>
      <div className="w-full">
        <div className="line-clamp-1">
          {name}
        </div>
        <div className="text-xs mb-0.5 opacity-70">
          {status}
        </div>
        <div className="w-28">
          <Progress value={progress} animated height={2} />
        </div>
      </div>
    </div>
  )
}