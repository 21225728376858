import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { format } from 'date-fns/esm'
import Agenda, { Days, Ticks } from 'react-headless-agenda'
import computeWeekEvents from '../../lib/schedule/computeWeekEvents'
import { ScheduleSet } from '@ritmo/types'
import { useMemo, useState } from 'react'
import WeekDayColumn from './WeekDayColumn'
import SchedulerNav from './SchedulerNav'
import { startOfWeek } from 'date-fns'
import TopBar from './TopBar'
import { baseFetcher } from '../../lib/apis'

const agendaDays = window.document.body.clientWidth < 768 ? 1 : 7

const daysStyle = {
  gridTemplateColumns: `60px repeat(${agendaDays}, 1fr)`,
}

export default function ScheduleSetScreen() {
  const { clientId } = useParams()
  const { data } = useSWR<ScheduleSet[]>(`${baseApiUrls.SCHEDULE}/${clientId}`, baseFetcher)
  const [viewingDate, setViewingDate] = useState(startOfWeek(new Date()))

  const events = useMemo(() => {
    if (!data) return []
    return computeWeekEvents(data, viewingDate)
  }, [data, viewingDate])

  return (
    <>
      <Agenda
        events={events}
        startDate={viewingDate}
        onStartDateChange={setViewingDate}
        days={agendaDays}
      >
        {(navProps) => (
          <>
            <TopBar>
              <SchedulerNav {...navProps} startDate={viewingDate} />
            </TopBar>
            <div className="h-5" />
            <div style={daysStyle} className="grid gap-3">
              <div />
              <Days>{({ date }) => <div key={date.toString()}>{format(date, 'ccc d')}</div>}</Days>
              <Ticks>
                {({ ticks, containerRef }) => (
                  <div ref={containerRef}>
                    {ticks.map(({ hour }) => (
                      <div key={hour} className="opacity-30 py-1">
                        {hour} hs
                      </div>
                    ))}
                  </div>
                )}
              </Ticks>
              <Days>{({ date }) => <WeekDayColumn key={date.toString()} date={date} />}</Days>
            </div>
          </>
        )}
      </Agenda>
    </>
  )
}
