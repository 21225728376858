import { useContext, useState } from 'react'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import { AuthContext } from '../../contexts/AuthContext'
import { Client } from '@ritmo/types'
import { useNavigate } from 'react-router-dom'
import { Button, Field, useConfirmModal } from '@ritmo/ui'

const Info = ({ client }: { client: Client }) => {
  const navigate = useNavigate()
  const [deleting, setDeleting] = useState(false)
  const [newData, setNewData] = useState({
    title: client.title,
    email: client.email,
  })
  const { user } = useContext(AuthContext)
  const { open: openDeleteModal, modal: deleteModal } = useConfirmModal({
    title: `Desactivar ${client.title}?`,
    description: 'Esta acción no se puede deshacer',
    onConfirm: async () => {
      setDeleting(true)
      await save({
        url: `${baseApiUrls.CLIENT}/${client._id}/disable`,
        method: 'patch',
        messages: {
          loading: 'Desactivando...',
          error: 'Error al desactivar',
          success: 'Cliente desactivado',
        },
      })
      setDeleting(false)
      navigate('/clients')
    }
  })

  const handleOnClick = () => save({
    url: `${baseApiUrls.CLIENT}/${client._id}`,
    method: 'patch',
    payload: newData,
    messages: {
      loading: 'Actualizando cliente...',
      success: 'Cliente actualizado correctamente',
      error: 'Error al actualizar el cliente',
    },
  })

  const changed = newData.title !== client.title || newData.email !== client.email

  return (
    <>
      <div className="mt-6 space-y-6">
        <Field label="Nombre">
          <input
            defaultValue={client.title}
            onChange={(e) =>
              setNewData((prev) => ({ ...prev, title: e.target.value }))
            }
            className="input w-full"
            disabled={user?.role === 'manager'}
          />
        </Field>
        <Field label="Email">
          <input
            defaultValue={client.email}
            onChange={(e) =>
              setNewData((prev) => ({ ...prev, email: e.target.value }))
            }
            className="input w-full"
            disabled={user?.role === 'manager'}
          />
        </Field>
      </div>
      <div className="mt-6 space-y-3">
        {(user?.role === 'admin') ? (
          <Button
            className="w-full"
            onClick={handleOnClick}
            disabled={!changed}
          >
            Guardar cambios
          </Button>
        ) : null}
        {(user?.role === 'admin') ? (
          <Button
            className="w-full"
            variant="danger"
            onClick={openDeleteModal}
            disabled={deleting}
          >
            Desactivar
          </Button>
        ) : null}
      </div>
      {deleteModal}
    </>
  )
}

export default Info
