import { format } from 'date-fns/esm'
import { Link } from 'react-router-dom'
import { Day, Needle } from 'react-headless-agenda'

interface WeekDayColumnProps {
  date: Date
}

export default function WeekDayColumn({ date }: WeekDayColumnProps) {
  return (
    <Day date={date}>
      {({ containerRef, events }) => (
        <div ref={containerRef} className="relative h-full">
          {events.map(({ event, bottom, top }) => (
            <Link
              to={event.setId}
              key={event.id}
              className="absolute rounded-md w-full p-2 cursor-pointer my-0.5 overflow-y-auto"
              style={{
                bottom,
                top,
                backgroundColor: event.color,
              }}
            >
              <div className="truncate">{event.title}</div>
              <div className="text-xs">
                {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
              </div>
            </Link>
          ))}
          <Needle>
            {({ top }) => <div className="absolute h-0.5 bg-red-500 z-40 w-full" style={{ top }} />}
          </Needle>
        </div>
      )}
    </Day>
  )
}
