import { useContext, useEffect, useMemo, useState, MouseEvent } from 'react'
import { Song } from '@ritmo/types'
import SongsManagerContext from './conext'
import SongActions from './SongActions'
import { Cover } from '@ritmo/ui'

interface SongRowProps {
  song: Song
  onClick: () => void
}

export default function SongRow({
  song,
  onClick,
}: SongRowProps) {
  const { title, artist, album, duration } = song
  const [adding, setAdding] = useState(false)

  const {
    selectedTracks,
    allTracks,
    addSongs,
  } = useContext(SongsManagerContext)

  const isInPlaylist = useMemo(() => {
    return allTracks.includes(song._id)
  }, [song._id, allTracks])

  const isSelected = useMemo(() => {
    return selectedTracks.includes(song._id)
  }, [song._id, selectedTracks])

  const addSong = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setAdding(true)
    await addSongs([song._id])
    setTimeout(() => {
      setAdding(false)
    }, 1000)
  }

  useEffect(() => {
    if (isSelected && !isInPlaylist) {
      onClick()
    }
  }, [isInPlaylist, isSelected, onClick])

  const opacityClass = !isInPlaylist ? 'opacity-60' : ''

  return (
    <tr
      className={`
        p-10 group h-11
        ${isSelected ? 'bg-accent/10' : ''}
        ${isInPlaylist ? 'cursor-pointer' : ''}
      `}
      onClick={isInPlaylist ? onClick : () => null}
    >
      <td className={`p-0.5 w-10 ${opacityClass}`}>
        <Cover
          image={song.image}
          decoding="async"
          loading="lazy"
          size="small"
        />
      </td>
      <td className={`${opacityClass}`}>
        <div className="line-clamp-1">{title}</div>
        <small className="line-clamp-1 lg:hidden">{artist}</small>
      </td>
      <td className={`${opacityClass} hidden lg:table-cell`}>
        <div className="line-clamp-1">{artist}</div>
      </td>
      <td className={`${opacityClass} hidden lg:table-cell`}>
        <div className="line-clamp-1">{album}</div>
      </td>
      <td className={`${opacityClass} hidden lg:table-cell`}>
        <div className="line-clamp-1">{duration}</div>
      </td>
      {isInPlaylist ? (
        <SongActions song={song} />
      ) : (
        <td className="text-center">
          <button
            onClick={addSong}
            disabled={adding}
            className="border rounded px-2 py-1"
          >
            {adding ? 'Agregando...' : 'Agregar'}
          </button>
        </td>
      )}
    </tr>
  )
}