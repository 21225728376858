import { createContext, Dispatch, SetStateAction } from 'react'
import { KeyedMutator } from 'swr'
import { Song } from '@ritmo/types'

export interface SongsManagerContextProps {
  playSong: (song: Song) => void
  playingSong: Song | null
  openDeleteModal: () => void
  songs: Song[]
  selectedTracks: string[]
  setSelectedTracks: Dispatch<SetStateAction<string[]>>
  allTracks: string[]
  mutate: KeyedMutator<Song[]>
  setSelectedSong: Dispatch<SetStateAction<Song | null>>
  setEditModal: Dispatch<SetStateAction<boolean>>
  addSongs: (songs: string[]) => Promise<void>
  packId: string
  readOnly?: boolean
}

const SongsManagerContext = createContext<SongsManagerContextProps>({
  playSong: () => [],
  playingSong: null,
  openDeleteModal: () => [],
  songs: [],
  selectedTracks: [],
  setSelectedTracks: () => [],
  allTracks: [],
  mutate: () => new Promise(() => []),
  setEditModal: () => [],
  setSelectedSong: () => [],
  addSongs: () => new Promise(() => []),
  packId: '',
  readOnly: false,
})

export default SongsManagerContext