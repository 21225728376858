import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { mutate } from 'swr'
import { branchUpdateToastMessages } from '../../../hooks/useBranchActions'
import { useConfirmModal } from '@ritmo/ui'
import { Player } from '@ritmo/types'
import { baseApiUrls } from '../../../utils/consts'
import save from '../../../utils/save'

const getTitle = (player?: Player) => {
  if (!player) return ''
  const isPlaying = player.playback.status === 'playing'
  return isPlaying
    ? `Pausar reproducción en ${player.title}?`
    : `Iniciar reproducción en ${player.title}?`
}

export const useTogglePlayModal = (player?: Player) => {
  const { clientId } = useParams()
  const [mutating, setMutating] = useState(false)
  const [params] = useSearchParams()

  const confirmModalProps = useConfirmModal({
    title: getTitle(player),
    onConfirm: async () => {
      if (!player) return
      const socketEvent =
        player.playback.status === 'playing' ? 'pause' : 'play'
      await save({
        url: `${baseApiUrls.BRANCHES}/${player?._id}/remote-playback-control/${socketEvent}`,
        messages: branchUpdateToastMessages,
      })
      setMutating(true)
      setTimeout(async () => {
        if (player) {
          player.playback.status =
            socketEvent === 'pause' ? 'paused' : 'playing'
        }
        await mutate(
          `${baseApiUrls.CLIENT}/${clientId}/monitor?${params.toString()}`
        )
        setMutating(false)
      }, 2000)
    },
  })

  return {
    mutating,
    ...confirmModalProps,
  }
}
