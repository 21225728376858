import { IPicture, parseBlob, selectCover } from 'music-metadata-browser'
import { getDuration } from './getDuration'

const getImage = (picture: IPicture[]) => {
  const cover = selectCover(picture)
  if (!cover?.data) return
  return `data:image/jpeg;base64,${Buffer.from(
    cover.data as Uint8Array
  ).toString('base64')}`
}

export type Metadata = {
  title: string
  name: string
  album: string
  artist: string
  label: string
  duration: string
  durationInSeconds: number
  year: number
  size: number
  image?: string
  ext: string
  fileMime: string
  kbps: number
}

export default async function getMetadata(file: File): Promise<Metadata> {
  const {
    common: {
      album = '',
      artist = '',
      title = '',
      label = '',
      year = 0,
      picture,
    },
    format: { duration, bitrate },
  } = await parseBlob(file)
  if (!duration || !bitrate) throw new Error('File is not supported')
  return {
    title,
    name: file.name,
    album,
    artist,
    label: label?.[0] || 'No Label',
    duration: getDuration(duration),
    durationInSeconds: duration,
    year,
    size: file.size,
    image: picture && getImage(picture),
    ext: file.name.split('.').pop() || '',
    fileMime: file.type,
    kbps: bitrate / 1000,
  }
}
