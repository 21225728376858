import { Button } from '@ritmo/ui'
import { Link, useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'
import { Business, WithId } from '@ritmo/types'
import EmptyMessage from '../../components/EmptyMessage'

export function BusinessRoute() {

  const { clientId } = useParams()

  const { data: business } = useSWR<WithId<Business>[]>(
    `${baseApiUrls.BUSINESSES}/by-client/${clientId}`,
    baseFetcher
  )

  const navigate = useNavigate()

  return (
    <>
      <div className="flex items-center justify-between">
        <h4>
          Razones sociales
        </h4>
        <Link to="./new">
          <Button>
            Agregar
          </Button>
        </Link>
      </div>
      <div className="h-6" />
      <table className="w-full">
        <thead>
          <tr>
            <th className="w-2/12">
              Nombre
            </th>
            <th className="w-3/12 hidden md:table-cell">
              Dirección
            </th>
            <th className="w-2/12 hidden md:table-cell">
              Pais
            </th>
            <th className="w-3/12 hidden md:table-cell">
              Provincia
            </th>
            <th className="w-2/12 hidden md:table-cell">
              Precio
            </th>
          </tr>
        </thead>
        <tbody>
          {business?.map(business => (
            <tr
              key={business._id}
              className="hover:bg-slate-200 cursor-pointer"
              onClick={() => navigate(`./${business._id}`)}
            >
              <td>
                <p className="line-clamp-1">
                  {business.name}
                </p>
              </td>
              <td className="hidden md:table-cell">
                <p className="line-clamp-1">
                  {business.address}
                </p>
              </td>
              <td className="hidden md:table-cell">
                {business.countryName}
              </td>
              <td className="hidden md:table-cell">
                <p className="line-clamp-1">
                  {business.stateName}
                </p>
              </td>
              <td className="hidden md:table-cell">
                {business.playerPrice}
              </td>
            </tr>
          ))}
        </tbody>
      </table >
      {business?.length === 0 && (
        <EmptyMessage />
      )}
    </>
  )
}
