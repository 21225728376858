import { startTransition, useState } from 'react'
import { useParams } from 'react-router-dom'
import save from '../../utils/save'
import { KeyedMutator } from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { Shift } from '@ritmo/types'
import { ConfirmButtons, Modal, Field, ModalProps } from '@ritmo/ui'

interface EditOrNewShiftModal extends Pick<ModalProps, 'open'> {
  shiftToEdit: Shift | null
  clearShiftToEdit: () => void
  mutate: KeyedMutator<Shift[]>
  onClose: () => void
}

export default function EditOrNewShiftModal({
  open,
  onClose,
  shiftToEdit,
  clearShiftToEdit,
  mutate,
}: EditOrNewShiftModal) {

  const { clientId } = useParams()
  const [newName, setNewName] = useState<string>()
  const [newFrom, setNewFrom] = useState<string>()
  const [newUntil, setNewUntil] = useState<string>()
  const [newSongsBetweenAd, setNewSongsBetweenAd] = useState<number>()
  const [adsInRow, setAdsInRows] = useState<number>()

  const saveShift = async () => {
    const { _id, ...rest } = shiftToEdit || {}
    onClose()
    const url = shiftToEdit
      ? `${baseApiUrls.SHIFTS}/${_id}`
      : baseApiUrls.SHIFTS
    await save({
      url,
      method: shiftToEdit ? 'patch' : 'post',
      payload: shiftToEdit
        ? {
          ...rest,
          start: newFrom || shiftToEdit?.start,
          end: newUntil || shiftToEdit?.end,
          title: newName || shiftToEdit?.title,
          songsBetweenAd: newSongsBetweenAd || shiftToEdit?.songsBetweenAd,
          adsInRow: adsInRow || shiftToEdit?.adsInRow,
        }
        : {
          clientId,
          type: 'publicidad-turno',
          replication: true,
          start: newFrom,
          end: newUntil,
          title: newName,
          songsBetweenAd: newSongsBetweenAd,
          adsInRow: adsInRow || 1,
          creation: new Date().toISOString(),
          updated: new Date().toISOString(),
        },
      messages: {
        loading: `${shiftToEdit ? 'Actualizando' : 'Creando'} turno...`,
        error: `Error al ${shiftToEdit ? 'actualizar' : 'crear'} turno`,
        success: `Turno ${shiftToEdit ? 'actualizado' : 'creado'}`,
      },
    })
    startTransition(() => {
      clearShiftToEdit()
      setNewName(undefined)
      setNewFrom(undefined)
      setNewUntil(undefined)
    })
    mutate()
  }

  return (
    <Modal open={open} >
      <h4>
        {shiftToEdit ? 'Editar' : 'Crear'} turno
      </h4>
      <div className="my-6 space-y-4">
        <Field label="Nombre">
          <input
            className="input w-full"
            defaultValue={shiftToEdit?.title}
            onChange={(e) => setNewName(e.target.value)}
          />
        </Field>
        <div className="flex gap-4">
          <Field label="Desde">
            <input
              className="input w-[110px]"
              defaultValue={shiftToEdit?.start}
              type="time"
              onChange={(e) => setNewFrom(e.target.value)}
            />
          </Field>
          <Field label="Hasta">
            <input
              className="input w-[110px]"
              defaultValue={shiftToEdit?.end}
              type="time"
              onChange={(e) => setNewUntil(e.target.value)}
            />
          </Field>
        </div>
        <Field label="Canciones entre anuncio">
          <input
            className="input w-full"
            defaultValue={shiftToEdit?.songsBetweenAd}
            type="number"
            onChange={(e) => setNewSongsBetweenAd(Number(e.target.value))}
          />
        </Field>
        <Field label="Anuncios consecutivos">
          <input
            className="input w-full"
            type="number"
            min="1"
            defaultValue={shiftToEdit?.adsInRow}
            onChange={(e) => {
              const value = Math.max(1, parseInt(e.target.value))
              setAdsInRows(value)
            }}
          />
        </Field>
      </div>
      <ConfirmButtons
        onCancel={onClose}
        onConfirm={saveShift}
        confirmText="Guardar"
      />
    </Modal >
  )
}