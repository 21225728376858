import { CaretLeft, CaretRight } from 'phosphor-react'
import { format } from 'date-fns'
import { IconButton } from '@ritmo/ui'

interface SchedulerNavProps {
  prev: () => void
  next: () => void
  startDate: Date
  endDate: Date
}

export default function SchedulerNav({
  prev,
  next,
  startDate,
  endDate,
}: SchedulerNavProps) {
  return (
    <div className="flex items-center space-x-1 md:space-x-5">
      <IconButton
        size="small"
        className="border"
        onClick={prev}
      >
        <CaretLeft weight="bold" />
      </IconButton>
      <div className="text-xs md:text-base">
        {format((startDate), 'MMM d')} - {format(endDate, 'MMM d')}
      </div>
      <IconButton
        size="small"
        className="border"
        onClick={next}
      >
        <CaretRight weight="bold" />
      </IconButton>
    </div>
  )
}