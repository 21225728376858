import { useEffect } from 'react'
import { initTable } from './initTable'

interface EditableTableProps {
  columns: string[]
}

export function EditableTable({ columns }: EditableTableProps) {
  useEffect(() => {
    initTable()
  }, [])

  return (
    <>
      <div className="relative" id="editable-table">
        <table className="w-full">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column}>{column}</th>
              ))}
            </tr>
          </thead>
        </table>
      </div>
    </>
  )
}
