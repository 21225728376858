import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import EmptyMessage from '../../components/EmptyMessage'
import { Shift } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import EditOrNewShiftModal from './EditOrNewModal'
import { getOrderedShifts } from './orderShifts'
import { Button, IconButton, useConfirmModal } from '@ritmo/ui'
import { Pencil, Trash } from 'phosphor-react'
import save from '../../utils/save'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'

const Shifts = () => {
  const { clientId } = useParams()
  const [modalOpen, setModalOpen] = useState(false)
  const [shiftToEdit, setShiftToEdit] = useState<Shift | null>(null)

  const { data: shifts, mutate } = useSWR<Shift[]>(
    `${baseApiUrls.SHIFTS}/${clientId}`,
    baseFetcher
  )

  const deleteShift = async () => {
    await save({
      url: `${baseApiUrls.SHIFTS}/${shiftToEdit?._id}`,
      method: 'DELETE',
      messages: {
        loading: 'Eliminando turno',
        success: 'Turno eliminado',
        error: 'Error eliminando turno'
      }
    })
    mutate()
  }

  const { modal: deleteModal, open: openDeleteModal } = useConfirmModal({
    title: `Eliminar turno "${shiftToEdit?.title}" ?`,
    description: `Los anuncios dejarán de sonar de ${shiftToEdit?.start} a ${shiftToEdit?.end}.`,
    onConfirm: deleteShift,
    confirmText: 'Si, eliminar',
  })

  const orderedShifts = useMemo(() => getOrderedShifts(shifts || []), [shifts])

  return (
    <>
      <div className="flex justify-between items-center">
        <BackButton title="Turnos" />
        <Button
          className="p-2"
          onClick={() => {
            setShiftToEdit(null)
            setModalOpen(true)
          }}
        >
          Nuevo turno
        </Button>
      </div>
      {shifts?.length ? (
        <div className="mt-8 divide-y">
          <div className="flex mb-2">
            <h5 className="w-2/12 text-accent">
              Nombre
            </h5>
            <h5 className="w-2/12 text-accent">
              Horario
            </h5>
            <div className="w-3/12">
              <h5 className="text-accent">Frecuencia</h5>
              <small>(Canciones entre anuncios)</small>
            </div>
          <div className="w-2/12">
            <h5 className="text-accent">Anuncios</h5>
            <small>(Anuncios consecutivos)</small>
          </div>
          </div>
          {orderedShifts.map(shift => (
            <div
              className="py-4 flex items-center "
              key={shift._id}
            >
              <div className="w-2/12 truncate pr-4">
                {shift.title}
              </div>
              <div className="w-2/12">
                {shift.start} ⟶ {shift.end}
              </div>
              <div className="w-3/12">
                {shift.songsBetweenAd}
              </div>
              <div className="w-2/12">
                {shift?.adsInRow || 1}
              </div>
              <div className="w-3/12 flex items-center justify-end space-x-3">
                <IconButton
                  className="text-accent hover:bg-accent/5"
                  disabled={!!shift.customDays}
                  onClick={() => {
                    setModalOpen(true)
                    setShiftToEdit(shift)
                  }}
                >
                  <Pencil size={20} />
                </IconButton>
                <IconButton
                  className="text-accent hover:bg-accent/5"
                  onClick={() => {
                    setShiftToEdit(shift)
                    openDeleteModal()
                  }}
                >
                  <Trash size={20} />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {(shifts && !shifts.length) ? (
        <EmptyMessage />
      ) : null}
      <EditOrNewShiftModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        shiftToEdit={shiftToEdit}
        clearShiftToEdit={() => setShiftToEdit(null)}
        mutate={mutate}
      />
      {deleteModal}
    </>
  )
}

export default Shifts
