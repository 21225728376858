import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { Admin, Manager, User, } from '@ritmo/types'
import { AuthContextType, LoginData } from '../types'
import { baseApiUrls } from '../utils/consts'
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseApi, baseFetcher } from '../lib/apis'

const defaultContext: AuthContextType = {
  user: null,
  fetchAndUpdate: () => Promise.resolve(),
  signIn: () => null,
  signOut: () => null,
  isValidating: false,
}

export const AuthContext = createContext<AuthContextType>(defaultContext)

type ErrorTextMapper = {
  [key: number]: string
}
const errorTextMapper: ErrorTextMapper = {
  429: 'Demasiados intentos fallidos. Intente nuevamente en 1 hora',
}

const storedUser = localStorage.getItem('user')

export interface Credentials {
  access_token: string
  exp: string
}

type LoginResponse = {
  profile: User,
  credentials: Credentials,
  influxToken: string,
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isValidating, setIsValidating] = useState(false)
  const [user, setUser] = useState<User | null>(storedUser ? JSON.parse(storedUser) : null)
  const navigate = useNavigate()

  const fetchAndUpdate = useCallback(async () => {
    const { profile } = await baseFetcher<{ profile: User }>(`${baseApiUrls.AUTH}/me`)
    localStorage.setItem('user', JSON.stringify(profile))
    setUser(profile)
  }, [])

  const signIn = async (loginData: LoginData) => {
    try {
      setIsValidating(true)

      const { data } = await baseApi.post<LoginResponse>(`${baseApiUrls.AUTH}/dashboard-login`, loginData)

      localStorage.setItem('user', JSON.stringify(data))

      window.location.reload()
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const jsonError = err.toJSON() as { status: number }
        toast.error(errorTextMapper[jsonError.status] || 'Usuario o contraseña incorrectos')
      }
    } finally {
      setIsValidating(false)
    }
  }

  const signOut = async () => {
    localStorage.removeItem('user')
    setUser(null)
    return navigate('/login', { replace: true })
  }

  const value = { user, fetchAndUpdate, signIn, signOut, isValidating }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user }: { user: Admin | Manager | null } = useAuth()
  const location = useLocation()
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children
}

export const RequireAdmin = ({ children }: { children: JSX.Element }) => {
  const { user }: { user: User | null } = useAuth()
  const location = useLocation()
  if (user?.role !== 'admin') {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children
}
