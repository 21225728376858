import { IdentificationCard } from 'phosphor-react'
import Desc from './components/Desc'
import InfoHeader from './components/InfoHeader'
import { loading } from './components/loading'
import { PlayerWith7DaysDownloads, usePlayer } from '../context'
import { Button, Switch } from '@ritmo/ui'
import EditBranch from '../../Client/EditBranch'
import { useCallback, useState } from 'react'
import { baseApiUrls } from '../../../utils/consts'
import save from '../../../utils/save'
import { branchUpdateToastMessages } from '../../../hooks/useBranchActions'
import { formatDistanceToNow } from 'date-fns'
import { es } from 'date-fns/locale'

async function updateScheduling(
  player: PlayerWith7DaysDownloads
): Promise<PlayerWith7DaysDownloads> {
  await save({
    payload: { musicScheduling: !player.musicScheduling },
    url: `${baseApiUrls.BRANCHES}/${player._id}/scheduling`,
    method: 'patch',
    messages: branchUpdateToastMessages,
  })
  return {
    ...player,
    musicScheduling: !player.musicScheduling,
  }
}

async function updateStreamOnly(
  player: PlayerWith7DaysDownloads
): Promise<PlayerWith7DaysDownloads> {
  await save({
    payload: { streamOnly: !player.streamOnly },
    url: `${baseApiUrls.BRANCHES}/${player._id}/stream-only`,
    method: 'patch',
    messages: branchUpdateToastMessages,
  })
  return {
    ...player,
    streamOnly: !player.streamOnly,
  }
}

export default function BasicInfo() {
  const { player, mutate } = usePlayer()
  const [editModal, setEditModal] = useState(false)

  const handleSchedulingSwitch = useCallback(async () => {
    if (!player) return
    await mutate(updateScheduling(player), {
      optimisticData: { ...player, musicScheduling: !player.musicScheduling },
    })
  }, [player, mutate])

  const handleStreamOnlySwitch = useCallback(async () => {
    if (!player) return
    await mutate(updateStreamOnly(player), {
      optimisticData: { ...player, streamOnly: !player.streamOnly },
    })
  }, [player, mutate])

  if (!player) return loading

  const programmingLabel = player.musicScheduling
    ? 'Habilitada'
    : 'No habilitada'

  const streamOnlyLabel = player.streamOnly ? 'Habilitado' : 'No habilitado'

  return (
    <div>
      <InfoHeader
        title="General"
        updated={player.updated}
        Icon={IdentificationCard}
      />
      <Desc label="Nombre" value={player.title} />
      <Desc label="Credencial" value={player.credential} />
      <Desc
        label="Vinculación"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={player.linked || player.jwtId ? 'Vinculado' : 'No vinculado'}
      />
      <Desc
        label="Programación"
        value={
          <Switch
            checked={player.musicScheduling}
            onChange={handleSchedulingSwitch}
            label={programmingLabel}
          />
        }
      />
      <Desc
        label="Stream only"
        value={
          <Switch
            checked={!!player.streamOnly}
            onChange={handleStreamOnlySwitch}
            label={streamOnlyLabel}
          />
        }
      />
      <Desc
        label="Creación"
        value={`hace ${formatDistanceToNow(new Date(player.created), {
          locale: es,
        })} (${new Date(player.created).toLocaleDateString()})`}
      />
      <Desc
        label="Contacto"
        value={
          <>
            {player.contactName}
            {player.contactEmail && (
              <a
                href={`mailto:${player.contactEmail}`}
                className="ml-2 underline"
              >
                {player.contactEmail}
              </a>
            )}
            {player.contactPhone && (
              <a href={`tel:${player.contactPhone}`} className="ml-2 underline">
                {player.contactPhone}
              </a>
            )}
          </>
        }
      />
      <div className="h-4" />
      <Button onClick={() => setEditModal(true)}>Editar</Button>
      {editModal && (
        <EditBranch
          player={player}
          onClose={() => setEditModal(false)}
          updateBranches={async () => {
            await mutate()
            setEditModal(false)
          }}
        />
      )}
    </div>
  )
}
