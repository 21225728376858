import { User } from '@ritmo/types'

export interface LoginData {
  username: string
  password: string
  recaptchaToken: string | null
}

export interface AuthContextType {
  user: User | null
  fetchAndUpdate: () => Promise<void>
  signIn: (loginData: LoginData) => void
  signOut: () => void
  isValidating: boolean
}

export interface PlayerDownloadsRecord {
  result: string
  table: string
  _time: string
  _measurement: string
  _value: string
}

export enum MANAGER_PERMISSIONS {
  GET_PLAYLISTS_SONGS = 'get_playlists_songs',
  VIEW_USAGE_METRICS = 'view_usage_metrics',
}
