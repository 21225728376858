import { format } from 'date-fns/esm'
import Agenda, { Days, Ticks } from 'react-headless-agenda'
import { Schedule } from '@ritmo/types'
import { useMemo } from 'react'
import WeekDayColumn from './WeekDayColumn'
import { startOfWeek } from 'date-fns'
import esLocale from 'date-fns/locale/es'
import getRangesFromSch from '../../../lib/schedule/getRangesFromSch'

const daysStyle = {
  gridTemplateColumns: 'repeat(8, 1fr)',
}

export default function SchedulePreview({ schedules }: { schedules: Schedule[] }) {
  const events = useMemo(() => {
    return getRangesFromSch(schedules, startOfWeek(new Date()))
  }, [schedules])

  return (
    <>
      <Agenda events={events} startDate={startOfWeek(new Date())}>
        {() => (
          <>
            <div style={daysStyle} className="grid gap-3 flex-1 h-min">
              <div />
              <Days>
                {({ date }) => (
                  <div key={date.toString()} className="uppercase text-center">
                    {format(date, 'EEEEE', { locale: esLocale })}
                  </div>
                )}
              </Days>
              <div className="text-center space-y-2">
                <Ticks>
                  {({ ticks }) => (
                    <div>
                      {ticks.map(({ hour }) =>
                        hour % 2 === 0 ? (
                          <div key={hour} className="opacity-30 text-xs">
                            {hour} hs
                          </div>
                        ) : null
                      )}
                    </div>
                  )}
                </Ticks>
                <div className="h-2" />
              </div>
              <Days>{({ date }) => <WeekDayColumn key={date.toString()} date={date} />}</Days>
            </div>
          </>
        )}
      </Agenda>
    </>
  )
}
