import { Button, Field } from '@ritmo/ui'
import BackButtonLarge from '../../components/BackButtonLarge'
import { useAuth } from '../../contexts/AuthContext'
import { isManager } from '../../lib/utils'
import { FormEvent, useCallback, useState } from 'react'
import { baseApi } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import toast from 'react-hot-toast'

export default function AccountScreen() {
  const { user, fetchAndUpdate } = useAuth()

  if (!user || !isManager(user)) throw new Error('User not found')

  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState(user.username || '')
  const [email, setEmail] = useState(user.email || '')

  const handleFormSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setLoading(true)
      try {
        await baseApi.put(`${baseApiUrls.USER}`, {
          username,
          email,
        })
        await fetchAndUpdate()
        toast.success('Usuario actualizado')
      } catch (error) {
        toast.error('Error al actualizar usuario')
      } finally {
        setLoading(false)
      }
    },
    [username, email, fetchAndUpdate]
  )

  return (
    <div>
      <div className="py-5">
        <BackButtonLarge to={`/clients/${user.clientId}`} title="Cuenta" />
      </div>
      <hr />
      <div className="h-5" />
      <form onSubmit={handleFormSubmit} className="max-w-md">
        <Field label="Nombre">
          <input
            className="input w-full"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Field>
        <div className="h-5" />
        <Field label="Email">
          <input
            className="input w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
        </Field>
        <div className="h-5" />
        <Button disabled={loading} type="submit">
          Guardar
        </Button>
      </form>
    </div>
  )
}
