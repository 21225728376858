import { Day, Needle } from 'react-headless-agenda'

interface WeekDayColumnProps {
  date: Date,
}

export default function WeekDayColumn({
  date,
}: WeekDayColumnProps) {
  return (
    <Day date={date}>
      {({ containerRef, events }) => (
        <div
          ref={containerRef}
          className="relative h-full"
        >
          {events.map(({ event, bottom, top }) => (
            <div
              key={`${event.start.valueOf()}-${event.end.valueOf()}`}
              className="absolute rounded-md w-full my-0.5 bg-slate-300 opacity-70"
              style={{
                bottom,
                top,
              }}
            />
          ))}
          <Needle>
            {({ top }) => (
              <div
                className="absolute h-0.5 bg-red-500 z-20 w-full"
                style={{ top }}
              />
            )}
          </Needle>
        </div>
      )}
    </Day>
  )
}