import { FormEvent, useState } from 'react'
import { Player } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import { Modal, ConfirmButtons } from '@ritmo/ui'
import { branchUpdateToastMessages } from '../../hooks/useBranchActions'
import { PlayerFormFields } from './PlayerFormFields'
import { formToSearchParams } from '../../utils/formToSearchParams'

const bodyLayout = {
  messages: branchUpdateToastMessages,
}

const EditBranch = ({
  player,
  onClose,
  updateBranches,
}: {
  player: Player
  onClose: () => void
  updateBranches: () => Promise<void>
}) => {
  const [saving, setSaving] = useState(false)

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSaving(true)
    await save({
      payload: formToSearchParams(e.currentTarget),
      url: `${baseApiUrls.BRANCHES}/${player._id}/basic-info`,
      method: 'patch',
      ...bodyLayout,
    })
    await updateBranches()
    setSaving(false)
  }

  return (
    <Modal className="w-10/12 max-w-2xl" open onClose={onClose}>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Editar sucursal
      </h3>
      <form className="mt-4 space-y-4" onSubmit={handleSubmit}>
        <PlayerFormFields player={player} />
        <div className="h-3" />
        <ConfirmButtons
          onCancel={onClose}
          confirmText="Guardar"
          disabled={saving}
        />
      </form>
    </Modal>
  )
}

export default EditBranch
