import { Player, Song } from '@ritmo/types'
import { Cover, IconButton, Modal } from '@ritmo/ui'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import { Pause, Play, X } from 'phosphor-react'
import { useTogglePlayModal } from '../branches/monitoring/useTogglePlayModal'

export default function RemoteDialog({
  playerId,
  onClose,
}: {
  playerId: string
  onClose: () => void
}) {
  const { data: player } = useSWR<Player>(
    `${baseApiUrls.BRANCHES}/${playerId}`,
    baseFetcher,
    {
      refreshInterval: 10 * 1000,
    }
  )

  const { open: openConfirm, modal, mutating } = useTogglePlayModal(player)

  const trackId = player?.playback?.trackId

  const { data: track } = useSWR<Song>(
    trackId && `${baseApiUrls.TRACKS}/${trackId}`,
    baseFetcher
  )

  return (
    <Modal className="w-full max-w-sm" open>
      <div className="flex items-center justify-between">
        <h3>Sonando ahora</h3>
        <IconButton onClick={onClose}>
          <X size={20} />
        </IconButton>
      </div>
      <div className="h-4" />
      <div className="flex items-center gap-x-4 bg-slate-100/70 rounded-xl p-2">
        <div className="w-20">
          <Cover image={track?.image} />
        </div>
        <div className="flex-1">
          <div className="line-clamp-1">{track?.title}</div>
          <small className="line-clamp-1">
            {player?.playback.playlistTitle}
          </small>
        </div>
      </div>
      <div className="h-4" />
      <button
        onClick={() => {
          openConfirm()
        }}
        disabled={mutating}
        className="inline-flex items-center py-1 px-2 border border-slate-300 rounded-md"
      >
        {mutating || !player ? (
          '...'
        ) : player.playback.status === 'playing' ? (
          <>
            <Pause />
            &nbsp;Pausar
          </>
        ) : (
          <>
            <Play />
            &nbsp;Iniciar
          </>
        )}
      </button>
      {modal}
    </Modal>
  )
}
