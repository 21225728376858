import { BellRinging, BellSlash } from 'phosphor-react'

export interface NotificationsToggleProps {
  notificationsEnabled: boolean
  onChange: (value: boolean) => void
}

export function NotificationsToggle({ notificationsEnabled, onChange }: NotificationsToggleProps) {
  const Icon = notificationsEnabled ? BellRinging : BellSlash
  const label = notificationsEnabled ? 'ON' : 'OFF'
  return (
    <button
      className={`
        text-xs flex flex-col items-center rounded gap-x-1 px-1.5 py-1.5 hover:bg-slate-200 sm:flex-row
        ${notificationsEnabled ? 'text-green-500' : 'opacity-60'}
      `}
      onClick={() => onChange(!notificationsEnabled)}
    >
      <Icon size={16} />
      {label}
    </button>
  )
}
