import { Method } from 'axios'
import { Fragment, useRef, useState } from 'react'
import { Branch } from '@ritmo/types'
import { baseApiUrls } from '../utils/consts'
import save from '../utils/save'
import { BranchAction } from '../views/Client/BranchActions'
import { Button, Modal, useConfirmModal } from '@ritmo/ui'

export const branchUpdateToastMessages = {
  loading: 'Actualizando sucursal...',
  success: 'Sucursal actualizada',
  error: 'Error al actualizar la sucursal',
}

export const userUpdateToastMessages = {
  loading: 'Actualizando usuario...',
  success: 'Usuario actualizada',
  error: 'Error al actualizar el usuario',
}

export default function useBranchActions(updateBranch: () => void) {
  const branch = useRef<Branch | null>(null)
  const [generatedPassword, setGeneratedPassword] = useState<string | null>(null)

  const editBranch = async <R, P = undefined>(
    endpoint: string,
    method: Method = 'get',
    payload?: P
  ): Promise<R> => {
    const r = await save({
      method,
      url: `${baseApiUrls.BRANCHES}/${branch.current?._id}/${endpoint}`,
      payload,
      messages: branchUpdateToastMessages,
    })
    updateBranch()
    return r as R
  }

  const generatePassword = async () => {
    const password = await editBranch<string>('generate-password')
    setGeneratedPassword(password)
  }

  const { open: openSignOutModal, modal: signOutModal } = useConfirmModal({
    title: <>Cerrar sesión en {branch.current?.title} ?</>,
    onConfirm: () => editBranch('logout'),
  })

  const { open: openDisableModal, modal: disableModal } = useConfirmModal({
    title: <>Deshabilitar {branch.current?.title} ?</>,
    description: 'Podrás volver a habilitarla más tarde.',
    onConfirm: () => editBranch('change-enabled', 'patch', { enabled: false }),
  })

  const { open: openDeleteModal, modal: deleteModal } = useConfirmModal({
    title: <>Eliminar {branch.current?.title} ?</>,
    description: 'Esta acción no se puede deshacer.',
    onConfirm: () => editBranch('delete'),
  })

  const { open: openForceRefreshModal, modal: forceRefreshModal } = useConfirmModal({
    title: <>Limpiar cache en {branch.current?.title} ?</>,
    description: 'Esta acción interrumpirá la música actual por unos segundos.',
    onConfirm: () => editBranch('force-refresh'),
  })

  const { open: openGeneratePassModal, modal: generatePassModal } = useConfirmModal({
    title: <>Generar contraseña para {branch.current?.title} ?</>,
    onConfirm: generatePassword,
  })

  const mapTypesHandlers = {
    [BranchAction.GEN_PASSWORD]: openGeneratePassModal,
    [BranchAction.SIGN_OUT]: openSignOutModal,
    [BranchAction.DISABLE]: openDisableModal,
    [BranchAction.DELETE]: openDeleteModal,
    [BranchAction.ENABLE]: async () => {
      editBranch('change-enabled', 'patch', { enabled: true })
    },
    [BranchAction.FORCE_RELOAD]: openForceRefreshModal,
  }

  const handleBranchAction = async (type: BranchAction, selectedBranch: Branch) => {
    branch.current = selectedBranch
    mapTypesHandlers[type]()
  }

  const newPassModal = (
    <Modal open={generatedPassword !== null} key="new-pass-modal">
      <h4 className="mb-2">Contraseña generada</h4>
      <p className="mb-4">
        Esta contraseña no será guardada, asegúrate de copiarla a un lugar seguro.
      </p>
      <h2>{generatedPassword}</h2>
      <div className="text-right mt-4">
        <Button onClick={() => setGeneratedPassword(null)}>Cerrar</Button>
      </div>
    </Modal>
  )

  return {
    handleBranchAction,
    modals: [
      <Fragment key="sign-out">{signOutModal}</Fragment>,
      <Fragment key="disable">{disableModal}</Fragment>,
      <Fragment key="delete">{deleteModal}</Fragment>,
      <Fragment key="refresh">{forceRefreshModal}</Fragment>,
      <Fragment key="pass">{generatePassModal}</Fragment>,
      <Fragment key="new-pass">{newPassModal}</Fragment>,
    ],
  }
}
