import Info from './Info'
import useClient from '../../hooks/useClient'
import { Cover } from '@ritmo/ui'
import EditPhotoIcon from '../../components/EditPhotoIcon'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'
import ManagerPermissions from './ManagerPermissions'
import { Outlet } from 'react-router-dom'

export default function ClientHome() {
  const { client, mutate } = useClient()
  const { user } = useContext(AuthContext)
  if (!client) return null

  return (
    <div className="md:flex gap-8">
      <div className="md:w-1/4">
        <Cover image={client.image} className="relative">
          {user?.role === 'admin' && (
            <>
              <EditPhotoIcon
                resourceId={client._id}
                onChange={mutate}
                type={'clients'}
              />
              <div className="h-32 w-full absolute bottom-0 bg-gradient-to-t from-black/20 to-transparent z-10" />
            </>
          )}
        </Cover>
        <Info client={client} />
        <div className="h-10" />
      </div>
      <div className="flex-1">
        <ManagerPermissions />
        <div className="h-10" />
        <Outlet />
      </div>
    </div>
  )
}
