import { BranchesSchedule } from '@ritmo/types'
import { Button } from '@ritmo/ui'
import { useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'

export function SettingsScreen() {

  const { clientId } = useParams()
  const navigate = useNavigate()
  const [creating, setCreating] = useState(false)

  const { data } = useSWR<BranchesSchedule[]>(
    `${baseApiUrls.B_SCHEDULE}/client/${clientId}`,
    baseFetcher,
  )

  if (!data) return null

  if (data.length) {
    return <Navigate to={data[0]._id} />
  }

  const createBranchesSch = async () => {
    setCreating(true)
    const r = await save({
      url: `${baseApiUrls.B_SCHEDULE}`,
      method: 'POST',
      payload: { clientId },
      messages: {
        loading: 'Creando horario',
        success: 'Horario creado',
        error: 'Error creando horario'
      }
    })
    setCreating(false)
    navigate(r.id)
  }

  return (
    <div>
      <Button
        onClick={createBranchesSch}
        disabled={creating}
      >
        Crear horario de sucursal
      </Button>
    </div>
  )
}