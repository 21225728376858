import { CellCoords } from './types'

export function getCoordsFromCell(cellEl: HTMLElement): CellCoords {
  const rowStartEl = cellEl.parentElement as HTMLElement
  const startColIndex = Number(cellEl.dataset.index)
  const startRowIndex = Number(rowStartEl.dataset.index)
  return {
    x: startColIndex,
    y: startRowIndex,
  }
}
