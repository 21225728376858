import axios, { AxiosError, Method } from 'axios'
import toast from 'react-hot-toast'
import { baseApi } from '../lib/apis'

type messagesType = {
  loading: string
  error: string
  success: string
  callbackError?: (err: AxiosError) => string
}

const save = async <R, P>({
  url,
  payload,
  method = 'get',
  messages,
}: {
  url: string
  payload?: P
  method?: Method
  messages: messagesType
}) => {
  try {
    const id = toast.loading(messages.loading)
    const resp = await baseApi({
      url,
      method,
      data: payload,
    })
    toast.dismiss(id)
    toast.success(messages.success)
    return resp.data as R
  } catch (err) {
    console.error(err)
    toast.dismiss()
    let errorMessage = messages.error
    if (axios.isAxiosError(err) && messages?.callbackError) {
      errorMessage = messages?.callbackError(err)
    }
    toast.error(errorMessage)
  }
}

export default save
