import {
  ArrowClockwise,
  DotsThreeVertical,
  IdentificationCard,
  ListChecks,
  SignOut,
} from 'phosphor-react'
import { useContext, useState } from 'react'
import { IconButton, Dropdown, useConfirmModal } from '@ritmo/ui'
import { AuthContext } from '../../contexts/AuthContext'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import { Link, useParams } from 'react-router-dom'
import CredentialsReportModal from './CredentialsReportModal'

const messages = {
  loading: 'Actualizando cliente...',
  success: 'Cliente actualizado correctamente',
  error: 'Error al actualizar el cliente',
}

export default function ClientActions() {
  const { clientId } = useParams()
  const { user } = useContext(AuthContext)
  const [credentialsReportModal, setCredentialsReportModal] = useState(false)

  const { open: openRefreshModal, modal: refreshModal } = useConfirmModal({
    title: 'Limpiar cache de todos los dispositivos?',
    description:
      'Esto interrumpirá la música en todas las sucursales por unos segundos.',
    onConfirm: () => {
      save({
        url: `${baseApiUrls.CLIENT}/${clientId}/force-refresh`,
        messages,
      })
    },
  })

  const { open: openSignOutModal, modal: logoutModal } = useConfirmModal({
    title: 'Cerrar sesión en todas las sucursales?',
    description:
      'Se interrumpirá la música y cada sucursal tendrá que volver a iniciar sesión',
    onConfirm: () => {
      save({
        url: `${baseApiUrls.CLIENT}/${clientId}/logout`,
        messages,
      })
    },
  })

  return (
    <>
      <Dropdown
        renderTrigger={
          <IconButton>
            <DotsThreeVertical
              className="text-accent"
              weight="bold"
              size={22}
            />
          </IconButton>
        }
      >
        {user?.role === 'admin' ? (
          <Link
            to="bulk-branches-update"
            className="p-2 cursor-pointer hover:bg-accent/5 flex items-center space-x-3 rounded whitespace-nowrap"
          >
            <ListChecks className="text-accent" size={24} />
            <span>Actualizar players</span>
          </Link>
        ) : (
          <></>
        )}
        <div
          className="p-2 cursor-pointer hover:bg-accent/5 flex items-center space-x-3 rounded whitespace-nowrap"
          onClick={() => setCredentialsReportModal(true)}
        >
          <IdentificationCard className="text-accent" size={24} />
          <span>Reporte de credenciales</span>
        </div>
        {user?.role === 'admin' ? (
          <div
            className="p-2 cursor-pointer hover:bg-accent/5 flex items-center space-x-3 rounded whitespace-nowrap"
            onClick={openSignOutModal}
          >
            <SignOut className="text-accent" size={24} />
            <span>Cerrar todas las sesiones</span>
          </div>
        ) : (
          <></>
        )}
        {user?.role === 'admin' ? (
          <div
            className="p-2 cursor-pointer hover:bg-accent/5 flex items-center space-x-3 rounded whitespace-nowrap"
            onClick={openRefreshModal}
          >
            <ArrowClockwise className="text-accent" size={24} />
            <span>Limpiar cache</span>
          </div>
        ) : (
          <></>
        )}
      </Dropdown>
      {refreshModal}
      {logoutModal}
      <CredentialsReportModal
        open={credentialsReportModal}
        onClose={() => setCredentialsReportModal(false)}
      />
    </>
  )
}
