import { MusicNote } from 'phosphor-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { ErrorMessage } from './components/ErrorMessage'
import InfoHeader from './components/InfoHeader'
import { AdsPack, Download, Playlist } from '@ritmo/types'
import { baseApiUrls } from '../../../utils/consts'
import { baseFetcher } from '../../../lib/apis'
import { usePlayer } from '../context'

interface DownloadInfoProps {
  detail: Download
  playlist: Playlist | AdsPack
}

function PlaylistDownloadInfo({ playlist, detail }: DownloadInfoProps) {
  if (!playlist) return null
  return (
    <div>
      <div>
        {playlist.title} - {detail.status}
      </div>
      <small>
        {detail.blobsQ}/{detail.playlistSongsQ}
      </small>
      <br />
      <progress max={100} value={detail.percentage} />
    </div>
  )
}

export default function MusicDownload() {
  const { clientId } = useParams()
  const { player } = usePlayer()
  const { data: playlistsArr } = useSWR<Playlist[]>(
    `${baseApiUrls.PLAYLIST}/${clientId}`,
    baseFetcher
  )

  const playlists = useMemo(() => {
    if (!playlistsArr) return {}
    return playlistsArr.reduce((acc, adsPack) => {
      acc[adsPack._id] = adsPack
      return acc
    }, {} as Record<string, Playlist>)
  }, [playlistsArr])

  if (!player) return null

  const header = (
    <InfoHeader
      title="Playlists"
      updated={player.storageInfo?.updated}
      Icon={MusicNote}
      isLive={player.connectionInfo.status === 'ONLINE'}
    />
  )

  if (player.streamOnly)
    return (
      <div>
        {header}
        <small>N/A (Stream only)</small>
      </div>
    )

  if (!player.storageInfo?.playlists)
    return (
      <div>
        {header}
        <ErrorMessage>
          La sucursal no contiene información de descarga por playlist (revisar
          versión)
        </ErrorMessage>
      </div>
    )

  const existentPlaylists = player.storageInfo.playlists

  return (
    <div>
      {header}
      {Object.keys(existentPlaylists).map((playerPlaylistId) => {
        const [, playlistId] = playerPlaylistId.split(':')
        return (
          <PlaylistDownloadInfo
            key={playlistId}
            detail={existentPlaylists[playerPlaylistId]}
            playlist={playlists[playlistId]}
          />
        )
      })}
    </div>
  )
}
