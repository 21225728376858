import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { currentPlaylistIdState, currentSongState } from '../../atoms/playback'
import { HowlerContext } from '../../contexts/HowlerContext'
import { Song } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../../lib/apis'

export default function usePlayback() {

  const { playlistId, adsPackId } = useParams()
  const setCurrentPlaylistId = useSetRecoilState(currentPlaylistIdState)
  const { soundRef } = useContext(HowlerContext)
  const [playingSong, setCurrentSong] = useRecoilState(currentSongState)

  const playSong = async (song: Song) => {
    if (song._id !== playingSong?._id) {
      const downloadUrl = await baseFetcher<string>(`${baseApiUrls.TRACKS}/download-url?key=${song.key}`)
      setCurrentPlaylistId(playlistId || adsPackId || '')
      return setCurrentSong({ ...song, downloadUrl })
    }
    if (soundRef?.current?.playing()) {
      soundRef.current.pause()
    } else {
      soundRef?.current?.play()
    }
  }

  return {
    playSong,
    playingSong,
  }
}