import useClient from '../hooks/useClient'
import RitmoLogo from '../assets/ritmo-brand.svg'

export function PrintHeader() {
  const { client } = useClient()
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <img src={client?.image} className="w-10 h-10 mr-4" />
        <h2 className="first-letter:uppercase">{client?.title}</h2>
      </div>
      <RitmoLogo />
    </div>
  )
}
