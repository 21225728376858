export function handleKeyPress(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    e.preventDefault()
    return
  }
  const cell = e.target as HTMLElement
  if (cell.tagName !== 'TD') return
  if (cell.getAttribute('contentEditable') === 'true') return
  cell.textContent = ''
  cell.setAttribute('contentEditable', 'true')
}
