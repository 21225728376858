import BackButton from '../../components/BackButton'
import { useNavigate, useParams } from 'react-router-dom'
import save from '../../utils/save'
import { baseApiUrls } from '../../utils/consts'
import TagForm from '../../components/TagForm'

export const NewTag = () => {
  const { clientId } = useParams<{
    clientId: string
    businessId: string
  }>()

  const navigate = useNavigate()

  const messages = {
    success: 'Tag creado',
    error: 'Error al crear el tag',
    loading: 'Creando tag',
  }

  const onSubmit = async (formData: { color: string }) => {
    try {
      const r = await save<string, { color: string }>({
        url: `${baseApiUrls.TAGS}/${clientId}`,
        method: 'POST',
        payload: formData,
        messages,
      })
      if (r) {
        navigate('..')
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className="flex items-center">
        <BackButton />
        <h4>Nuevo tag</h4>
      </div>
      <div className="h-6" />
      <TagForm onSubmit={onSubmit} />
    </>
  )
}
