import { isSameDay, isWithinInterval } from 'date-fns'
import { getRangesFromSch, ProgrammingBlock } from './getRangesFromSch'
import { verifyIfSetIsActive } from './verifyIfSetIsActive'
import { ScheduleSet } from '@ritmo/types'

export interface SetWithRanges {
  set: ScheduleSet
  range: ProgrammingBlock
}

export const getTodaysSchedule = (
  scheduleSets: ScheduleSet[] = [],
  branchDate: Date,
) => {
  const blocks: SetWithRanges[] = []
  for (const set of scheduleSets) {
    if (verifyIfSetIsActive(set)) {
      const ranges = getRangesFromSch(set.schedule, new Date())
      for (const range of ranges) {
        const startOrEndsToday = isSameDay(range.start, branchDate) || isSameDay(range.end, branchDate)
        if (startOrEndsToday || isWithinInterval(branchDate, range)) {
          blocks.push({
            set,
            range,
          })
        }
      }
    }
  }
  return blocks.sort((a, b) => {
    return a.range.start.getTime() - b.range.start.getTime()
  })
}