import { endOfWeek, areIntervalsOverlapping, endOfDay } from 'date-fns'
import { ScheduleSet } from '@ritmo/types'
import d from '../stringToDate'
import getRangesFromSch, { Event } from './getRangesFromSch'

export interface WeekEvent extends Event {
  color: string
  title: string
  setId: string
  id: string
}

const computeWeekEvents = (clientSets: ScheduleSet[], startWeekDate: Date): WeekEvent[] => {
  const events = []
  const weekRange = {
    start: startWeekDate,
    end: endOfWeek(startWeekDate)
  }
  for (const clientSet of clientSets) {
    const { start, end, schedule, title, _id, color } = clientSet
    if (!areIntervalsOverlapping(
      weekRange,
      { start: d(start), end: endOfDay(d(end)) }
    )) continue

    // set is inside current view
    const setEvents = getRangesFromSch(schedule, startWeekDate)
    events.push(...setEvents.map(e => ({
      ...e,
      color,
      title,
      setId: _id,
      id: `${_id}-${e.start.valueOf()}-${e.end.valueOf()}`,
    })))
  }
  return events
}

export default computeWeekEvents