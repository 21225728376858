import { mutate } from 'swr'
import { baseApi } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'

export default function useAddSongs(packId: string) {
  const addSongs = async (songs: string[]) => {
    await baseApi.patch(
      `${baseApiUrls.PLAYLIST}/add-songs-ids/${packId}`,
      { songs },
    )
    mutate(`${baseApiUrls.PLAYLIST}/${packId}/details`)
  }
  return addSongs
}
