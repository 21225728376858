import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { DeviceMobile } from 'phosphor-react'
import Desc from './components/Desc'
import InfoHeader from './components/InfoHeader'
import { usePlayer } from '../context'
import { getUTCNow } from './programming/dates'

export default function Device() {
  const { player } = usePlayer()

  if (!player) return null

  const header = (
    <InfoHeader
      title="Dispositivo"
      updated={player.updated}
      Icon={DeviceMobile}
    />
  )

  const localTime = utcToZonedTime(getUTCNow(), player.timeZone)

  return (
    <div>
      {header}
      <Desc
        label="Navegador"
        value={
          <>
            {player.browser.name} - {player.browser.version}
          </>
        }
      />
      <Desc
        label="Sistema operativo"
        value={
          <>
            {player.os?.name} - {player.os?.version}
          </>
        }
      />
      <Desc
        label="Marca y modelo"
        value={
          <>
            {player.device.vendor}-{player.device.model}-{player.device.type}
          </>
        }
      />
      <Desc label="Versión de Ritmo" value={player.appVersion} />
      <Desc label="Zona horaria" value={player.timeZone} />
      <Desc label="Hora local" value={format(localTime, 'HH:mm')} />
    </div>
  )
}
