import { Button, IconButton, useConfirmModal } from '@ritmo/ui'
import { Link, useNavigate, useParams } from 'react-router-dom'
import EmptyMessage from '../../components/EmptyMessage'
import { baseApiUrls } from '../../utils/consts'
import useSWR, { mutate } from 'swr'
import { baseFetcher } from '../../lib/apis'
import { Tag, WithId } from '@ritmo/types'
import BackButton from '../../components/BackButton'
import { Pencil, Trash } from 'phosphor-react'
import { useRef } from 'react'
import save from '../../utils/save'

export const ListTags = () => {
  const { clientId } = useParams()
  const tagRef = useRef<Tag>()

  const { data: tags } = useSWR<WithId<Tag>[]>(
    `${baseApiUrls.TAGS}/by-client/${clientId}`,
    baseFetcher
  )
  const navigate = useNavigate()

  const handleTagDelete = async (selectedTag: Tag) => {
    tagRef.current = selectedTag
    openDeleteModal()
  }

  const { open: openDeleteModal, modal: deleteModal } = useConfirmModal({
    title: <>Eliminar {tagRef.current?.title} ?</>,
    description: 'Esta acción no se puede deshacer.',
    onConfirm: async () => {
      await save<string, Tag>({
        url: `${baseApiUrls.TAGS}/${tagRef.current?._id}`,
        method: 'DELETE',
        messages: {
          success: 'tag eliminado',
          error: 'Error al eliminar el tag',
          loading: 'Eliminando tag',
        },
      })
      await mutate(`${baseApiUrls.TAGS}/by-client/${clientId}`)
    },
  })

  return (
    <>
      <div className="flex items-center justify-between">
        <BackButton title="Tags" />
        <Link to="./new">
          <Button>Agregar</Button>
        </Link>
      </div>
      <div className="h-6" />
      <table className="w-full">
        <thead>
          <tr>
            <th className="w-3/12">Titulo</th>
            <th className="w-4/12">Color</th>
          </tr>
        </thead>
        <tbody>
          {tags?.map((tag: Tag) => (
            <tr key={tag._id} className="cursor-pointer">
              <td>
                <p className="line-clamp-1">{tag.title}</p>
              </td>
              <td>
                <div className="flex items-center">
                  <span
                    className="w-5 h-5 rounded-full inline-block ml-2"
                    style={{ backgroundColor: tag.color }}
                  />
                </div>
              </td>
              <td className="flex items-center justify-end text-accent group-hover:opacity-100 text-xl lg:text-xl gap-x-1.5 lg:gap-x-2">
                <IconButton
                  onClick={() => navigate(`./${tag._id}`)}
                  size="small"
                >
                  <Pencil className="text-accent" size={22} />
                </IconButton>
                <IconButton onClick={() => handleTagDelete(tag)} size="small">
                  <Trash className="text-accent" size={22} />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {tags?.length === 0 && <EmptyMessage />}
      {deleteModal}
    </>
  )
}
