import { useNavigate, useParams } from 'react-router-dom'
import { baseApiUrls } from '../../utils/consts'
import { Button, useConfirmModal } from '@ritmo/ui'
import save from '../../utils/save'

const PlaylistActions = () => {
  const { playlistId, adsPackId } = useParams()
  const navigate = useNavigate()

  const remove = async () => {
    const endpoint = playlistId ? `${baseApiUrls.PLAYLIST}/${playlistId}` : `${baseApiUrls.AD}/delete-ad-pack/${adsPackId}`
    await save({
      method: 'DELETE',
      url: endpoint,
      messages: {
        loading: 'Eliminando...',
        error: 'Error al eliminar',
        success: 'Registro eliminado',
      },
    })
    navigate('../')
  }

  const { open: openDeleteModal, modal: deleteModal } = useConfirmModal({
    title: 'Eliminar playlist?',
    description: 'Esta acción no se puede deshacer',
    onConfirm: remove,
  })

  return (
    <>
      <div className="mt-3 space-y-3">
        <Button
          variant="danger"
          className="w-full"
          onClick={openDeleteModal}
        >
          Eliminar
        </Button>
      </div>
      {deleteModal}
    </>
  )
}

export default PlaylistActions
