import { Database } from 'phosphor-react'
import InfoHeader from './components/InfoHeader'
import { usePlayer } from '../context'

export default function Storage() {
  const { player } = usePlayer()

  if (!player) return null
  const header = (
    <InfoHeader
      title="Almacenamiento"
      updated={player.storageInfo?.updated}
      Icon={Database}
      isLive={player.connectionInfo.status === 'ONLINE'}
    />
  )

  if (!player.storageInfo)
    return (
      <div>
        {header}
        <small>N/A (stream only)</small>
      </div>
    )

  const usageInMB = player.storageInfo.usageInMB
  const quotaInMB = player.storageInfo.quotaInMB

  const usageInGB = usageInMB / 1024
  const quotaInGB = quotaInMB / 1024

  return (
    <div>
      {header}
      <small>
        {usageInGB.toFixed(2)}GB/{quotaInGB.toFixed(2)}GB
      </small>
      <br />
      <progress max={quotaInMB} value={usageInMB} />
    </div>
  )
}
