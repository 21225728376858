import { ChangeEvent, useCallback, useContext, useMemo, useState } from 'react'
import { Button } from '@ritmo/ui'
import BranchesTable from './BranchesTable'
import { baseApiUrls } from '../../utils/consts'
import { ChartBar, Plus, Tag } from 'phosphor-react'
import { AuthContext } from '../../contexts/AuthContext'
import ClientActions from './ClientActions'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import SearchInput from '../../components/SearchInput'
import { MonitorResponse, playersContext } from '../../contexts/players'
import { PagesNav } from '../../components/PagesNav'
import { MANAGER_PERMISSIONS } from '../../types'
import { useSelection } from '../../hooks/useSelection'
import { TagsModal } from '../branches/TagsModal'

export default function Players() {
  const { clientId } = useParams()
  const { user } = useContext(AuthContext)
  const [params, setSearchParams] = useSearchParams()
  const [tagsModal, setTagsModal] = useState(false)

  const { data: monitorData, mutate: playersMutate } = useSWR<MonitorResponse>(
    `${baseApiUrls.CLIENT}/${clientId}/monitor?${params.toString()}`,
    baseFetcher,
    { refreshInterval: 60 * 1000 }
  )

  const [
    selectedPlayers,
    toggleSelectedPlayer,
    pageSelected,
    togglePagePlayers,
    resetAllItems
  ] = useSelection(monitorData?.pagePlayers.map((p) => p._id) || [])

  const toggleShowDisabledChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setSearchParams((curr) => {
        curr.set('disabled_only', e.target.value)
        curr.set('page', '1')
        return curr
      })
    },
    [setSearchParams]
  )

  const canShowMetrics = useMemo(() => {
    if (!user) return false
    if (user.role === 'admin') return true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const permissions = user.permissions || []
    return permissions.includes(MANAGER_PERMISSIONS.VIEW_USAGE_METRICS)
  }, [user])

  const actions = (
    <>
      {canShowMetrics ? (
        <Link to="metrics">
          <Button variant="secondary">
            <ChartBar weight="bold" />
            <span className="hidden md:block">&nbsp; Métricas</span>
          </Button>
        </Link>
      ) : null}
      <Link to="tags">
        <Button variant="secondary">
          <Tag weight="bold" />
          <span className="hidden md:block">&nbsp; Tags</span>
        </Button>
      </Link>
      {user?.role === 'admin' ? (
        <Link to="bulk-branches">
          <Button>
            <Plus weight="bold" />
            &nbsp; Agregar players
          </Button>
        </Link>
      ) : null}
      <ClientActions />
    </>
  )

  const selectionActions = (
    <Button variant="secondary" onClick={() => setTagsModal(true)}>
      Reemplazar tags
    </Button>
  )

  return (
    <playersContext.Provider
      value={{
        playersMutate,
        selectedPlayers,
        toggleSelectedPlayer,
        pageSelected,
        togglePagePlayers,
        resetAllItems
      }}
    >
      <div className="flex items-center mt-6 md:mt-0 justify-end gap-x-3">
        <h4 className="mr-auto">Players ({monitorData?.totalPlayers})</h4>
        {Object.keys(selectedPlayers).length ? selectionActions : actions}
      </div>
      <div className="flex mt-4 gap-x-2">
        <SearchInput />
        <div className="w-32">
          <select
            className="input"
            value={params.get('disabled_only') === 'true' ? 'true' : 'false'}
            onChange={toggleShowDisabledChange}
          >
            <option value="false">Activos</option>
            <option value="true">Inactivos</option>
          </select>
        </div>
      </div>
      <div className="h-4" />
      <BranchesTable
        pagePlayers={monitorData?.pagePlayers || []}
        totalPlayers={monitorData?.totalPlayers || 0}
      />
      <div className="text-center mt-10">
        <PagesNav rowCount={monitorData?.totalPlayers || 0} pageSize={40} />
      </div>
      {tagsModal && (
        <TagsModal
          players={Object.keys(selectedPlayers)}
          onClose={() => setTagsModal(false)}
        />
      )}
    </playersContext.Provider>
  )
}
