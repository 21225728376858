import BackButton from '../../components/BackButton'
import UpdateBulkPlayers from './UpdateBulk'

export default function UpdateBulkScreen() {
  return (
    <div>
      <div className="flex justify-between items-center">
        <BackButton title="Actualizar players" to="../" />
      </div>
      <div className="h-4" />
      <UpdateBulkPlayers />
    </div>
  )
}
