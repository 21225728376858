import { Link, useParams } from 'react-router-dom'
import useSWR from 'swr'
import EmptyMessage from '../../components/EmptyMessage'
import { formatDay } from '../../lib/utils'

import { ScheduleSet } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import TopBar from './TopBar'
import { baseFetcher } from '../../lib/apis'

export default function ScheduleList() {
  const { clientId } = useParams()

  const { data: scheduleSets } = useSWR<ScheduleSet[]>(`${baseApiUrls.SCHEDULE}/${clientId}`, baseFetcher)

  return (
    <>
      <TopBar />
      {scheduleSets ? (
        <div className="mt-8 divide-y">
          {scheduleSets.map(shift => (
            <Link
              className="block"
              to={`../${shift._id}?list=true`}
              key={shift._id}
            >
              <div
                className="cursor-pointer py-4"
              >
                <h5>{shift.title}</h5>
                {formatDay(shift.start)} ⟶ {formatDay(shift.end)}
              </div>
            </Link>
          ))}
          {!scheduleSets.length ? (
            <EmptyMessage />
          ) : null}
        </div>
      ) : null}

    </>
  )
}