import { useContext } from 'react'
import { Song } from '@ritmo/types'
import SongsManagerContext from './conext'
import SongRow from './SongRow'
import { songsTableHeader } from './tableHeader'

export default function SongsTable() {

  const {
    songs,
    setSelectedTracks,
    readOnly,
  } = useContext(SongsManagerContext)

  const handleClick = (song: Song) => {
    if (readOnly) return
    setSelectedTracks((curr) => {
      const index = curr.indexOf(song._id)
      if (index < 0) return [...curr, song._id]
      curr.splice(index, 1)
      return [...curr]
    })
  }

  return (
    <table className="w-full">
      {songsTableHeader}
      <tbody>
        {songs.map((song) => (
          <SongRow
            key={song._id}
            song={song}
            onClick={() => handleClick(song)}
          />
        ))}
      </tbody>
    </table>
  )
}