import { createCell } from './createCell'
import { getColumnsLength } from './getColumnsLength'
import { getTable } from './getTable'
import { selectedRange } from './selectRange'

function fixRowsIndexes(start: number) {
  const table = getTable()
  const tbodyEl = table.querySelector('tbody')
  if (!tbodyEl) return
  for (let i = start; i < tbodyEl.children.length; i++) {
    const row = tbodyEl.children[i]
    row.setAttribute('data-index', String(i))
  }
}

export function addRow(index?: number) {
  const table = getTable()
  const tbodyEl = table.querySelector('tbody')
  const cols = getColumnsLength(table)

  if (!tbodyEl) return

  const newRowIndex = index ?? tbodyEl.children.length
  const previousRow = tbodyEl.querySelector(
    `tr[data-index="${newRowIndex - 1}"]`
  )

  const tr = document.createElement('tr')
  tr.setAttribute('data-index', String(newRowIndex))
  for (let cellI = 0; cellI < cols; cellI++) {
    const td = createCell(cellI, '')
    tr.appendChild(td)
  }

  previousRow!.parentNode!.insertBefore(tr, previousRow!.nextSibling)

  fixRowsIndexes(newRowIndex + 1)
}

export function addRowBelowSelected() {
  if (!selectedRange) {
    addRow()
    return
  }
  const [, end] = selectedRange
  addRow(end.y + 1)
}

export function removeSelectedRows() {
  if (!selectedRange) return
  const [start, end] = selectedRange
  const table = getTable()
  const tbodyEl = table.querySelector('tbody')

  if (!tbodyEl) return

  const yStart = Math.min(start.y, end.y)
  const yEnd = Math.max(start.y, end.y)

  for (let i = yStart; i <= yEnd; i++) {
    const row = tbodyEl.querySelector(`tr[data-index="${i}"]`)
    row?.remove()
  }

  fixRowsIndexes(start.y)
}
