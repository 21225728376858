export function CurrencySelector(props: JSX.IntrinsicElements['select']) {
  console.log(props.defaultValue)
  return (
    <select className="input w-full" autoComplete="off" {...props}>
      <option value="">Selecciona una moneda</option>
      <option value="ARS">Pesos argentinos</option>
      <option value="BOB">Bolivianos</option>
      <option value="CLP">Pesos chilenos</option>
      <option value="MXN">Pesos mexicanos</option>
      <option value="USD">Dólares estadounidenses</option>
      <option value="UYU">Pesos uruguayos</option>
    </select>
  )
}
