import { CaretDown } from 'phosphor-react'
import { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { uploadStateFamily } from '../../atoms/upload'
import UploadingTrack from '../../components/UploadingTrack'
import { IconButton } from '@ritmo/ui'


interface UploadingTracksProps {
  playlistId: string
}

export default function UploadingTracks({ playlistId }: UploadingTracksProps) {
  const uploadState = useRecoilValue(uploadStateFamily(playlistId))
  const uploadArray = Object.keys(uploadState)

  const [expanded, setExpanded] = useState(true)

  if (!uploadArray.length) return null

  return (
    <div className={`
      w-10/12 max-w-sm
      bg-slate-100 border border-slate-300
      rounded-md fixed bottom-24 right-6 z-40
    `}>
      <div
        className={`
           border-slate-300 pl-4 pr-1 py-1
          flex items-center justify-between
          ${expanded ? 'border-b' : ''}
        `}
      >
        Subiendo ({uploadArray.length})
        <IconButton onClick={() => setExpanded(!expanded)}>
          <CaretDown className={expanded ? '' : 'transform rotate-180'} />
        </IconButton>
      </div>
      {expanded && (
        <div className="max-h-52 overflow-y-auto p-5">
          <div className="space-y-4">
            {uploadArray.map(fileName => (
              <div key={fileName}>
                <UploadingTrack
                  name={fileName}
                  {...uploadState[fileName]}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}