import { Playlist } from '@ritmo/types'
import { SelectionAll, SelectionSlash } from 'phosphor-react'
import useSWR from 'swr'
import { baseApiUrls } from '../utils/consts'
import { Field } from '@ritmo/ui'
import { baseFetcher } from '../lib/apis'

export function PlaylistsSelector({
  clientId,
  selectedPlaylists,
  togglePlaylists,
  setPlaylists,
  disabled = false,
  playlistUrl = `${baseApiUrls.PLAYLIST}/${clientId}`,
  label = 'Playlists',
  name = 'playlists[]',
}: {
  clientId: string
  selectedPlaylists: string[]
  togglePlaylists: (id: string) => void
  setPlaylists: (newState: string[]) => void
  disabled?: boolean
  playlistUrl?: string
  label?: string
  name?: string
}) {
  const { data: playlists = [] } = useSWR<Playlist[]>(
    playlistUrl,
    baseFetcher,
    { fallbackData: [] }
  )

  const BulkSelectionIcon = selectedPlaylists.length
    ? SelectionSlash
    : SelectionAll

  const toggleSelection = (
    <button
      className="text-sm text-accent-dark flex gap-x-1 items-center absolute right-0 top-0 z-10"
      onClick={() => {
        setPlaylists(
          selectedPlaylists.length ? [] : playlists.map((b) => b._id)
        )
      }}
      disabled={disabled}
      type="button"
    >
      <BulkSelectionIcon />
      {selectedPlaylists.length ? 'Deseleccionar todo' : 'Seleccionar todo'}
    </button>
  )

  return (
    <div className="relative">
      {toggleSelection}
      <Field label={label}>
        <div className="overflow-y-auto max-h-60">
          {playlists.map((playlist) => (
            <label key={playlist._id} className="block">
              <input
                value={playlist._id}
                type="checkbox"
                name={name}
                checked={Boolean(selectedPlaylists.includes(playlist._id))}
                onChange={() => togglePlaylists(playlist._id)}
                disabled={disabled}
              />
              &nbsp;&nbsp;
              {playlist.title}
            </label>
          ))}
        </div>
      </Field>
    </div>
  )
}
