import { handleCopy } from './listeners/handleCopy'
import { createCell } from './createCell'
import { handleDoubleClick } from './listeners/handleDoubleClick'
import { getColumnsLength } from './getColumnsLength'
import { getTable } from './getTable'
import { handleKeyDown } from './listeners/handleKeyDown'
import { handleKeyPress } from './listeners/handleKeyPress'
import { handleKeyUp } from './listeners/handleKeyUp'
import { handlePaste } from './listeners/handlePaste'
import {
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
} from './listeners/mouseEvents'

export function initTable() {
  const table = getTable()
  const cols = getColumnsLength(table)
  const tbody = table.querySelector('tbody')
  if (tbody) {
    table.removeChild(tbody)
  }
  const rangeIndicator = document.getElementById('tableRangeIndicator')
  if (rangeIndicator) {
    rangeIndicator.remove()
  }

  const tbodyEl = document.createElement('tbody')
  tbodyEl.classList.add('text-sm')

  for (let i = 0; i < 4; i++) {
    const tr = document.createElement('tr')
    tr.setAttribute('data-index', String(i))
    for (let cellI = 0; cellI < cols; cellI++) {
      const td = createCell(cellI, '')
      tr.appendChild(td)
    }
    tbodyEl.appendChild(tr)
  }

  table.appendChild(tbodyEl)

  table.addEventListener('paste', handlePaste) // paste event
  table.addEventListener('mousedown', handleMouseDown) // start selection
  table.addEventListener('mousemove', handleMouseMove) // select range
  table.addEventListener('dblclick', handleDoubleClick) // edit cell
  table.addEventListener('copy', handleCopy)
  table.addEventListener('keydown', handleKeyDown) // delete key
  table.addEventListener('keyup', handleKeyUp) // reselect in case of resize
  table.addEventListener('keypress', handleKeyPress) // edit cell

  table.addEventListener('mouseup', handleMouseUp) // end selection

  // create range indicator
  const rangeIndicatorEl = document.createElement('div')
  rangeIndicatorEl.id = 'tableRangeIndicator'
  rangeIndicatorEl.classList.add(
    'absolute',
    'ring',
    'ring-accent/60',
    'hidden',
    'rounded',
    'transition-all',
    'duration-100',
    'pointer-events-none'
  )

  table.parentElement?.appendChild(rangeIndicatorEl)
}
