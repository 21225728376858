import useSWR from 'swr'
import { baseApiUrls } from '../../utils/consts'
import { useParams, useSearchParams } from 'react-router-dom'
import { baseFetcher } from '../../lib/apis'
import BackButton from '../../components/BackButton'
import { Button, DaysIndicator } from '@ritmo/ui'
import { AdsPack } from '@ritmo/types'
import { loading } from '../../components/loading'
import { useMemo } from 'react'
import { scaleLinear } from '@visx/scale'
import { getLegiblePeriod } from '../../lib/getLegiblePeriod'
import { PrintHeader } from '../../components/PrintHeader'

interface PlaysPerPlayerRecord {
  branchId: string
  branchTitle: string
  '0-4': string
  '4-8': string
  '8-12': string
  '12-16': string
  '16-20': string
  '20-0': string
  total: string
}

interface MetricsResponse {
  playersRows: PlaysPerPlayerRecord[]
  total: number
  pack: AdsPack
  maxPlays: number
}

function HeatmapCell({
  value,
  scaleFn,
}: {
  value: string
  scaleFn: (value: number) => number
}) {
  const bgOpacity = scaleFn(parseInt(value))
  const textColor = bgOpacity < 0.5 ? 'black' : 'white'

  return (
    <td className="h-[40px] text-center relative break-inside-avoid-page">
      <span className="relative z-20" style={{ color: textColor }}>
        {value}
      </span>
      <div
        className="absolute z-10 left-0 right-0 top-0 bottom-0 bg-accent"
        style={{ opacity: bgOpacity }}
      />
    </td>
  )
}

export function AdsPackMetrics() {
  const { adsPackId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: metrics } = useSWR<MetricsResponse>(
    `${baseApiUrls.STATS}/${adsPackId}/metrics?${searchParams.toString()}`,
    baseFetcher
  )
  const { data: adsPack } = useSWR<AdsPack>(
    `${baseApiUrls.AD}/${adsPackId}/details`,
    baseFetcher
  )

  const legiblePeriod = useMemo(() => {
    return getLegiblePeriod(searchParams.get('start') || '-30d')
  }, [searchParams])

  const scale = useMemo(
    () =>
      scaleLinear({
        domain: [0, metrics?.maxPlays || 0],
        range: [0, 1],
      }),
    [metrics?.maxPlays]
  )

  const printElements = (
    <div className="hidden print:contents">
      <PrintHeader />
      <div className="h-8" />
      <h1>{adsPack?.title}</h1>
      <div className="h-2" />
      {legiblePeriod}
      <div className="h-8" />
      Días habilitados
      <div className="mt-2" />
      <DaysIndicator small days={adsPack?.days || []} />
    </div>
  )

  if (!metrics) return loading

  return (
    <>
      <div className="flex items-center justify-between print:hidden">
        <BackButton title={`${metrics?.pack.title} - Métricas`} />
        <Button onClick={window.print} variant="secondary">
          Descargar reporte
        </Button>
      </div>
      <div className="h-8 print:hidden" />
      <select
        className="input print:hidden"
        value={searchParams.get('start') || '-30d'}
        onChange={(e) => {
          setSearchParams((curr) => {
            curr.set('start', e.target.value)
            return curr
          })
        }}
      >
        <option value="-30d">Últimos 30 días</option>
        <option value="-60d">Últimos 60 días</option>
        <option value="-90d">Últimos 90 días</option>
      </select>
      {printElements}
      <div className="h-8 " />
      Total de reproducciones
      <h2>{metrics?.total}</h2>
      <div className="h-8" />
      Puntos de escucha
      <h2>{metrics.playersRows.length}</h2>
      <div className="h-8" />
      Reproducciones por punto de escucha
      <table>
        <thead>
          <tr>
            <th>Punto de escucha</th>
            <th>Total</th>
            <th>0 hs</th>
            <th>4 hs</th>
            <th>8 hs</th>
            <th>12 hs</th>
            <th>16 hs</th>
            <th>20 hs</th>
          </tr>
        </thead>
        <tbody>
          {metrics.playersRows.map((branchPlays) => (
            <tr key={branchPlays.branchId}>
              <td>{branchPlays.branchTitle}</td>
              <td className="flex items-center">{branchPlays.total}</td>
              <HeatmapCell value={branchPlays['0-4']} scaleFn={scale} />
              <HeatmapCell value={branchPlays['4-8']} scaleFn={scale} />
              <HeatmapCell value={branchPlays['8-12']} scaleFn={scale} />
              <HeatmapCell value={branchPlays['12-16']} scaleFn={scale} />
              <HeatmapCell value={branchPlays['16-20']} scaleFn={scale} />
              <HeatmapCell value={branchPlays['20-0']} scaleFn={scale} />
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
