import { Schedule } from '@ritmo/types'
import {
  isAfter,
  addWeeks,
  subWeeks,
  setDay,
} from 'date-fns'

export interface ProgrammingBlock {
  start: Date
  end: Date
  isCopy?: boolean
}

const getMockedDate = (dayOfWeek: number, hourAndMinutes: string, pivotDate = new Date()) => {
  const d = setDay(pivotDate, dayOfWeek)
  const [hour, minutes] = hourAndMinutes.split(':')
  d.setHours(Number(hour), Number(minutes), 0)
  return d
}

export const getRangesFromSch = (
  schedule: Schedule[],
  pivotDate: Date
): ProgrammingBlock[] => schedule.reduce((acc: ProgrammingBlock[], config) => {
  const { startDay, startHour, endDay, endHour } = config
  const start = getMockedDate(Number(startDay), startHour, pivotDate)
  const end = getMockedDate(Number(endDay), endHour, pivotDate)
  if (isAfter(start, end)) {
    // is cross week, create two ranges
    acc.push({ start: subWeeks(start, 1), end })
    acc.push({ start, end: addWeeks(end, 1), isCopy: true })
  } else {
    acc.push({ start, end })
  }
  return acc
}, [])