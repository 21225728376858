interface TabProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean
}

function Tab({ active, ...props }: TabProps) {
  return (
    <div
      className={`
          cursor-pointer opacity-70 text-sm rounded px-3 border
          ${active ? 'border-slate-500' : ''}
        `}
      {...props}
    />
  )
}

interface Tabs {
  name: string
  label: string
}

interface TabsProps {
  tabs: Tabs[]
  view: string
  onChange: (view: string) => void
}

export default function Tabs({ tabs, view, onChange }: TabsProps) {
  return (
    <div className="flex items-center gap-3 mb-2">
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.name}
            active={view === tab.name}
            onClick={() => onChange(tab.name)}
          >
            {tab.label}
          </Tab>
        )
      })}
    </div>
  )
}
