import axios, { AxiosRequestConfig } from 'axios'
import { baseApiUrls } from '../utils/consts'
import { baseApi, baseFetcher } from './apis'

export const uploadFile = async (
  file: File,
  key: string,
  onProgress?: (progress: number, fileName: string) => void,
  progressRef?: string
) => {
  const signedUrl = await baseFetcher<string>(
    `${baseApiUrls.UPLOAD}/signed-url?name=${key}&command=put`
  )

  const config: AxiosRequestConfig = {
    onUploadProgress: (ev) => {
      if (!onProgress) return
      onProgress(Math.round((ev.loaded / ev.total) * 100), progressRef || file.name)
    }
  }
  await axios.put(signedUrl, file, config)
  return signedUrl.split('?')[0]
}

interface HashAndName {
  name: string
  hash: string
  metadata: Record<string, string | number | undefined>
}

interface Response {
  addedIds: string[]
  namesToUpload: string[]
}

export const addSongsToPlaylist = (
  songs: HashAndName[],
  playlistId: string,
  partition: string
): Promise<Response> => baseApi.patch(
  `${baseApiUrls.PLAYLIST}/add-songs/${playlistId}`,
  { songs, partition }
)
