import { MusicNotes } from 'phosphor-react'
import { useCallback, useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import pLimit from 'p-limit'
import { useRecoilState } from 'recoil'
import { mutate as globalMutate } from 'swr'
import { UploadInfo, uploadStateFamily } from '../../atoms/upload'
import { uploadFile } from '../../lib/upload'
import { useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { baseApiUrls } from '../../utils/consts'
import { getExistentSong } from './getExistentSong'
import { mutate } from 'swr'
import SongsManagerContext from '../SongsManager/conext'
import { baseApi } from '../../lib/apis'
import type { Song } from '@ritmo/types'
import { convertFile } from '../../lib/convertFile'
import getMetadata from '../../lib/upload/getMetadata'

const DragAndDrop = () => {
  const { allTracks, mutate: tableMutate } = useContext(SongsManagerContext)
  const { playlistId, adsPackId } = useParams()
  const packId = playlistId || (adsPackId as string)
  const groupUploadState = uploadStateFamily(packId)
  const [uploadFiles, setUpload] = useRecoilState(groupUploadState)
  const [processing, setProcessing] = useState(false)

  const baseUrl = adsPackId ? baseApiUrls.AD : baseApiUrls.PLAYLIST

  const onFinish = useCallback(() => {
    tableMutate()
    mutate(`${baseUrl}/${packId}/details`)
    globalMutate(`${baseUrl}/${packId}/details`)
  }, [packId, tableMutate, baseUrl])

  const handleProgress = useCallback(
    (progress: number, fileName: string) => {
      setUpload((currState) => ({
        ...currState,
        [fileName]: {
          ...currState[fileName],
          progress,
          status: progress === 100 ? 'complete' : 'uploading',
        },
      }))
    },
    [setUpload]
  )

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setProcessing(true)
      const idsToAddToPlaylist: string[] = []

      const limit = pLimit(7)

      await Promise.allSettled(
        acceptedFiles.map((file) =>
          limit(async () => {
            const metadata = await getMetadata(file)
            const fileNameWithoutExt = `_${
              file.name.split(`.${metadata.ext}`)[0]
            }`

            const updateUpload = (
              status: UploadInfo['status'],
              progress: number
            ) => {
              console.log('updateUpload', status, progress)
              setUpload((currFiles) => ({
                ...currFiles,
                [fileNameWithoutExt]: {
                  progress,
                  status,
                  image: metadata.image,
                },
              }))
            }

            const existentSong = await getExistentSong(
              metadata.name,
              metadata.duration
            )
            const alreadyInPlaylist =
              existentSong && allTracks.includes(existentSong?._id)

            if (alreadyInPlaylist) {
              updateUpload('already in playlist', 100)
              return
            }

            if (existentSong) {
              idsToAddToPlaylist.push(existentSong._id)
              updateUpload('already uploaded', 100)
              return
            }

            const convertedFile = await convertFile(
              file,
              metadata,
              setUpload,
              fileNameWithoutExt
            )

            const { data: clientGeneratedId } = await baseApi.get(
              `${baseApiUrls.TRACKS}/object-id`
            )
            const encodedURI = encodeURIComponent(clientGeneratedId.toString())

            updateUpload('pending', 0)

            // s3, cloudant and playlist
            await uploadFile(
              convertedFile,
              encodedURI,
              handleProgress,
              fileNameWithoutExt
            )
            const { data } = await baseApi.post<Song>(baseApiUrls.TRACKS, {
              key_v2: true,
              key: encodedURI,
              created: new Date().toISOString(),
              updated: new Date().toISOString(),
              type: playlistId ? 'song' : 'sonp',
              replication: true,
              originalname: convertedFile.name,
              ...metadata,
              title: metadata.title || '',
            })
            idsToAddToPlaylist.push(data._id)
          })
        )
      )

      setUpload({})
      setProcessing(false)

      const url = playlistId
        ? `${baseApiUrls.PLAYLIST}/add-songs-ids/${packId}`
        : `${baseApiUrls.AD}/add-songs-ids/${packId}`

      if (idsToAddToPlaylist.length) {
        await baseApi.patch(url, {
          songs: idsToAddToPlaylist,
          type: playlistId ? 'song' : 'sonp',
        })
      }

      toast.success(`${idsToAddToPlaylist.length} canciones agregadas`)
      onFinish()
    },
    [setUpload, packId, allTracks, handleProgress, onFinish, playlistId]
  )

  const validator = (file: File) => {
    if (uploadFiles[file.name]) {
      throw new Error('File already in the queue')
    }
    return null
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: [
      'audio/mpeg',
      'audio/mp3',
      'audio/wav',
      'audio/aiff',
      'audio/mp4',
      '.m4a',
      '.aiff',
    ],
    disabled: processing,
    validator,
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className={`
          ${
            isDragActive
              ? 'bg-accent/10 ring-8 ring-accent/30'
              : 'ring-2 ring-slate-200'
          }
          transition-all  text-center rounded-xl p-4 text-sm flex justify-center flex-wrap
          ${processing ? 'pointer-events-none opacity-60' : ''}
        `}
      >
        <>
          <MusicNotes size={32} className="text-accent w-full" />
          {processing ? (
            <p className="mt-2">Procesando archivos...</p>
          ) : (
            <p className="mt-2">Arrastra los archivos aquí</p>
          )}
        </>
      </div>
    </div>
  )
}

export default DragAndDrop
