import { ReactNode } from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { ArrowsClockwise, DownloadSimple, ThumbsDown } from 'phosphor-react'

function NavLinkItem({ to, children }: { to: string; children: ReactNode }) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => `
        flex items-center gap-x-2 px-2 py-1 rounded-md
        ${isActive ? 'text-accent bg-accent/10' : ''}
      `}
    >
      {children}
    </NavLink>
  )
}

export function MetricsLayout() {
  return (
    <div className="flex mt-8 gap-x-12">
      <nav>
        <NavLinkItem to="download">
          <DownloadSimple /> Descargas
        </NavLinkItem>
        <NavLinkItem to="releases">
          <ArrowsClockwise /> Versiones
        </NavLinkItem>
        <NavLinkItem to="dislike">
          <ThumbsDown /> Dislikes
        </NavLinkItem>
      </nav>
      <div>
        <Outlet />
      </div>
    </div>
  )
}
