import { Business } from '@ritmo/types'
import { Button, Field, useConfirmModal } from '@ritmo/ui'
import save from '../../utils/save'
import { baseApiUrls } from '../../utils/consts'
import { useNavigate, useParams } from 'react-router-dom'
import { CountrySelector } from '../../components/location/CountrySelector'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import BackButton from '../../components/BackButton'
import { FormEvent } from 'react'
import { CurrencySelector } from './CurrencySelector'
import { loading } from '../../components/loading'

const token = localStorage.getItem('access_token')

export function BusinessDetail() {
  const { clientId, businessId } = useParams<{
    clientId: string
    businessId: string
  }>()

  const businessReportUrl = `${import.meta.env.VITE_API_URL}${
    baseApiUrls.BUSINESSES
  }/report/${businessId}?token=${token}`

  const isNew = !businessId

  const { data: business } = useSWR<Business>(
    !isNew ? `${baseApiUrls.BUSINESSES}/${businessId}` : null,
    baseFetcher
  )

  const { modal, open } = useConfirmModal({
    title: 'Eliminar razon social',
    description: `¿Estas seguro que quieres eliminar "${business?.name}"?`,
    onConfirm: async () => {
      await save<string, Business>({
        url: `${baseApiUrls.BUSINESSES}/${businessId}`,
        method: 'DELETE',
        messages: {
          success: 'Razon social eliminada',
          error: 'Error al eliminar la razon social',
          loading: 'Eliminando razon social',
        },
      })
      navigate('..')
    },
  })

  const navigate = useNavigate()

  const messages = isNew
    ? {
        success: 'Razon social creada',
        error: 'Error al crear la razon social',
        loading: 'Creando razon social',
      }
    : {
        success: 'Razon social actualizada',
        error: 'Error al actualizar la razon social',
        loading: 'Actualizando razon social',
      }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const url = isNew
      ? `${baseApiUrls.BUSINESSES}/${clientId}`
      : `${baseApiUrls.BUSINESSES}/${businessId}`

    const formData = new FormData(e.currentTarget)
    try {
      const r = await save<string>({
        url,
        method: isNew ? 'POST' : 'PUT',
        payload: Object.fromEntries(formData),
        messages,
      })
      if (r) {
        navigate('..')
      }
    } catch (err) {
      console.error(err)
    }
  }

  if (!isNew && !business) {
    return loading
  }

  return (
    <>
      <div className="flex items-center">
        <BackButton />
        <h4>{isNew ? 'Nueva razon social' : <>{business?.name}</>}</h4>
        <a href={businessReportUrl} download className="ml-auto">
          <Button size="small" variant="secondary">
            Descargar reporte
          </Button>
        </a>
      </div>
      <div className="h-6" />
      <form className="max-w-lg space-y-4" onSubmit={onSubmit}>
        <Field label="Pais *">
          <CountrySelector initialCode={business?.country} />
        </Field>
        <Field label="Razón social *">
          <input
            className="input w-full"
            autoComplete="off"
            required
            name="name"
            defaultValue={business?.name}
          />
        </Field>
        <Field label="CUIT / RUT / NIT *">
          <input
            className="input w-full"
            autoComplete="off"
            required
            name="nit"
            defaultValue={business?.nit}
          />
        </Field>
        <Field label="Dirección fiscal *">
          <input
            className="input w-full"
            autoComplete="off"
            name="address"
            required
            defaultValue={business?.address}
          />
        </Field>
        <Field label="Provincia/Departamento *">
          <input
            className="input w-full"
            autoComplete="off"
            name="stateName"
            type="text"
            defaultValue={business?.stateName}
          />
        </Field>
        <Field label="Ciudad">
          <input
            className="input w-full"
            autoComplete="off"
            name="city"
            type="text"
            defaultValue={business?.city || undefined}
          />
        </Field>
        <Field label="Precio *">
          <input
            className="input w-full"
            autoComplete="off"
            name="playerPrice"
            type="number"
            required
            defaultValue={business?.playerPrice}
            step=".01"
          />
        </Field>
        <Field label="Moneda *">
          <CurrencySelector
            name="currency"
            required
            defaultValue={business?.currency}
          />
        </Field>
        <Field label="Contacto comercial (nombre)">
          <input
            className="input w-full"
            autoComplete="off"
            name="commercialContactName"
            type="text"
            defaultValue={business?.commercialContactName || undefined}
          />
        </Field>
        <Field label="Contacto comercial (email)">
          <input
            className="input w-full"
            autoComplete="off"
            name="commercialContactEmail"
            type="text"
            defaultValue={business?.commercialContactEmail || undefined}
          />
        </Field>
        <Field label="Contacto facturación (nombre)">
          <input
            className="input w-full"
            autoComplete="off"
            name="billingContactName"
            type="text"
            defaultValue={business?.billingContactName || undefined}
          />
        </Field>
        <Field label="Contacto facturación (email)">
          <input
            className="input w-full"
            autoComplete="off"
            name="billingContactEmail"
            type="text"
            defaultValue={business?.billingContactEmail || undefined}
          />
        </Field>
        <div className="flex items-center justify-end">
          {isNew ? null : (
            <Button
              variant="danger"
              className="mr-auto"
              onClick={open}
              type="button"
            >
              Eliminar
            </Button>
          )}
          <Button type="submit">
            {isNew ? 'Crear razón social' : 'Actualizar razón social'}
          </Button>
        </div>
      </form>
      {modal}
    </>
  )
}
