import { useAuth } from '../../contexts/AuthContext'
import { Dropdown, MenuItem } from '@ritmo/ui'
import { ChartBar, SignOut, User, Users } from 'phosphor-react'
import { Link } from 'react-router-dom'
import { CaretDown } from 'phosphor-react'
import RitmoLogo from '../../assets/ritmo-brand.svg'

const Navbar = () => {
  const { user, signOut } = useAuth()
  if (!user) return null
  return (
    <div className="bg-white h-12 md:h-14 flex items-center px-4 gap-x-4 print:hidden">
      <RitmoLogo />
      {user.role === 'admin' && (
        <>
          <Link
            to="/clients"
            className="hover:underline underline-offset-1 md:ml-10 flex items-center"
          >
            <Users size={24} className="mr-1.5" />
            Clientes
          </Link>
          <Link
            to="/metrics"
            className="hover:underline underline-offset-1 md:ml-10 flex items-center"
          >
            <ChartBar size={24} className="mr-1.5" />
            Métricas
          </Link>
        </>
      )}
      <div className="ml-auto">
        <div className="flex items-center">
          <Dropdown
            renderTrigger={
              <button className="inline-flex items-center text-black bg-accent/10 rounded-md px-3 py-1">
                <span className="line-clamp-1 text-left">
                  {user.username.toUpperCase()}
                </span>
                <CaretDown
                  className="ml-2 -mr-1 text-slate-800 hover:text-gray-500"
                  aria-hidden="true"
                  weight="bold"
                />
              </button>
            }
          >
            {user.role === 'manager' ? (
              <Link to="/account">
                <MenuItem>
                  <User size={24} />
                  <span>Cuenta</span>
                </MenuItem>
              </Link>
            ) : null}
            <MenuItem onClick={signOut}>
              <SignOut size={24} />
              <span>Cerrar sesión</span>
            </MenuItem>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default Navbar
