import { IconButton } from '@ritmo/ui'
import { Pencil } from 'phosphor-react'
import { ChangeEventHandler } from 'react'
import toast from 'react-hot-toast'
import { baseApi } from '../lib/apis'
import { uploadFile } from '../lib/upload'
import { baseApiUrls } from '../utils/consts'

interface EditPhotoIconProps {
  resourceId: string
  onChange?: () => void
  type: 'playlists' | 'announcements' | 'clients'
}

export default function EditPhotoIcon({ resourceId, onChange, type }: EditPhotoIconProps) {

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    const file = e.target?.files?.[0]
    if (!file) return
    const toastId = toast.loading('Guardando imagen')
    const encodedURI = encodeURIComponent(file.name)
    await uploadFile(file, encodedURI)
    await baseApi.patch(
      `${baseApiUrls.ASSETS}/update-image/${resourceId}?resourceType=${type}`,
      { image: encodedURI }
    )
    toast.dismiss(toastId)
    toast.success('Imagen actualizada')
    onChange && onChange()
  }

  return (
    <label>
      <IconButton className="absolute z-20 bottom-3 right-3 border border-white/20 text-white backdrop-blur">
        <Pencil size={20} />
      </IconButton>
      <input
        type="file"
        className="hidden"
        onChange={handleChange}
        accept="image/jpeg,image/png"
      />
    </label>
  )
}
