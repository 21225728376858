import { useNavigate } from 'react-router-dom'
import { FormEvent, useState } from 'react'
import { Button, Field } from '@ritmo/ui'
import { useAuth } from '../../contexts/AuthContext'
import { useEffect } from 'react'
import LogoAndSlogan from '../../assets/logo-and-slogan.svg'
import Logo from '../../assets/logo.svg'
import { LoginData } from '../../types'
import useRecaptchaToken from '../../hooks/useRecaptchaToken'

function Login() {
  const navigate = useNavigate()
  const { signIn, isValidating, user } = useAuth()
  const { generateRecaptchaToken } = useRecaptchaToken()

  useEffect(() => {
    if (!user) return
    const to = user.role === 'admin'
      ? '/clients'
      : `/clients/${user.clientId}`
    navigate(to)
  }, [user, navigate])

  const [loginFields, setLoginFields] = useState<Omit<LoginData, 'recaptchaToken'>>({
    username: '',
    password: '',
  })
  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const recaptchaToken = await generateRecaptchaToken()
    signIn({ ...loginFields, recaptchaToken })
  }

  return (
    <div className="flex">
      <div className="min-h-screen w-full md:w-1/2 flex items-center justify-center">
        <div className="filter md:hidden absolute left-6 top-6">
          <Logo />
        </div>
        <div className="w-full px-5 max-w-sm">
          <h2 className="text-center mb-10">Admin Dashboard</h2>
          <form onSubmit={handleOnSubmit} className="space-y-5">
            <Field label="Username">
              <input
                type="text"
                placeholder="Username"
                autoComplete="username"
                className="input w-full"
                onChange={(e) =>
                  setLoginFields((prev) => ({ ...prev, username: e.target.value }))
                }
              />
            </Field>
            <Field label="Password">
              <input
                type="password"
                placeholder="Password"
                autoComplete="password"
                className="input w-full"
                onChange={(e) =>
                  setLoginFields((prev) => ({ ...prev, password: e.target.value }))
                }
              />
            </Field>
            <Button className="w-full font-bold p-2" disabled={isValidating} type="submit">
              {isValidating ? 'Iniciando sesión...' : 'Iniciar sesión'}
            </Button>
          </form>
        </div>
        <div className="text-xs absolute left-0 px-3 md:px-10 bottom-10 opacity-70 max-w-sm">
          This site is protected by reCAPTCHA and the Google&nbsp;
          <a href="https://policies.google.com/privacy" className="text-blue-800">Privacy Policy</a> and&nbsp;
          <a href="https://policies.google.com/terms" className="text-blue-800">Terms of Service</a> apply.
        </div>
      </div>
      <div className={`
        bg-gradient-to-b from-slate-300 items-center justify-center
        hidden md:flex flex-1
      `}>
        <LogoAndSlogan />
      </div>
    </div>
  )
}

export default Login
