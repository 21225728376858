import { useMemo } from 'react'
import { Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { AxisBottom } from '@visx/axis'
import { addHours, addMinutes, format, formatDistanceStrict } from 'date-fns'
import { Tooltip } from '@ritmo/ui'
import { es } from 'date-fns/locale'

export interface PlayerRowRecord {
  _time: string
  _value: number
  duration: number
}

export type PlayerUsageChartProps = {
  playerTitle: string
  records: PlayerRowRecord[]
  hourDetailInTooltip: boolean
  maxValue: number
}

export function getDurationString(minutes: number) {
  const d = new Date()
  d.setHours(0, 0, 0, 0)
  const finalDate = addMinutes(d, minutes)
  return formatDistanceStrict(d, finalDate, {
    locale: es,
    roundingMethod: 'round',
  })
}

function getHoursString(time: string) {
  const startDate = new Date(time)
  const fourMoreHours = addHours(startDate, 4)
  return `- De ${format(startDate, 'HH')} a ${format(fourMoreHours, 'HH')}`
}

export default function PlayerUsageChart({
  playerTitle,
  records,
  hourDetailInTooltip,
  maxValue,
}: PlayerUsageChartProps) {
  // bounds
  const xMax = 500
  const yMax = 25

  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: records.map((d) => d._time),
        padding: 0,
      }),
    [xMax, records]
  )

  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, maxValue],
      }),
    [yMax, maxValue]
  )

  return (
    <div className="text-accent">
      <svg width={xMax} height={yMax + 20}>
        <Group>
          {records.map((d) => {
            const barWidth = xScale.bandwidth()
            const barHeight = yMax - (yScale(d._value) ?? 0)
            const barX = xScale(d._time)
            const barY = yMax - barHeight
            const opacity = barHeight / yMax
            const durationStr = d.duration
              ? `${getDurationString(d.duration)} de uso`
              : 'Sin registro de uso'
            const hourStr = hourDetailInTooltip ? getHoursString(d._time) : ''
            const text = (
              <div>
                <small>
                  {format(new Date(d._time), 'dd/MM')} {hourStr} - {playerTitle}
                </small>
                <br />
                {durationStr}
              </div>
            )
            return (
              <g key={`bar-${d._time}`}>
                <Bar
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill="currentColor"
                  fillOpacity={opacity}
                />
                <Tooltip text={text} delay={0} insideSvg>
                  <rect
                    className="cursor-pointer"
                    x={barX}
                    y={0}
                    width={barWidth}
                    height={yMax}
                    fill="transparent"
                  />
                </Tooltip>
              </g>
            )
          })}
        </Group>
        <AxisBottom
          scale={xScale}
          // tickValues={records.map((r) => r._time)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tickFormat={(d: string) => {
            try {
              return format(new Date(d), 'dd/MM')
            } catch {
              console.warn('Error formatting date', {
                date: d,
                type: 'usage-report',
              })
              return ''
            }
          }}
          top={yMax - 5}
          hideAxisLine
          hideTicks
        />
      </svg>
    </div>
  )
}
