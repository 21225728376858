import BackButton from '../../components/BackButton'
import AddBulkBranches from './AddBulk'

export default function AddBulkBranchesScreen() {
  return (
    <div>
      <div className="flex justify-between items-center">
        <BackButton title="Agregar players" to="../" />
      </div>
      <div className="h-4" />
      <AddBulkBranches />
    </div>
  )
}
