import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { mutate } from 'swr'
import { Button, useConfirmModal } from '@ritmo/ui'
import SongsManagerContext from '../../components/SongsManager/conext'
import deleteTracks from '../../lib/playlists/deleteTracks'
import { baseApiUrls } from '../../utils/consts'

export default function DeleteTracksButton() {

  const {
    selectedTracks,
    setSelectedTracks,
    mutate: tableMutate,
    allTracks,
  } = useContext(SongsManagerContext)

  const { playlistId, adsPackId } = useParams()
  const packId = playlistId || adsPackId as string
  const baseUrl = adsPackId ? baseApiUrls.AD : baseApiUrls.PLAYLIST
  const hasSelected = selectedTracks.length > 0
  const qToDelete = hasSelected ? selectedTracks.length : allTracks.length
  const hasSelectedTracks = selectedTracks.length > 0

  const onConfirm = async () => {
    const type = hasSelectedTracks ? 'bulk' : 'all'
    const collection = playlistId ? 'playlists' : 'ads'
    await deleteTracks(packId, type, selectedTracks, collection)
    setSelectedTracks([])
    mutate(`${baseUrl}/${packId}/details`)
    tableMutate()
  }

  const { open, modal } = useConfirmModal({
    title: `Borrar ${qToDelete} canciones?`,
    confirmText: 'Borrar',
    onConfirm,
  })

  if (!allTracks.length) return null

  return (
    <>
      <Button
        variant="secondary"
        onClick={open}
      >
        {hasSelected
          ? 'Quitar seleccionadas'
          : 'Quitar todas'
        }
      </Button>
      {modal}
    </>
  )
}