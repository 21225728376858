import Card from '../../components/Card/Card'
import { baseApiUrls } from '../../utils/consts'
import { Client } from '@ritmo/types'
import Tools from './Tools'
import { Link, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { Button } from '@ritmo/ui'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'

function handleKeyDown(e: KeyboardEvent) {
  const linkEl = document.querySelector(`[data-letter="${e.key}"]`)
  linkEl?.scrollIntoView({ behavior: 'smooth' })
}

const Clients = () => {
  const [searchParams] = useSearchParams()
  const { data: clients } = useSWR<Client[]>(
    `${baseApiUrls.CLIENT}?${searchParams.toString()}`,
    baseFetcher
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center">
        <h2>Clientes</h2>
        <Link to="new">
          <Button>Nuevo cliente</Button>
        </Link>
      </div>
      <div className="mt-2 py-2 sticky top-0 bg-gray-100">
        <Tools />
      </div>
      <div className="cards-grid mt-2">
        {clients?.map((client) => (
          <Card
            key={client._id}
            title={client.title}
            image={client.image}
            link={`./${client._id}`}
          />
        ))}
      </div>
    </div>
  )
}

export default Clients
