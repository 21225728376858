import { Song } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import { baseApi } from '../../lib/apis'

export const getExistentSong = async (
  name: string,
  duration: string,
): Promise<Song | null> => {
  const { data } = await baseApi.post<Song>(
    `${baseApiUrls.TRACKS}/find`,
    { name, duration }
  )
  return data
}
