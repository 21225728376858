import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from '@ritmo/ui'

interface SearchInputProps {
  minChars?: number
  debounceTime?: number
  placeholder?: string
  className?: string
}

export default function SearchInput({
  minChars = 3,
  debounceTime,
  placeholder = 'Buscar',
  className = '',
}: SearchInputProps) {
  const [params, setParams] = useSearchParams()
  const [inputValue, setInputValue] = useState(params.get('search') || '')

  const debouncedSearch = useDebounce(inputValue, debounceTime)

  useEffect(() => {
    if (debouncedSearch.length >= minChars) {
      setParams(
        (curr) => {
          curr.set('page', '1')
          curr.set('search', debouncedSearch)
          return curr
        },
        { replace: true }
      )
    } else {
      setParams(
        (curr) => {
          curr.set('search', '')
          return curr
        },
        { replace: true }
      )
    }
  }, [debouncedSearch, setParams, minChars])

  return (
    <input
      className={`input ${className}`}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder={placeholder}
      onKeyDown={(e) => e.stopPropagation()}
    />
  )
}
