import { Song } from '@ritmo/types'
import { atom } from 'recoil'

export interface PlaybackState {
  playing: boolean
  timer: string
  step: number
}

export const defaultPlaybackState = {
  playing: false,
  timer: '00:00',
  step: 0,
}

export const playbackState = atom<PlaybackState>({
  key: 'playbackState',
  default: defaultPlaybackState,
})

export const currentSongState = atom<Song & { downloadUrl: string } | null>({
  key: 'currentSongState',
  default: null,
})

export const currentPlaylistIdState = atom<string | null>({
  key: 'currentPlaylistIdState',
  default: null,
})

