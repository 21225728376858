import { Button, Modal, useArrayState } from '@ritmo/ui'
import { FormEvent, useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { baseApi, baseFetcher } from '../../lib/apis'
import { baseApiUrls } from '../../utils/consts'
import useSWR from 'swr'
import { Business, WithId } from '@ritmo/types'
import { PlaylistsSelector } from '../../components/PlaylistsSelector'
import { EditableTable } from '../../components/EditableTable/EditableTable'
import { getDataFromTable } from '../../components/EditableTable/getDataFromTable'
import { formToSearchParams } from '../../utils/formToSearchParams'
import { initTable } from '../../components/EditableTable/initTable'

interface BulkDataResponseSuccess {
  ok: true
  createdPlayers: number
  csvData: string
}

interface BulkDataResponseError {
  ok: false
  duplicatedPlayers: string[]
}

type BulkDataResponse = BulkDataResponseSuccess | BulkDataResponseError

function downloadCSV(data: string) {
  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = 'players.csv'
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

const columns = [
  'Nombre',
  'Dirección',
  'Ciudad',
  'Provincia/Estado',
  'Contacto',
  'Email',
  'Teléfono',
]

export default function AddBulkBranches() {
  const { clientId } = useParams()
  const [saving, setSaving] = useState(false)
  const [duplicatedPlayers, setDuplicatedPlayers] = useState<string[]>([])
  const { data: business } = useSWR<WithId<Business>[]>(
    `${baseApiUrls.BUSINESSES}/by-client/${clientId}`,
    baseFetcher
  )
  const [playerPlaylists, setPlayerPlaylists, togglePlayerPlaylists] =
    useArrayState<string>([])
  const [playerAdsPacks, setPlayerAdsPacks, togglePlayerAdsPacks] =
    useArrayState<string>([])
  const [playerTags, setPlayerTags, togglePlayerTags] = useArrayState<string>(
    []
  )

  const handleSave = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      const toastId = toast.loading('Guardando...')
      setSaving(true)
      try {
        const htmlForm = e.currentTarget as HTMLFormElement
        const searchParams = formToSearchParams(htmlForm)
        const players = getDataFromTable()
        players.forEach((player) => {
          searchParams.append('players', player)
        })

        const { data } = await baseApi.post<BulkDataResponse>(
          `${baseApiUrls.BRANCHES}/${clientId}/bulk`,
          searchParams
        )
        if (data.ok) {
          toast.success(`${data.createdPlayers} players agregados`)
          downloadCSV(data.csvData)
          htmlForm.reset()
          setPlayerPlaylists([])
          setPlayerAdsPacks([])
          initTable()
        } else {
          setDuplicatedPlayers(data.duplicatedPlayers)
        }
      } catch (err) {
        toast.error('No se pudieron agregar los players')
      } finally {
        setSaving(false)
        toast.dismiss(toastId)
      }
    },
    [clientId, setPlayerAdsPacks, setPlayerPlaylists]
  )

  if (!clientId) return null

  return (
    <>
      <form onSubmit={handleSave} className="space-y-4">
        <EditableTable columns={columns} />
        <select className="input" name="businessId">
          <option value="">Razón social (opcional)</option>
          {business?.map((business) => (
            <option key={business._id} value={business._id}>
              {business.name}
            </option>
          ))}
        </select>
        <PlaylistsSelector
          clientId={clientId}
          selectedPlaylists={playerPlaylists}
          setPlaylists={setPlayerPlaylists}
          togglePlaylists={togglePlayerPlaylists}
          name="playlists"
        />
        <PlaylistsSelector
          clientId={clientId}
          selectedPlaylists={playerAdsPacks}
          setPlaylists={setPlayerAdsPacks}
          togglePlaylists={togglePlayerAdsPacks}
          playlistUrl={`${baseApiUrls.AD}/${clientId}`}
          label="Packs de anuncios"
          name="adsPacks"
        />
        <PlaylistsSelector
          clientId={clientId}
          selectedPlaylists={playerTags}
          setPlaylists={setPlayerTags}
          togglePlaylists={togglePlayerTags}
          playlistUrl={`${baseApiUrls.TAGS}/by-client/${clientId}`}
          label="Etiquetas"
          name="tags"
        />
        <div>
          <Button disabled={saving} type="submit">
            Crear players
          </Button>
        </div>
      </form>
      <Modal open={duplicatedPlayers.length > 0}>
        <h4 className="mb-2">
          {duplicatedPlayers.length} players ya existentes:
        </h4>
        <ul className="mb-4">
          {duplicatedPlayers.map((credential) => (
            <li key={credential}>{credential}</li>
          ))}
        </ul>
        <Button onClick={() => setDuplicatedPlayers([])}>Cerrar</Button>
      </Modal>
    </>
  )
}
