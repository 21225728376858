import { IconButton, Tooltip } from '@ritmo/ui'
import { Download } from 'phosphor-react'
import { baseApiUrls } from '../../utils/consts'
import { useParams } from 'react-router-dom'

const token = localStorage.getItem('access_token')

export function DownloadTracksCsvButton() {
  const { playlistId } = useParams()

  if (!playlistId) return null

  return (
    <Tooltip text="Descargar playlist en csv">
      <a
        download
        href={`${import.meta.env.VITE_API_URL}${baseApiUrls.PLAYLIST}/${playlistId}/tracks-as-csv/?token=${token}`}
        className="inline-flex"
      >
        <IconButton variant="custom" className="border">
          <Download size={20} />
        </IconButton>
      </a>
    </Tooltip>
  )
}
