import { Plug } from 'phosphor-react'
import Desc from './components/Desc'
import InfoHeader from './components/InfoHeader'
import { usePlayer } from '../context'
import { format } from 'date-fns'

export default function ConnectionInfo() {

  const { player } = usePlayer()
  if (!player) return null

  const statusChangedAt = player.connectionInfo.statusChangedAt
  const since = statusChangedAt && format(new Date(statusChangedAt), 'dd/MM/yyyy HH:mm:ss')

  return (
    <div>
      <InfoHeader
        title="Conexión"
        updated={statusChangedAt}
        Icon={Plug}
      />
      <Desc label="Estado" value={player.connectionInfo.status} />
      <Desc
        label="Última desconexión"
        value={player.connectionInfo.disconnectReason}
      />
      <Desc
        label="Desde"
        value={since}
      />
    </div>
  )
}