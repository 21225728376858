import { useParams } from 'react-router-dom'
import { Playlist } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import PlaylistActions from './PlaylistActions'
import { Cover } from '@ritmo/ui'
import BackButton from '../../components/BackButton'
import UploadingTracks from './UploadingTracks'
import EditPhotoIcon from '../../components/EditPhotoIcon'
import PlaylistInfo from './PlaylistInfo'
import SongsManager from '../../components/SongsManager'
import useSWR from 'swr'
import { baseFetcher } from '../../lib/apis'
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

export default function PlaylistScreen() {
  const { user } = useContext(AuthContext)
  const { playlistId } = useParams()
  const { data: playlist, mutate } = useSWR<Playlist>(
    `${baseApiUrls.PLAYLIST}/${playlistId}/details`,
    baseFetcher
  )

  if (!playlist) return null

  const isManager = user?.role === 'manager'

  return (
    <>
      <BackButton title={playlist.title} />
      <div className="h-10" />
      <div className="md:flex gap-x-10">
        <div className="md:w-1/4 flex-shrink-0">
          <Cover
            className="relative"
            image={playlist.image}
          >
            <EditPhotoIcon resourceId={playlist._id} onChange={mutate} type={'playlists'} />
            <div className="h-32 w-full absolute bottom-0 bg-gradient-to-t from-black/20 to-transparent z-10" />
          </Cover>
          {!isManager && (
            <>
              <PlaylistInfo playlist={playlist} onSave={mutate} />
              <PlaylistActions />
            </>
          )}
        </div>
        <div className="flex-1 mt-4 md:mt-0">
          <SongsManager
            readOnly={isManager}
            title="Canciones"
            allTracks={playlist.songs}
          />
        </div>
      </div>
      <UploadingTracks playlistId={playlist._id} />
    </>
  )
}
