import { Equalizer } from 'phosphor-react'

export default function EmptyMessage({ title = 'Nada por aquí' }: { title?: string }) {
  return (
    <div className="opacity-40 text-center max-w-xs mx-auto p-6">
      <Equalizer size={60} className="inline" />
      <h4 className="mt-3">
        {title}
      </h4>
    </div>
  )
}