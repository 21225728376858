import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/Layout'
import ClientHome from './views/Client'
import Playlists from './views/Playlists'
import Playlist from './views/Playlists/Playlist'
import Login from './views/Login'
import { AuthProvider, RequireAdmin, RequireAuth } from './contexts/AuthContext'
import ClientsList from './views/ClientsList/ClientsList'
import Ads from './views/Ads'
import NewPlaylist from './views/Playlists/NewPlaylist'
import NewAd from './views/Ads/NewAd'
import Ad from './views/Ads/Ad'
import Shifts from './views/Shifts'
import ClientLayout from './components/ClientLayout'
import { Toaster } from 'react-hot-toast'
import ScheduleSet from './views/Schedule'
import SchedulerDetail, { NewSchedule } from './views/Schedule/ScheduleDetail'
import { RecoilRoot } from 'recoil'
import NewClient from './views/ClientsList/NewClient'
import { HowlerProvider } from './contexts/HowlerContext'
import ScheduleList from './views/Schedule/ScheduleList'
import AccountScreen from './views/Account/AccountScreen'
import { SettingsScreen } from './views/Settings/Settings'
import { CustomSchDetail } from './views/Settings/custom-schedule/CustomSchDetail'
import AddBulkBranchesScreen from './views/branches/AddBulkScreen'
import Players from './views/Client/Players'
import PlayerDetails from './views/branches/PlayerDetails'
import { BusinessRoute } from './views/Settings/Business'
import { BusinessDetail } from './views/Settings/BusinessDetail'
import UpdateBulkScreen from './views/branches/UpdateBulkScreen'
import { AdsPackMetrics } from './views/Ads/AdsPackMetrics'
import { PlayerMetrics } from './views/PlayersMetrics/PlayersMetrics'
import { MetricsLayout } from './views/metrics/MetricsLyout'
import { DownloadMetrics } from './views/metrics/DownloadMetrics'
import { ReleasesMetrics } from './views/metrics/ReleasesMetrics'
import { DislikeMetrics } from './views/metrics/DislikeMetrics'
import { NewTag } from './views/Tags/NewTag'
import { UpdateTag } from './views/Tags/UpdateTag'
import { ListTags } from './views/Tags/ListTags'

function App() {
  const clientsRoutes = (
    <Route path="clients">
      <Route
        index
        element={
          <RequireAdmin>
            <ClientsList />
          </RequireAdmin>
        }
      />
      <Route path="new" element={<NewClient />} />
      <Route path=":clientId" element={<ClientLayout />}>
        <Route index element={<Navigate to="players" replace />} />
        <Route path="playlists">
          <Route index element={<Playlists />} />
          <Route path=":playlistId" element={<Playlist />} />
          <Route path="new-playlist" element={<NewPlaylist />} />
        </Route>
        <Route path="schedule">
          <Route index element={<ScheduleSet />} />
          <Route path="list" element={<ScheduleList />} />
          <Route path=":scheduleSetId" element={<SchedulerDetail />} />
          <Route path="new" element={<NewSchedule />} />
        </Route>
        <Route path="ads">
          <Route index element={<Ads />} />
          <Route path=":adsPackId">
            <Route index element={<Ad />} />
            <Route path="metrics" element={<AdsPackMetrics />} />
          </Route>
          <Route path="new-ad" element={<NewAd />} />
          <Route path="shifts" element={<Shifts />} />
        </Route>
        <Route path="players">
          <Route index element={<Players />} />
          <Route path="metrics" element={<PlayerMetrics />} />
          <Route path="bulk-branches" element={<AddBulkBranchesScreen />} />
          <Route path="bulk-branches-update" element={<UpdateBulkScreen />} />
          <Route path=":playerId" element={<PlayerDetails />} />
          <Route path="tags">
            <Route index element={<ListTags />} />
            <Route path="new" element={<NewTag />} />
            <Route path=":tagId" element={<UpdateTag />} />
          </Route>
        </Route>
        <Route path="settings">
          <Route element={<ClientHome />}>
            <Route index element={<Navigate to="business" replace />} />
            <Route path="business">
              <Route index element={<BusinessRoute />} />
              <Route path="new" element={<BusinessDetail />} />
              <Route path=":businessId" element={<BusinessDetail />} />
            </Route>
          </Route>
          <Route path="custom-schedule" element={<SettingsScreen />} />
          <Route path=":customSchId" element={<CustomSchDetail />} />
        </Route>
      </Route>
    </Route>
  )

  const accountRoutes = <Route path="account" element={<AccountScreen />} />

  const metricsRoutes = (
    <Route path="metrics" element={<MetricsLayout />}>
      <Route index element={<Navigate to="download" />} />
      <Route path="download" element={<DownloadMetrics />} />
      <Route path="releases" element={<ReleasesMetrics />} />
      <Route path="dislike" element={<DislikeMetrics />} />
    </Route>
  )

  return (
    <RecoilRoot>
      <HowlerProvider>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            >
              <Route index element={<Navigate to="/clients" />} />
              {clientsRoutes}
              {accountRoutes}
              {metricsRoutes}
            </Route>
          </Routes>
        </AuthProvider>
      </HowlerProvider>
      <Toaster position="top-center" />
    </RecoilRoot>
  )
}

export default App
