import { Download } from 'phosphor-react'
import InfoHeader from './components/InfoHeader'
import { usePlayer } from '../context'
import DownloadsChart from '../../../components/DownloadsChart'
import { useMemo } from 'react'

export default function LastDownloads() {

  const { player } = usePlayer()

  const total = useMemo(() => {
    if (!player?.last7DaysDownloads) return 0
    return player?.last7DaysDownloads.reduce((acc, cur) => acc + Number(cur._value), 0)
  }, [player?.last7DaysDownloads])

  const emptyLoginsTotal = useMemo(() => {
    if (!player?.last7DaysEmptyLogins) return 0
    return player?.last7DaysEmptyLogins.reduce((acc, cur) => acc + Number(cur._value), 0)
  }, [player?.last7DaysEmptyLogins])

  if (!player) return null

  return (
    <div>
      <InfoHeader
        title="Descargas"
        Icon={Download}
      />
      <small>
        <b>{total}</b> tracks descargados en los últimos 7 días
      </small>
      {total > 0 && (
        <>
          <div className="h-4" />
          <DownloadsChart records={player.last7DaysDownloads} />
          <div className="h-6" />
        </>
      )}
      <div className="h-6" />
      <small>
        <b>{emptyLoginsTotal}</b> inicios de sesión sin música en los últimos 7 días
      </small>
      {emptyLoginsTotal > 0 && (
        <>
          <div className="h-4" />
          <DownloadsChart records={player.last7DaysEmptyLogins} />
        </>
      )}
    </div>
  )
}