import { Player } from '@ritmo/types'
import { createContext, useContext } from 'react'
import { PlayerDownloadsRecord } from '../../types'
import { KeyedMutator } from 'swr'

export type PlayerWith7DaysDownloads = Player & {
  last7DaysDownloads: PlayerDownloadsRecord[]
  last7DaysEmptyLogins: PlayerDownloadsRecord[]
}

interface PlayerContext {
  player: PlayerWith7DaysDownloads | undefined
  mutate: KeyedMutator<PlayerWith7DaysDownloads>
}

const playerContext = createContext<PlayerContext>({
  player: undefined,
  mutate: () => Promise.resolve(undefined),
})

export const usePlayer = () => useContext(playerContext)

export default playerContext
