import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import { Button, Field } from '@ritmo/ui'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'

const NewPlaylist = () => {
  const { clientId } = useParams()
  const navigate = useNavigate()
  const [title, setTitle] = useState('')

  const createPlaylist = async () => {
    const result = await save<{ ok: boolean, id: string }>({
      url: baseApiUrls.PLAYLIST,
      method: 'post',
      messages: {
        error: 'Error al crear la playlist',
        success: 'Playlist creada',
        loading: 'Creando playlist...'
      },
      payload: { title, clientId }
    })
    if (!result?.ok) return
    return navigate(`../${result.id}`)
  }
  return (
    <>
      <BackButton title="Nueva playlist" />
      <div className="max-w-xs mx-auto mt-6 space-y-6">
        <Field label="Nombre">
          <input
            placeholder="Playlist"
            onChange={e => setTitle(e.target.value)}
            className="input w-full"
            autoFocus
          />
        </Field>
        <Button
          className="w-full"
          onClick={createPlaylist}
        >
          Crear playlist
        </Button>
      </div>
    </>
  )
}

export default NewPlaylist