import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type UseSelectionResult = [
  selected: Record<string, boolean>,
  toggleSelected: (itemId: string) => void,
  allItemsSelected: boolean,
  toggleAllItems: () => void,
  resetAllItems: () => void
]

export function useSelection(itemsIds: string[]): UseSelectionResult {
  const pressingShiftRef = useRef(false)
  const lastAddedIndexRef = useRef<number | null>(null)
  const [selected, setSelected] = useState<Record<string, boolean>>({})

  const allItemsSelected = useMemo(() => {
    if (!itemsIds.length) return false
    return itemsIds.every((id) => selected[id])
  }, [itemsIds, selected])

  const toggleSelected = useCallback(
    (itemId: string) => {
      const index = itemsIds.findIndex((id) => id === itemId)
      if (index === -1 || index === undefined) return

      const newSelectedPlayers = { ...selected }

      if (pressingShiftRef.current && lastAddedIndexRef.current !== null) {
        const start = Math.min(lastAddedIndexRef.current, index)
        const end = Math.max(lastAddedIndexRef.current, index)
        for (let i = start; i <= end; i++) {
          newSelectedPlayers[itemsIds[i]] = true
        }
      } else {
        if (selected[itemId]) {
          delete newSelectedPlayers[itemId]
        } else {
          newSelectedPlayers[itemId] = true
        }
      }

      if (newSelectedPlayers[itemId]) {
        lastAddedIndexRef.current = index
      }
      setSelected(newSelectedPlayers)
    },
    [itemsIds, selected]
  )

  const toggleAllItems = useCallback(() => {
    if (allItemsSelected) {
      setSelected({})
      return
    }
    setSelected((curr) => {
      const newSelected = { ...curr }
      itemsIds.forEach((id) => {
        newSelected[id] = true
      })
      return newSelected
    })
  }, [itemsIds, allItemsSelected])

  const resetAllItems = useCallback(() => {
    setSelected({})
  }, [])

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    pressingShiftRef.current = e.key === 'Shift'
  }, [])

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Shift') {
      pressingShiftRef.current = false
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])

  return [selected, toggleSelected, allItemsSelected, toggleAllItems, resetAllItems]
}
