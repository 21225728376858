import { useState } from 'react'
import BranchesSelector from '../../components/BranchesSelector/BranchesSelector'
import { Button, Field, useArrayState } from '@ritmo/ui'
import { Playlist } from '@ritmo/types'
import { baseApiUrls } from '../../utils/consts'
import save from '../../utils/save'
import { useParams } from 'react-router-dom'
import TagsSelector from '../../components/TagsSelector/TagsSelector'
import Tabs from '../../components/Tabs'

interface PlaylistInfoProps {
  playlist: Playlist
  onSave: () => void
}

export default function PlaylistInfo({ playlist, onSave }: PlaylistInfoProps) {
  const { clientId } = useParams<{ clientId: string }>()
  const [title, setTitle] = useState(playlist.title)
  const [addView, setAddView] = useState<string>(playlist.tags.length > 0 ? 'tags' : 'branches')
  const [playlistTags, setTags, toggleTags] = useArrayState(playlist.tags)
  const [playlistBranches, setBranches, toogleBranches] = useArrayState(
    playlist.branches
  )

  const saveChanges = async () => {
    await save({
      url: `${baseApiUrls.PLAYLIST}/${playlist._id}`,
      method: 'patch',
      payload: {
        title: title,
        branches: playlistBranches,
        tags: playlistTags,
      },
      messages: {
        loading: 'Actualizando playlist...',
        success: 'Playlist actualizada correctamente',
        error: 'Error al actualizar la playlist',
      },
    })
    onSave()
  }

  if (!clientId) return null

  return (
    <>
      <Field label="Nombre" className="my-6">
        <input
          defaultValue={playlist.title}
          onChange={(e) => setTitle(e.target.value)}
          className="input w-full"
        />
      </Field>
      <Tabs
        tabs={[
          {
            name: 'branches',
            label: 'Sucursales',
          },
          {
            name: 'tags',
            label: 'Tags',
          },
        ]}
        view={addView}
        onChange={setAddView}
      />
      {addView === 'branches' ? (
        <BranchesSelector
          clientId={clientId}
          selectedBranches={playlistBranches}
          toggleBranches={toogleBranches}
          setBranches={setBranches}
        />
      ) : (
        <TagsSelector
          clientId={clientId}
          selectedTags={playlistTags}
          toggleTags={toggleTags}
          setTags={setTags}
        />
      )}
      <Button className="w-full mt-6" onClick={saveChanges}>
        Guardar cambios
      </Button>
    </>
  )
}
