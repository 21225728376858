import { getTable } from '../getTable'
import { selectedRange } from '../selectRange'

export function handleCopy(e: ClipboardEvent) {
  e.preventDefault()

  if (!selectedRange) return

  const [start, end] = selectedRange

  const xStart = Math.min(start.x, end.x)
  const xEnd = Math.max(start.x, end.x)
  const yStart = Math.min(start.y, end.y)
  const yEnd = Math.max(start.y, end.y)

  let result = ''

  for (let i = yStart; i <= yEnd; i++) {
    const row = getTable().querySelector(`tr[data-index="${i}"]`)
    if (!row) continue

    for (let j = xStart; j <= xEnd; j++) {
      const cell = row.querySelector(`td[data-index="${j}"]`)
      if (!cell) continue
      result += cell.textContent + '\t'
    }
    result = result + '\n'
  }
  navigator.clipboard.writeText(result)
}
