import { Button, Field } from '@ritmo/ui'
import ColorPicker from './ColorPicker'
import { FormEvent, useState } from 'react'
import { Tag } from '@ritmo/types'

interface TagFormInterface {
  onSubmit: ({ color }: { color: string }) => void
  tag?: Tag
}

export default function TagForm({ tag, onSubmit }: TagFormInterface) {
  const [color, setColor] = useState(tag?.color || '#f44336')

  const habdleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = {
      ...Object.fromEntries(new FormData(e.currentTarget)),
      color,
    }

    onSubmit(formData)
  }

  return (
    <form className="max-w-lg space-y-4" onSubmit={habdleSubmit}>
      <Field label="Titulo *">
        <input
          className="input w-full"
          autoComplete="off"
          required
          name="title"
          defaultValue={tag?.title}
        />
      </Field>
      <Field label="Color" className="max-w-[180px]">
        <ColorPicker value={color} onChange={setColor} />
      </Field>
      <div className="flex items-center justify-end">
        <Button type="submit">{tag ? 'Guardar' : 'Crear'}</Button>
      </div>
    </form>
  )
}
