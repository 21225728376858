import { Schedule } from '@ritmo/types'
import { IconButton } from '@ritmo/ui'
import { Trash } from 'phosphor-react'

interface ScheduleTableProps {
  schedules: Schedule[]
  onChange: (newSchedules: Schedule[]) => void
  readOnly?: boolean
}

const weekDays = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
]
const options = weekDays.map((label, i) => ({ label, value: i.toString() }))

export function ScheduleTable({
  schedules,
  onChange,
  readOnly = false,
}: ScheduleTableProps) {
  const removeSchedule = (index: number) => {
    const result = [...schedules]
    result.splice(index, 1)
    onChange(result)
  }

  const changeTimeOrDay = (
    type: keyof Schedule,
    index: number,
    value: string
  ) => {
    const result = [...schedules]
    result[index][type] = value
    onChange(result)
  }

  return (
    <div className="space-y-3 flex-1 select-none">
      {schedules.map((schedule, i) => (
        <div key={i} className="flex items-center gap-x-3">
          <div className="flex flex-col sm:contents gap-y-3 flex-1">
            <select
              className="input"
              value={schedule?.startDay}
              onChange={(e) =>
                changeTimeOrDay('startDay', i, e.currentTarget.value)
              }
              disabled={readOnly}
            >
              {options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              className="input"
              value={schedule?.startHour}
              type="time"
              onChange={(e) =>
                changeTimeOrDay('startHour', i, e.currentTarget.value)
              }
              disabled={readOnly}
            />
          </div>
          <h4 className="opacity-50 relative -top-1">⟶</h4>
          <div className="flex flex-col sm:contents gap-y-3 flex-1">
            <select
              className="input"
              value={schedule?.endDay}
              onChange={(e) =>
                changeTimeOrDay('endDay', i, e.currentTarget.value)
              }
              disabled={readOnly}
            >
              {options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <input
              className="input"
              value={schedule?.endHour}
              type="time"
              onChange={(e) =>
                changeTimeOrDay('endHour', i, e.currentTarget.value)
              }
              disabled={readOnly}
            />
          </div>
          {!readOnly && (
            <IconButton
              size="small"
              className="text-xl sm:text-base hover:bg-accent/10 hover:text-accent"
              onClick={() => removeSchedule(i)}
            >
              <Trash />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  )
}
