import { Howl } from 'howler'
import { useCallback, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  currentPlaylistIdState,
  currentSongState,
  playbackState,
} from '../../atoms/playback'
import { formatTime } from './formatTime'
import { baseApiUrls } from '../../utils/consts'
import { baseFetcher } from '../apis'
import { Song } from '@ritmo/types'

export default function useHowler() {

  const setPlayback = useSetRecoilState(playbackState)
  const soundRef = useRef<Howl | null>(null)
  const [currentSong, setCurrentSong] = useRecoilState(currentSongState)
  const currentPlaylistId = useRecoilValue(currentPlaylistIdState)
  const requesting = useRef(false)

  const requestSong = useCallback(async (playlistId?: string) => {
    if (requesting.current) return
    requesting.current = true
    // TODO NO SIEMPRE ES PLAYLIST
    const randomSongUrl = `${baseApiUrls.PLAYLIST}/${playlistId || currentPlaylistId}/random-song`
    const song = await baseFetcher<Song & { downloadUrl: string }>(randomSongUrl)
    setCurrentSong(song)
    requesting.current = false
  }, [currentPlaylistId, setCurrentSong])

  const stepFunction = useCallback(() => {
    const sound = soundRef.current as Howl
    const seek = sound.seek()
    setPlayback({
      playing: true,
      timer: formatTime(Math.round(seek)),
      step: (seek * 100) / sound.duration() || 0,
    })
    if (sound.playing()) {
      requestAnimationFrame(() => stepFunction())
    } else {
      setPlayback(curr => ({ ...curr, playing: false }))
    }
  }, [setPlayback])

  const playSong = useCallback((src: string) => {
    return new Promise<void>((resolve, reject) => {
      soundRef.current?.unload()

      soundRef.current = new Howl({
        src,
        html5: true,
        format: ['mp3'],
        preload: 'metadata',
        autoplay: true,
        volume: 1,
      })

      soundRef.current.on('play', stepFunction)
      soundRef.current.on('playerror', reject)
      soundRef.current.on('loaderror', reject)
      soundRef.current.on('end', () => resolve())
    })
  }, [stepFunction])

  const playCurrentSong = useCallback(async (songKey: string) => {
    try {
      await playSong(songKey)
    } catch (err) {
      console.warn(err)
    } finally {
      requestSong()
    }
  }, [requestSong, playSong])

  useEffect(() => {
    if (!currentSong) return
    playCurrentSong(currentSong.downloadUrl)
  }, [currentSong])

  return {
    soundRef,
    requestSong,
  }
}